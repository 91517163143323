import { Component, Inject, ChangeDetectionStrategy } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BucketOptions, ChartOptions } from "@library";
import { InfosheetMeasurementSummaryOverrides, MeasurementSummaryOverrides } from '@core/data';

export interface MeasureSummaryOptionsFormDialogData {
    chartOptions: ChartOptions[];
    bucketOptions: BucketOptions[];
    override: MeasurementSummaryOverrides | InfosheetMeasurementSummaryOverrides;
    formGroupName?: string;
}

@Component({
    selector: 'pv-measure-summary-options-form-dialog',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    templateUrl: 'measure-summary-options-form.dialog.html'
})
export class MeasureSummaryOptionsFormDialog {

    formGroup = new FormGroup({
        bucketId: new FormControl(null),
        chartId: new FormControl(null),
        showChart: new FormControl(true),
        showData: new FormControl(false),
        showStats: new FormControl(true),
        showAverage: new FormControl(false),
    });

    chartOptions: ChartOptions[];
    bucketOptions: BucketOptions[];
    formGroupName: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: MeasureSummaryOptionsFormDialogData,
        private _dialogRef: MatDialogRef<MeasureSummaryOptionsFormDialog>
    ) {
        this.formGroup.reset(_data.override);
        this.chartOptions = _data.chartOptions;
        this.bucketOptions = _data.bucketOptions;
        this.formGroupName = _data.formGroupName;
    }

    submit(){
        this._dialogRef.close({...this._data.override, ...this.formGroup.value});
    }


}

