import { Collection, Cultivar, DetailRequest, Filter, FilterBuilder, FilterQueryMode, ReportImageOption, Report, ReportLetterhead, ReportRemark, ReportSample, ReportService, ReportTable, ReportWeather, Sample, SampleSearchRequest, SampleService, Site, Status, translateCommonErrorStatus, WeatherReportOptions, WeatherStation, WeatherStationService, ReportTableTemplate } from '@core/data';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { uniqBy } from "lodash";
import { Injectable } from '@angular/core';
import { isNumberRange, isNumberSet, parseNumberRangeValue, parseNumberSet } from '@core/utils';
import { REPORT_OPTIONS_DEFAULT } from './report-defaults';


export class InitReportBuilder {
    static readonly type = "[ReportBuilder] Init Edit";
    constructor(public reportKey: string = null) { }
}
export class ReloadReportBuilder {
    static readonly type = "[ReportBuilder] Reload";
    constructor() { }
}

export class UpdateReportFormData {
    static readonly type = "[ReportBuilder] Update Form";
    constructor(public data: Report) { }
}

/**
 * Adds Sample to report
 */
export class ReportAddSample {
    static readonly type = '[ReportBuilder] Add Sample';
    constructor(public sampleKey: string) { }
}

export class ReportAddManySamples {
    static readonly type = '[ReportBuilder] Add Many Samples';
    constructor(public sampleKeys: string[]) { }
}

export class ReportAddCultivarSamples {
    static readonly type = '[ReportBuilder] Add Cultivar Samples';
    constructor(public cultivarKey: string, public primary: boolean) { }
}

export class ReportAddWeather {
    static readonly type = '[ReportBuilder] Add Weather';
    constructor(public weather: Partial<ReportWeather>) { }
}

export class ReportRemoveWeather {
    static readonly type = '[ReportBuilder] Remove Weather';
    constructor(public weather: ReportWeather) {}
}

export class ReloadReportBuilderLiveData {
    static readonly type = '[ReportBuilder] Reload Live Data';
    constructor() { }
}

export class ReloadReportBuilderLetterheadOptions {
    static readonly type = '[ReportBuilder] Reload Letterhead Options';
    constructor() { }
}

export class ReloadReportBuilderWeatherImport {
    static readonly type = '[ReportBuilder] Reload Weather Import';
    constructor() { }
}

export class ReloadReportBuilderRemarks {
    static readonly type = '[ReportBuilder] Reload Remarks';
    constructor(public reportKey: string) { }
}

export class ReportAddTable {
    static readonly type = '[ReportBuilder] Add Table';
    constructor(public table: Partial<ReportTable>) { }
}

export class ReportRemoveTable {
    static readonly type = '[ReportBuilder] Remove Table';
    constructor(public table: ReportTable) { }
}

export class ReportUpdateTables {
    static readonly type = '[ReportBuilder] Update Tables'
    constructor(public tables: ReportTable[]) { }
}

export class ReportUpdateRemarks {
    static readonly type = '[ReportBuilder] Update Remarks'
    constructor(public remarks: ReportRemark[]) { }
}

export class ReportUpdateSamples {
    static readonly type = '[ReportBuilder] Update Samples'
    constructor(public samples: ReportSample[]) { }
}

/**
 * Saves the report
 */
export class SubmitReportBuilder {
    static readonly type = '[ReportBuilder] Submit Report';
    constructor(public model: Partial<Report>) { }
}

/**
 * Calls action to signify that report was saved
 */
export class ReportSaveSuccess {
    static readonly type = '[ReportBuilder] Save Success';
    constructor(public model: Report) { }
}

export class SetReportBuilderSampleSearch {
static readonly type = '[ReportBuilder] Set Search';
    constructor(public text: string) { }
}

export class SetReportBuilderSiteFilter {
    static readonly type = '[ReportBuilder] Set Site Filter';
    constructor(public siteKeys: string[]) { }
}

export class QueryReportBuilderSamples {
    static readonly type = '[ReportBuilder] Query Samples';
    constructor(public query: ReportBuilderSampleQuery){};
}

export class ResetReportSampleQuery {
    static readonly type = '[ReportBuilder] Reset Samples Query';
    constructor(public showCultivarSelection: boolean){}
}

export class SetReportBuilderInfoSheetImages {
    static readonly type = '[ReportBuilder] Set Info Sheet Images';
    constructor(public index: number, public imageKeys: string[], public imageOptions: ReportImageOption[]) { }
}

export class SetNewReportBuilderImageOptions {
    static readonly type = '[ReportBuilder] Set New Report Image Options';
    constructor (public imageOptions: ReportImageOption[][]) { }
}

export class LoadReportTableTemplates {
    static readonly type = '[ReportBuilder] Load Table Templates';
    constructor(public ownerOrgKey: string){}
}
export class CreateReportTableTemplate {
    static readonly type = '[ReportBuilder] Create Table Templates';
    constructor(public template: Partial<ReportTableTemplate>){}
}

/**
 * State Model
 */
export interface ReportBuilderStateModel {
    status: Status;
    key: string;
    orgKey: string;
    data: Report;
    letterheadOptions: ReportLetterhead[];
    // substates
    liveData: ReportBuilderLiveDataStateModel;
    sampleImport: ReportBuilderSampleImportStateModel;
    weatherImport: ReportBuilderWeatherImportStateModel;
    tableTemplateImport: ReportBuilderTableTemplateImportStateModel;

    query: ReportBuilderSampleQuery;
}

export interface ReportBuilderLiveDataStateModel {
    status: Status;
    data: Sample[];
}

export interface ReportBuilderSampleImportStateModel {
    status: Status;
    samples: Sample[];
}

export interface ReportBuilderWeatherImportStateModel {
    status: Status;
    stations: WeatherStation[];
}

export interface ReportBuilderTableTemplateImportStateModel {
    status: Status;
    templates: ReportTableTemplate[];
}

/**
 * DEFAULT MODEL
 */
export const DEFAULTS: ReportBuilderStateModel = {
    status: Status.UNINITIALIZED,
    key: null,
    orgKey: null,
    data: null,
    letterheadOptions: [],
    liveData: {
        status: Status.UNINITIALIZED,
        data: [],
    },
    sampleImport: {
        status: Status.UNINITIALIZED,
        samples: [],
    },
    weatherImport: {
        status: Status.UNINITIALIZED,
        stations: [],
    },
    tableTemplateImport: {
        status: Status.UNINITIALIZED,
        templates: [],
    },
    query: {
        search: null,
        sites: [],
        primaryCultivar: null,
        controlCultivar: null,
        scionCultivars: [],
        rootstockCultivars: [],
        type: [],
        birthYear: [],
        birthWeek: [],
        scionCropId: [],
        rowIndex: null,
        positionIndex: null,
        hideDestroyed: true,
        excludeYear: false,
        startEvalPeriod: null,
        endEvalPeriod: null,
        plantSample: null,
        harvestSample: null,
    }
};

export interface ReportBuilderSampleQuery {
    search: string;
    sites: Site[];
    primaryCultivar: Cultivar;
    controlCultivar: Cultivar;

    scionCultivars: Cultivar[];
    rootstockCultivars: Cultivar[];
    scionCropId: string[];

    type: string[];

    birthYear?: number[];
    birthWeek?: number[];

    startEvalPeriod?: string;
    endEvalPeriod?: string;

    hideDestroyed?: boolean;
    excludeYear?: boolean;

    rowIndex?: string;
    positionIndex?: string;

    plantSample?: null,
    harvestSample?: null,
}

@State<ReportBuilderStateModel>({
    name: 'reportBuilder',
    defaults: null
})
@Injectable()
export class ReportBuilderState {

    @Selector()
    static data(state: ReportBuilderStateModel) {
        return state.data;
    }

    @Selector()
    static sampleImport(state: ReportBuilderStateModel) {
        return state.sampleImport;
    }

    @Selector()
    static weatherImport(state: ReportBuilderStateModel) {
        return state.weatherImport;
    }

    @Selector()
    static liveData(state: ReportBuilderStateModel) {
        return state.liveData;
    }

    @Selector()
    static tableTemplates(state: ReportBuilderStateModel) {
        return state.tableTemplateImport;
    }

    @Selector()
    static query(state: ReportBuilderStateModel) {
        return state.query;
    }

    sampleDetail: DetailRequest = {
        related: [
            'scionCultivar',
            'rootstockCultivar',
            'site',
            'plantSample',
            'primaryImage',
            'evals',
            'evals.images',
            'evals.notes',
            'evals.chars',
            'evals.measures',
        ],
    };


    constructor(
        private _sampleService: SampleService,
        private _reportService: ReportService,
        private _weatherService: WeatherStationService,
    ) { }


    @Action(InitReportBuilder, {cancelUncompleted: true})
    initEditReportBuilder(ctx: StateContext<ReportBuilderStateModel>, action: InitReportBuilder) {


        if (!action.reportKey) {
            ctx.setState(DEFAULTS);
            return;
        }

        ctx.setState({
            ...DEFAULTS,
            key: action.reportKey,
        });

        return ctx.dispatch(new ReloadReportBuilder);

    }

    @Action(ReloadReportBuilder, {cancelUncompleted: true})
    reloadReportBuilder(ctx: StateContext<ReportBuilderStateModel>, action: ReloadReportBuilder){

        const state = ctx.getState();

        ctx.setState({
            ...state,
            status: Status.LOADING,
            sampleImport: {
                status: Status.UNINITIALIZED,
                samples: []
            },
            weatherImport: {
                status: Status.UNINITIALIZED,
                stations: []
            },
            tableTemplateImport: {
                status: Status.UNINITIALIZED,
                templates: []
            }
        });

        return this._reportService
            .get(state.key)
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            status: Status.OK,
                            orgKey: result.ownerOrgKey,
                            data: result
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                ),
                switchMap(res => {
                    const query: ReportBuilderSampleQuery = {
                        ...DEFAULTS.query,
                        primaryCultivar: res.options?.cultivarInfoOptions?.primaryCultivar || null,
                        controlCultivar: res.options?.cultivarInfoOptions?.controlCultivar || null,
                    }

                    return ctx.dispatch([
                        new ReloadReportBuilderLiveData(),
                        new ReloadReportBuilderWeatherImport(),
                        new QueryReportBuilderSamples(query),
                        new ReloadReportBuilderLetterheadOptions(),
                    ]);
                })
            );
    }


    @Action(ReloadReportBuilderLiveData, {cancelUncompleted: true})
    reloadLiveData(ctx: StateContext<ReportBuilderStateModel>, action: ReloadReportBuilderLiveData) {

        const state = ctx.getState();

        if (state.data && Array.isArray(state.data.samples) && state.data.samples.length > 0) {

            ctx.patchState({
                liveData: {
                    status: Status.LOADING,
                    data: [],
                }
            });

            let keys = state.data.samples.map(s => s.sampleKey);

            let filter: Filter = {
                queries: [
                    { key: 'ownerOrgKey', value: state.orgKey },
                    { key: 'key', value: keys, mode: FilterQueryMode.IN }
                ],
                sort: {
                    column: 'birthDate',
                    order: 'asc'
                }
            };

            return this._sampleService.query(filter, this.sampleDetail)
                .pipe(tap(
                    result => {
                        ctx.patchState({
                            liveData: {
                                status: Status.OK,
                                data: result.data,
                            }
                        });
                    },
                    error => {
                        ctx.patchState({
                            liveData: {
                                status: translateCommonErrorStatus(error),
                                data: []
                            }
                        });
                    }
                ));
        } else {
            ctx.patchState({
                liveData: {
                    status: Status.EMPTY,
                    data: []
                }
            });
        }

    }

    @Action(ReloadReportBuilderRemarks)
    reloadReportBuilderRemarks(ctx: StateContext<ReportBuilderStateModel>, action: ReloadReportBuilderRemarks) {
        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING,
        });

        return this._reportService
            .getRemarks(action.reportKey, {related: ['documents']})
            .pipe(
                tap(
                    (result: ReportRemark[]) => {
                        ctx.patchState({
                            ...state,
                            data: {
                                ...state.data,
                                remarks: result
                            },
                            status: Status.OK,
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            );
    }

    @Action(ReloadReportBuilderLetterheadOptions)
    reloadReportBuilderLetterheadOptions(ctx: StateContext<ReportBuilderStateModel>, action: ReloadReportBuilderLetterheadOptions){

        const state = ctx.getState();

        return this._reportService
            .getOrgReportLetterheads(state.orgKey)
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            letterheadOptions: result
                        });
                    },
                )
            );
    }


    @Action(ReloadReportBuilderWeatherImport)
    reloadReportBuilderWeatherImport(ctx: StateContext<ReportBuilderStateModel>, action: ReloadReportBuilderWeatherImport){

        const state = ctx.getState();

        ctx.patchState({
            weatherImport: {
                stations: [],
                status: Status.LOADING,
            }
        });

        const filter: Filter = {
            queries: [{ key: 'ownerOrgKey', value: state.orgKey }],
            sort: { column: 'name', order: 'asc' }
        };

        const detail: DetailRequest = {
            related: ['sensorSummaries']
        };

        return this._weatherService.query(filter, detail)
            .pipe(tap(
                result => {


                    ctx.patchState({
                        weatherImport: {
                            status: result.data.length ? Status.OK : Status.EMPTY,
                            stations: result.data
                        }
                    });

                },
                error => {
                    ctx.patchState({
                        weatherImport: {
                            status: translateCommonErrorStatus(error),
                            stations: []
                        }
                    });
                }
            ));


    }

    @Action(UpdateReportFormData)
    updateReportFormData(ctx: StateContext<ReportBuilderStateModel>, action: UpdateReportFormData) {
        const state = ctx.getState();

        if (state.status !== Status.OK) return;

        let formData: Report = JSON.parse(JSON.stringify(action.data));

        if (state.data) {
            let isUnindexed = state.data.samples.filter((sample) => sample.reportSampleIndex === 0).length > 1;

            formData.samples.forEach((sample, index) => {
                let stateSample = state.data.samples.find((reportSample) => reportSample.sampleKey === sample.sampleKey)

                if (isUnindexed) return sample.reportSampleIndex = index
                if (!stateSample) return
                sample.reportSampleIndex = stateSample.reportSampleIndex;
            })
        }

        ctx.patchState({
            data: formData
        });

    }


    /**
     * Adds the requested sample to the report
     * @param ctx State Context
     * @param action sampleType ('plant' | 'harvest') sampleKey key of sample to add
     */
    @Action(ReportAddSample)
    loadSample(ctx: StateContext<ReportBuilderStateModel>, action: ReportAddSample) {
        const state = ctx.getState();
        ctx.patchState({
            liveData: {
                ...state.liveData,
                status: Status.LOADING,
            },
        });

        let nextIndex = state.data.samples ? state.data.samples.length : 0;

        return this.getSampleDetail(action.sampleKey)
            .pipe(tap(
                snapshot => {
                    const state = ctx.getState();
                    const reportSample: ReportSample = this.mapSampleDetail(snapshot, nextIndex);

                    let reportSamples = uniqBy([reportSample, ...state.data.samples], 'sampleKey')
                    reportSamples = reportSamples.sort((a, b) => a.reportSampleIndex - b.reportSampleIndex);

                    ctx.patchState({
                        data: {
                            ...state.data,
                            samples: reportSamples,
                        },
                        liveData: {
                            status: Status.OK,
                            data: uniqBy([snapshot, ...state.liveData.data], 'key'),
                        },
                    })

                    ctx.dispatch(new QueryReportBuilderSamples(state.query));
                },
                error => {
                    const state = ctx.getState();
                    ctx.patchState({
                        liveData: {
                            ...state.liveData,
                            status: Status.OK,
                        }
                    });
                }
            ));
    }



    /**
     * Adds the requested samples (many) to the report
     * @param ctx State Context
     * @param action sampleType ('plant' | 'harvest') sampleKey key of samples to add
     */
     @Action(ReportAddManySamples)
     loadManySamples(ctx: StateContext<ReportBuilderStateModel>, action: ReportAddManySamples) {
         const state = ctx.getState();

         ctx.patchState({
             liveData: {
                 ...state.liveData,
                 status: Status.LOADING,
             },
         });


         return this.getManySamplesDetails(state.orgKey, action.sampleKeys)
             .pipe(tap(
                samples => {
                    const state = ctx.getState();
                    const reportSamples: ReportSample[] = samples.data.map(sample => this.mapSampleDetail(sample));

                    ctx.patchState({
                        data: {
                            ...state.data,
                            samples: uniqBy([...reportSamples, ...state.data.samples], 'sampleKey'),
                        },
                        liveData: {
                            status: Status.OK,
                            data: uniqBy([...samples.data, ...state.liveData.data], 'key'),
                        },
                    })

                    ctx.dispatch(new QueryReportBuilderSamples(state.query));
                },
                error => {
                    const state = ctx.getState();
                    ctx.patchState({
                        liveData: {
                            ...state.liveData,
                            status: translateCommonErrorStatus(error),
                        }
                    });
                }
            ));
     }

    @Action(ReportAddCultivarSamples)
    loadCultivarSamples(ctx: StateContext<ReportBuilderStateModel>, action: ReportAddCultivarSamples) {
        const state = ctx.getState();

        ctx.patchState({
            liveData: {
                ...state.liveData,
                status: Status.LOADING,
            },
        });

        return this.getCultivarSampleDetails(state.orgKey, action.cultivarKey)
            .pipe(tap(
                samples => {
                    const state = ctx.getState();
                    const reportSamples: ReportSample[] = samples.data.map(sample => this.mapSampleDetail(sample));

                    ctx.patchState({
                        data: {
                            ...state.data,
                            samples: uniqBy([...reportSamples, ...state.data.samples], 'sampleKey'),
                        },
                        liveData: {
                            status: Status.OK,
                            data: uniqBy([...samples.data, ...state.liveData.data], 'key'),
                        },
                    })
                },
                error => {
                    const state = ctx.getState();
                    ctx.patchState({
                        liveData: {
                            ...state.liveData,
                            status: translateCommonErrorStatus(error),
                        }
                    });
                }
            ));
    }

    @Action(SetReportBuilderInfoSheetImages, { cancelUncompleted: true })
    setReportBuilderInfoSheetImages(ctx: StateContext<ReportBuilderStateModel>, action: SetReportBuilderInfoSheetImages) {
        ctx.setState(
            patch({
                data: patch({
                    options: patch({
                        cultivarInfoOptions: patch({
                            includeImages: updateItem<string[]>(action.index, action.imageKeys),
                            infoSheetImageOptions: updateItem<ReportImageOption[]>(action.index, action.imageOptions)
                        })
                    })
                })
            })
        );
    }

    @Action(SetNewReportBuilderImageOptions, { cancelUncompleted: true})
    setNewReportBuilderImageOptions(ctx: StateContext<ReportBuilderStateModel>, action: SetNewReportBuilderImageOptions) {
        const state = ctx.getState();

        ctx.patchState({
            ...state,
            data: {
                ...state.data,
                options: {
                    ...state.data.options,
                    cultivarInfoOptions: {
                        ...state.data.options.cultivarInfoOptions,
                        infoSheetImageOptions: action.imageOptions
                    }
                }
            }
        });
    }

    @Action(ReportAddWeather)
    reportAddWeather(ctx: StateContext<ReportBuilderStateModel>, action: ReportAddWeather){

        const options: WeatherReportOptions = {
            startDate: action.weather.startDate,
            endDate: action.weather.endDate,
            interval: action.weather.interval,
            reportId: action.weather.reportId
        };

        ctx.patchState({
            status: Status.LOADING,
        });

        return this._weatherService.report(action.weather.stationKey, options)
            .pipe(tap(
                result => {

                    const state = ctx.getState();

                    let replaced = false;

                    const newReport: ReportWeather = {
                        id: action.weather.id,
                        ...result,
                        title: action.weather.title
                    };

                    const weather = state.data.weather.map(report => {

                        if (newReport.stationKey === report.stationKey
                            && newReport.id && newReport.id === report.id) {
                            replaced = true;
                            return newReport;
                        }

                        return report;
                    });

                    if(!replaced) weather.push(newReport);

                    ctx.patchState({
                        status: Status.OK,
                        data: {
                            ...state.data,
                            weather,
                        }
                    });


                },
                error => {
                    ctx.patchState({
                        status: Status.OK,
                    });
                }
            ));


    }

    /**
     * @param ctx State Context
     * @param action weather: string key
     */
    @Action(ReportRemoveWeather)
    reportRemoveWeather(ctx: StateContext<ReportBuilderStateModel>, action: ReportRemoveWeather) {

        const state = ctx.getState();

        const data = {
            ...state.data,
            weather: state.data.weather.filter(w => {
                if (w.id !== undefined && w.id == action.weather.id) return false;
                // handle case where id === undefined
                if (w === action.weather) return false;
                return true;
            })
        };

        ctx.patchState({
            data
        });
    }

    @Action(ReportAddTable)
    reportAddTable(ctx: StateContext<ReportBuilderStateModel>, action: ReportAddTable) {
        let replaced = false;

        const state = ctx.getState();

        const newReport: ReportTable = {
            id: action.table.id,
            title: action.table.title,
            description: action.table.description,
            tableOptions: action.table.tableOptions,
            index: action.table.index >= 0 ? action.table.index : state.data.tables.length,
        };

        const tables = state.data.tables.map(report => {
            if (newReport.id && newReport.id === report.id) {
                replaced = true;
                return newReport;
            }
            return report;
        });

        if (!replaced) {
            tables.push(newReport);
        }

        ctx.patchState({
            status: Status.OK,
            data: {
                ...state.data,
                tables,
            }
        });
    }


    @Action(ReportRemoveTable)
    ReportRemoveTable(ctx: StateContext<ReportBuilderStateModel>, action: ReportRemoveTable) {
        const state = ctx.getState();

        const data = {
            ...state.data,
            tables: state.data.tables.filter(t => {
                if (t.id !== undefined && t.id == action.table.id) return false;
                if (t === action.table) return false;
                return true;
            })
        };

        ctx.patchState({
            data
        });
    }

    @Action(ReportUpdateTables)
    ReportUpdateTables(ctx: StateContext<ReportBuilderStateModel>, action: ReportUpdateTables) {
        const state = ctx.getState();

        ctx.patchState({
            status: Status.OK,
            data: {
                ...state.data,
                tables: action.tables,
            }
        });
    }

    @Action(ReportUpdateRemarks)
    ReportUpdateRemarks(ctx: StateContext<ReportBuilderStateModel>, action: ReportUpdateRemarks) {
        const state = ctx.getState();

        ctx.patchState({
            status: Status.OK,
            data: {
                ...state.data,
                remarks: action.remarks,
            }
        });
    }

    @Action(ReportUpdateSamples)
    ReportUpdateSamples(ctx: StateContext<ReportBuilderStateModel>, action: ReportUpdateSamples) {
        const state = ctx.getState();

        ctx.patchState({
            status: Status.OK,
            data: {
                ...state.data,
                samples: action.samples,
            }
        });
    }

    /**
     * Saves the report
     * @param ctx State Context
     * @param action model: the report to be saved
     */
    @Action(SubmitReportBuilder, { cancelUncompleted: true })
    reportSave(ctx: StateContext<ReportBuilderStateModel>, action: SubmitReportBuilder) {

        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING,
        });

        const form = action.model;

        // cleanup sample and weather data
        let data: Partial<Report> = {
            ...form,
            options: {
                ...action.model.options,
                sectionsOrder: form.options.sectionsOrder ? form.options.sectionsOrder : state.data.options.sectionsOrder,
                cultivarInfoOptions: {
                    ...state.data.options.cultivarInfoOptions,
                    primaryCultivar: state.data.options.cultivarInfoOptions?.primaryCultivar ?
                        state.data.options.cultivarInfoOptions?.primaryCultivar?.key as any : null,
                    controlCultivar: state.data.options.cultivarInfoOptions?.controlCultivar ?
                        state.data.options.cultivarInfoOptions?.controlCultivar?.key as any : null,
                    infoSheetImageOptions: action.model?.options.cultivarInfoOptions?.infoSheetImageOptions || REPORT_OPTIONS_DEFAULT.cultivarInfoOptions.infoSheetImageOptions,
                    overrides: action.model.options?.cultivarInfoOptions?.overrides || REPORT_OPTIONS_DEFAULT.cultivarInfoOptions.overrides
                }
            },
            samples: form.samples.map(sample => {
                return {
                    sampleKey: sample.sampleKey,
                    reportSampleIndex: sample.reportSampleIndex,
                    includeEvals: sample.includeEvals,
                    includeImages: sample.includeImages,
                    includeNotes: sample.includeNotes,
                    evalsTimelineOptions: sample.evalsTimelineOptions,
                };
            }),
            weather: form.weather.map(weather => {
                return {
                    id: weather.id,
                    title: weather.title,
                    stationKey: weather.stationKey,
                    reportId: weather.reportId,
                    endDate: weather.endDate,
                    startDate: weather.startDate,
                    interval: weather.interval,
                };
            }),
            tables: form.tables.map(table => {
                return {
                    id: table.id,
                    title: table.title,
                    description: table.description,
                    tableOptions: table.tableOptions,
                    index: table.index,
                };
            }),
            sections: form.sections.map(section => {
                return {
                    index: section.index,
                    enabled: section.enabled,
                    formControlName: section.formControlName,
                    formGroupName: section.formGroupName,
                    iconRef: section.iconRef,
                    title: section.title,
                    options: section.options,
                    optionsData: section.optionsData,
                }
            })
        };

        let dataCopy = data;
        delete dataCopy.options.showStorageRegime;
        delete dataCopy.options['legendOptions'];

        const request = this._reportService.update(state.key, dataCopy);

        return request.pipe(
            tap(
                (result: Report) => {

                    ctx.patchState({
                        key: result.key,
                        status: Status.OK,
                        data: result,
                    });

                    return ctx.dispatch(new ReportSaveSuccess(result));

                },
                error => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(error)
                    });
                }
            ),
            catchError(e => {
                return of(null);
            }),
        );
    }

    private getCurrentFilter(state: ReportBuilderStateModel, action: QueryReportBuilderSamples): Filter {

        const fb = new FilterBuilder();
        const hasPrimary = action.query.primaryCultivar ? true : false;
        const hasControl = action.query.controlCultivar ? true : false;

        fb.setPage({size: 50, index: 0});
        fb.setSort({column: 'updatedAt', order: 'desc'});

        if (state.orgKey) fb.setQuery('ownerOrgKey', state.orgKey);

        if (Array.isArray(action.query.sites) && action.query.sites.length > 0) {
            let keys = action.query.sites.map(i => i.key);
            fb.setQuery('siteKey', keys, FilterQueryMode.IN);
        }

        if (action.query.search) {
            if (action.query.search.trim()) fb.setQuery('$fuzzy', action.query.search);
        }

        if (hasPrimary || hasControl || Array.isArray(action.query.scionCultivars) && action.query.scionCultivars.length > 0) {
            let keys = action.query.scionCultivars.map(i => i.key);
            if (hasPrimary) keys.push(action.query.primaryCultivar.key);
            if (hasControl) keys.push(action.query.controlCultivar.key);
            fb.setQuery('scionCultivarKey', keys, FilterQueryMode.IN);
        }

        if (Array.isArray(action.query.rootstockCultivars) && action.query.rootstockCultivars.length > 0) {
            let keys = action.query.rootstockCultivars.map(i => i.key);
            fb.setQuery('rootstockCultivarKey', keys, FilterQueryMode.IN);
        }

        if (Array.isArray(action.query.type) && action.query.type.length > 0) {
            fb.setQuery('type', action.query.type, FilterQueryMode.IN);
        }

        if (Array.isArray(action.query.birthYear) && action.query.birthYear.length > 0) {
            fb.setQuery('birthYear', action.query.birthYear, FilterQueryMode.IN);
        }

        if (Array.isArray(action.query.birthWeek) && action.query.birthWeek.length > 0) {
            fb.setQuery('birthWeek', action.query.birthWeek, FilterQueryMode.IN);
        }

        if (Array.isArray(action.query.scionCropId) && action.query.scionCropId.length > 0) {
            fb.setQuery('scionCropId', action.query.scionCropId, FilterQueryMode.IN);
        }

        if (isNumberSet(action.query.rowIndex)) {
            fb.setQuery('rowIndex', parseNumberSet(action.query.rowIndex), FilterQueryMode.IN);
        } else if (isNumberRange(action.query.rowIndex)) {
            fb.setQuery('rowIndex', parseNumberRangeValue(action.query.rowIndex), FilterQueryMode.BETWEEN);
        }

        if (isNumberSet(action.query.positionIndex)) {
            fb.setQuery('positionIndex', parseNumberSet(action.query.positionIndex), FilterQueryMode.IN);
        } else if (isNumberRange(action.query.positionIndex)) {
            fb.setQuery('positionIndex', parseNumberRangeValue(action.query.positionIndex), FilterQueryMode.BETWEEN);
        }

        if(action.query.hideDestroyed) {
            fb.setQuery('deathDate', new Date(), FilterQueryMode.HAS_NO_VALUE);
        }

        if (action.query.excludeYear && (Array.isArray(action.query.birthYear) && action.query.birthYear.length > 0)) {
            fb.setQuery('birthYear', action.query.birthYear, FilterQueryMode.NOT_EQUALS);
        }

        if (action.query.startEvalPeriod && action.query.endEvalPeriod) {
            fb.setQuery('evalPeriod', [action.query.startEvalPeriod, action.query.endEvalPeriod], FilterQueryMode.BETWEEN);
        }

        return fb.get();
    }

    @Action(ResetReportSampleQuery)
    resetReportSampleQuery(ctx: StateContext<ReportBuilderStateModel>, action: ResetReportSampleQuery) {
        const state = ctx.getState();

        const query: ReportBuilderSampleQuery = {
            ...DEFAULTS.query,
            primaryCultivar: action.showCultivarSelection ? state.data.options.cultivarInfoOptions?.primaryCultivar : null,
            controlCultivar: action.showCultivarSelection ? state.data.options.cultivarInfoOptions?.controlCultivar : null,
        }

        ctx.patchState({
            query: query,
        })

        ctx.dispatch(new QueryReportBuilderSamples(query));
    }

    @Action(QueryReportBuilderSamples, { cancelUncompleted: true})
    queryReportBuilderSamples(ctx: StateContext<ReportBuilderStateModel>, action: QueryReportBuilderSamples) {
       const state = ctx.getState();

       ctx.patchState({
           query: action.query,
           status: Status.LOADING,
       })

       const detail: DetailRequest = {
            related: [
                'scionCultivar', 'rootstockCultivar',
                'site', 'plantSample'
            ]
        };

        const filter: Filter = this.getCurrentFilter(state, action);

        let samples: string[] = []

        if (state.data) samples = state.data.samples.map(sample => sample.sampleKey);

       return this._sampleService
           .query(filter, detail)
           .pipe(
               tap(
                   result => {
                       if (result.total > 0) {
                            ctx.patchState({
                               status: Status.OK,
                               sampleImport: {
                                samples: result.data.filter(sample => !samples.includes(sample.key)),
                                status: Status.OK
                            },
                           });
                       } else {
                           ctx.patchState({
                               sampleImport: { samples: [],  status: Status.EMPTY},
                           });
                       }
                   },
                   err => {
                       ctx.patchState({
                           status: translateCommonErrorStatus(err),
                           sampleImport: { samples: [],  status:  translateCommonErrorStatus(err)},
                       });
                   }
               )
           );
    }

    @Action(SetReportBuilderSampleSearch, { cancelUncompleted: true })
    setReportBuilderSearch(ctx: StateContext<ReportBuilderStateModel>, action: SetReportBuilderSampleSearch) {

        const state = ctx.getState();

        ctx.patchState({
            sampleImport: {
                status: Status.LOADING,
                samples: state.sampleImport.samples,
            }
        });

        let request: Observable<Sample[]>;

        if(!action.text || action.text.length < 2){

            const filter: Filter = {
                queries: [{ key: 'ownerOrgKey', value: state.orgKey }],
                page: {size: 15, index: 0},
                sort: {column: 'updatedAt', order: 'desc'}
            };

            const detail: DetailRequest = {
                related: [
                    'scionCultivar', 'rootstockCultivar',
                    'site', 'plantSample'
                ]
            };

            request = this._sampleService.query(filter, detail)
                        .pipe(map(result => result.data));
        } else {

            const searchReq: SampleSearchRequest = {
                ownerOrgKey: state.orgKey,
                limit: 100,
                text: action.text
            };

            request = this._sampleService.search(searchReq)
                        .pipe(map(result => result.results));
        }

        return request.pipe(tap(
                samples => {

                    const state = ctx.getState();

                    ctx.patchState({
                        sampleImport: {
                            status: samples.length ? Status.OK : Status.EMPTY,
                            samples,
                        }
                    });
                },
                error => {

                    ctx.patchState({
                        sampleImport: {
                            status: translateCommonErrorStatus(error),
                            samples: [],
                        }
                    });
                }
            ));
    }

    @Action(SetReportBuilderSiteFilter, { cancelUncompleted: true })
    setReportBuilderSiteFilter(ctx: StateContext<ReportBuilderStateModel>, action: SetReportBuilderSiteFilter) {

        const state = ctx.getState();

        ctx.patchState({
            sampleImport: {
                status: Status.LOADING,
                samples: state.sampleImport.samples,
            }
        });

        let request: Observable<Sample[]>;

        if(!action.siteKeys || action.siteKeys.length == 0){

            const filter: Filter = {
                queries: [{ key: 'ownerOrgKey', value: state.orgKey }],
                page: {size: 15, index: 0},
                sort: {column: 'updatedAt', order: 'desc'}
            };

            const detail: DetailRequest = {
                related: [
                    'scionCultivar', 'rootstockCultivar',
                    'site', 'plantSample'
                ]
            };

            request = this._sampleService.query(filter, detail)
                        .pipe(map(result => result.data));
        }else {

            const filter: Filter = {
                queries: [
                    {
                        key: 'ownerOrgKey',
                        mode: FilterQueryMode.EQUALS,
                        value: state.orgKey,
                    },
                    {
                        key: 'siteKey',
                        mode: FilterQueryMode.IN,
                        value: action.siteKeys,
                    },
                ],
                sort: {
                    column: 'label',
                    order: 'asc'
                },
            };

            const detail: DetailRequest = {
                related: [
                    'scionCultivar', 'rootstockCultivar',
                    'site', 'plantSample'
                ]
            };

            request = this._sampleService.query(filter, detail)
                        .pipe(map(result => result.data));

        }

        return request.pipe(tap(
                samples => {

                    const state = ctx.getState();

                    ctx.patchState({
                        sampleImport: {
                            status: samples.length ? Status.OK : Status.EMPTY,
                            samples,
                        }
                    });
                },
                error => {

                    ctx.patchState({
                        sampleImport: {
                            status: translateCommonErrorStatus(error),
                            samples: [],
                        }
                    });
                }
            ));
    }

    @Action(LoadReportTableTemplates, {cancelUncompleted: true})
    loadReportTableTemplates(ctx: StateContext<ReportBuilderStateModel>, action: LoadReportTableTemplates){

        let orgKey = action.ownerOrgKey;

        if(!orgKey) return;


        ctx.patchState({
            status: Status.LOADING,
        });


        return this._reportService.listTableTemplates(orgKey)
                .pipe(tap(
                    result => {
                        ctx.patchState({
                            status: Status.OK,
                            tableTemplateImport: {
                                status: result.length ? Status.OK : Status.EMPTY,
                                templates: result,
                            }
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                ));
    }

    @Action(CreateReportTableTemplate, {cancelUncompleted: true})
    createReportTableTemplate(ctx: StateContext<ReportBuilderStateModel>, action: CreateReportTableTemplate){

        const request = this._reportService.createTableTemplate(action.template);
        const state = ctx.getState()

        return request.pipe(tap(
            result => {
                ctx.patchState({
                    status: Status.OK,
                })

                ctx.dispatch(new LoadReportTableTemplates(state.orgKey));
            },
            err => {
                ctx.patchState({
                    status: translateCommonErrorStatus(err),
                });
            }
        ));

    }

    private getSampleDetail(key: string): Observable<Sample> {
        return this._sampleService.get(key, this.sampleDetail);
    }

    private getManySamplesDetails(orgKey: string, sampleKeys: string[]): Observable<Collection<Sample>> {
        let filter: Filter = {
            queries: [
                { key: 'ownerOrgKey', value: orgKey },
                { key: 'key', value: sampleKeys, mode: FilterQueryMode.IN},
            ],
            sort: {
                column: 'birthDate',
                order: 'asc'
            }
        };

        return this._sampleService.query(filter, this.sampleDetail);
    }

    private mapSampleDetail(sample: Sample, index: number = 0): ReportSample {
        return {
            reportSampleIndex: index,
            sampleKey: sample.key,
            includeEvals: sample.evals.map(e => e.key),
            includeImages: sample.evals.reduce((accum, evalu) => {
                return accum.concat(evalu.images.map(i => i.key));
            }, []),
            includeNotes: sample.evals.reduce((accum, evalu) => {
                return accum.concat(evalu.notes.map(i => i.key));
            }, []),
            evalsTimelineOptions: sample.evals.reduce((accum, evalu) => {
                return Object.assign(accum, {[evalu.key]: {}});
            }, {}),
            data: sample,
        };
    }

    private getCultivarSampleDetails(orgKey: string, cultivarKey: string): Observable<Collection<Sample>> {
        let filter: Filter = {
            queries: [
                { key: 'ownerOrgKey', value: orgKey },
                { key: 'scionCultivarKey', value: cultivarKey},
            ],
            sort: {
                column: 'birthDate',
                order: 'asc'
            }
        };
        return this._sampleService.query(filter, this.sampleDetail);
    }
}
