import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Cultivar, FilterSort, Status } from '@core/data';
import { Dialog, Snackbar, SnackLinkComponent } from '@core/material';
import { Crop, Library } from '@library';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CultivarFormDialog, CultivarFormDialogData } from '../cultivar-form/cultivar-form.dialog';
import { CultivarIndexState, CultivarIndexStateModel, ExportCultivarIndex, FilterCultivarIndex, InitCultivarIndex, LoadCultivarIndex, PageCultivarIndex, SortCultivarIndex, DeleteCultivar, CultivarIndexQuery, ResetCultivarIndex, SetCultivarIndexSelected, ImportCultivarIndex, DownloadCultivarImportTemplate } from './cultivar-index.state';
import { LIBRARY } from '@app/evaluation/library';
import { SelectionModel } from '@angular/cdk/collections';
import { CultivarTastingsSampleFormDialog, CultivarTastingsSampleFormDialogData } from '../cultivar-tastings-sample-form/cultivar-tastings-sample-form.dialog';
import { OrganizationSubscriptionHandler } from '@core/data/globals/subscriptions';
import { WEB_BASE_URI } from '@core/browser';
import { ExportHistoryDialog, ExportHistoryDialogData } from '../export-history-form/export-history-form.dialog';
import { ExportType } from '../../../../core/data/types/export-history';
import { CultivarSwitchFormDialog, CultivarSwitchFormDialogData } from '../cultivar-form/cultivar-switch-form.dialog';

@Component({
    selector: 'pv-cultivar-index-view',
    templateUrl: './cultivar-index.view.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-cultivar-index-view',
    }
})
export class CultivarIndexView implements OnInit, OnDestroy {

    @Select(CultivarIndexState)
    state$: Observable<CultivarIndexStateModel>;

    @Select(CultivarIndexState.query)
    query$: Observable<CultivarIndexQuery>;

    @Select(CultivarIndexState.sort)
    sort$: Observable<FilterSort>;


    // sort form group
    orderControl = new FormControl('desc');
    columnControl = new FormControl('createdAt');
    sortFormGroup = new FormGroup({
        column: this.columnControl,
        order: this.orderControl,
    });

    filterFormGroup = new FormGroup({
        search: new FormControl(null),
        isRootstock: new FormControl("*"),
        cropId: new FormControl([]),
        harvestWindow: new FormControl([]),
        licensee: new FormControl([]),
    });

    exportFormGroup = new FormGroup({
        type: new FormControl('xlsx'),
    });

    importFormGroup = new FormGroup({
        file: new FormControl(null),
    });

    filterCropOptions: Crop[];
    selection = new SelectionModel<string>(true, []);
    private _destroy$ = new Subject();
    private _snackRef: MatSnackBarRef<SnackLinkComponent>;
    hasTastingsModule: boolean = false;
    hasEvaluationModule: boolean = false;

    constructor(
        // private _store: CultivarStore,
        private _dialogs: MatDialog,
        @Inject(LIBRARY) private _evalLib: Library,
        @Inject(WEB_BASE_URI) private _baseWebUri: string,
        private _dialog: Dialog,
        private _store: Store,
        private _route: ActivatedRoute,
        private _snackbar: Snackbar,
        private _subscriptionHandler: OrganizationSubscriptionHandler,
    ){}

    ngOnInit(){
        this.hasTastingsModule = this._subscriptionHandler.isSubscribedToTastings();
        this.hasEvaluationModule = this._subscriptionHandler.isSubscribedToEvaluation();

        this.filterCropOptions = this._evalLib.filterExcludedCrops();

        this._route.paramMap.subscribe(params => {
            this._store.dispatch(new InitCultivarIndex(params.get('orgKey')));
        });


        // query state => filter form group
        this.query$
        .pipe(takeUntil(this._destroy$))
        .subscribe(query => {

            this.filterFormGroup.setValue({
                search: query.search,
                isRootstock: query.isRootstock,
                cropId: query.cropId,
                harvestWindow: query.harvestWindow,
                licensee: query.licensee,
            }, {emitEvent: false});

        });


        // sort state => sort form group
        this.sort$
        .pipe(takeUntil(this._destroy$))
        .subscribe(sort => {
            this.sortFormGroup.setValue(sort, {emitEvent: false});
        });


        // filter form group => state changes
        this.filterFormGroup.valueChanges
        .pipe(debounceTime(300), takeUntil(this._destroy$))
        .subscribe(value => {
            this._store.dispatch(new FilterCultivarIndex(value));
        });


        // sort form group => state changes
        this.sortFormGroup.valueChanges
        .pipe(debounceTime(300), takeUntil(this._destroy$))
        .subscribe(value => {
            this._store.dispatch(new SortCultivarIndex(value.column, value.order));
        });

        this.selection.changed
            .pipe(takeUntil(this._destroy$), debounceTime(100))
            .subscribe(val => {
                this._store.dispatch(new SetCultivarIndexSelected(this.selection.selected));
            })

        // Ensures that selection on UI always matches the state
        this.state$.subscribe(data => {
            if (data == null) {
                this.selection.clear();
                return;
            }

            if (data.selectedKeys.length == 0) {
                this.selection.clear();
                return;
            }

            data.selectedKeys.forEach(key => this.selection.select(key));
        })

    }

    ngOnDestroy(){
        this._destroy$.next();
        this._destroy$.complete();
    }

    add(){
        const state: CultivarIndexStateModel = this._store.selectSnapshot(CultivarIndexState);

        const data: CultivarFormDialogData = {
            defaults: {
                commonName: state.query.search,
                harvestWindow: state.query.harvestWindow.length ? state.query.harvestWindow[0] : null,
                cropId: state.query.cropId.length ? state.query.cropId[0] : "apple",
                isRootstock: state.query.isRootstock !== '*' ? !!state.query.isRootstock : false,
                licensee: state.query.licensee.length ? state.query.licensee[0] : null,
                ownerOrgKey: state.orgKey,
            }
        };

        const ref = this._dialogs.open(CultivarFormDialog, { data });
    }

    edit(model: Cultivar){
        const data: CultivarFormDialogData = { key: model.key };
        const ref = this._dialogs.open(CultivarFormDialog, { data });
    }

    switchCultivar(model: Cultivar) {
        const data: CultivarSwitchFormDialogData = { key: model.key, defaults: model };
        this._dialogs.open(CultivarSwitchFormDialog, { data });
    }

    remove(model: Cultivar) {

        if(
            model.rootstockHarvestSamplesCount
            || model.scionHarvestSamplesCount
            || model.rootstockPlantSamplesCount
            || model.scionPlantSamplesCount
        ){
            this._snackbar.error('Remove sample connections or refresh data before cultivar can be deleted.');
            return;
        }


        let dialog = this._dialog.confirm(
            'Remove Cultivar',
            'Are you sure you want to delete this cultivar? ',
            'Remove',
            'Cancel'
        ).afterClosed().subscribe(res => {
            if(res) {
                this._store.dispatch(new DeleteCultivar(model.key));
            }
        });
    }

    openGenerateDialog(selectedKeys: string[]): void {
        if (selectedKeys.length === 0) {
            this._snackbar.error('No Cultivars Selected')
            return;
        }

        const state: CultivarIndexStateModel = this._store.selectSnapshot(CultivarIndexState);

        if (!this.validateGenerateDialogData(state.selectedData)) {
            this._snackbar.error('Rootstock Cultivars are not a valid selection. Only Scion Cultivars can be used during this operation.');
            return;
        }

        const data: CultivarTastingsSampleFormDialogData = {
            cultivarKeys: selectedKeys,
            seletedCultivars: state.selectedData,
            defaults: {
                ownerOrgKey: state.orgKey,
            },
            hasEvaluationModule: this.hasEvaluationModule,
        }

        this._dialog.open(CultivarTastingsSampleFormDialog, { data });
    }

    openTastingsLanding() {
        window.open(`${this._baseWebUri}/modules/tasting`, '_blank');
    }

    validateGenerateDialogData(selectedData: Cultivar[]): boolean {
        return selectedData.every(cultivar => !cultivar.isRootstock);
    }

    reset(){
        this._store.dispatch(new ResetCultivarIndex());
    }

    paginate(page: PageEvent){
        this._store.dispatch(new PageCultivarIndex(page.pageIndex, page.pageSize));
    }

    sort(sort: Sort){

        if(sort.direction){

            this.sortFormGroup.setValue({
                column: sort.active,
                order: sort.direction
            });

        }else{
            this.sortFormGroup.setValue({
                column: 'commonName',
                order: 'asc'
            });
        }

    }

    toggleSortOrder(event: MouseEvent) {
        event.stopPropagation();
        this.orderControl.setValue(this.orderControl.value === 'asc' ? 'desc' : 'asc');
    }

    reload(){
        this._store.dispatch(new LoadCultivarIndex);
    }

    export(){

        let exportOptions = this.exportFormGroup.value;

        this.exportFormGroup.disable();

        this._store.dispatch(new ExportCultivarIndex(exportOptions.type))
            .subscribe(
                s => {
                    this.exportFormGroup.enable();

                    let exp = this._store.selectSnapshot(CultivarIndexState.latestExport);

                    if(exp && exp.status === Status.COMPLETE){
                        this._snackbar.info(
                            `Export is queued. The result will be emailed to you once the export is complete.`,
                        );
                    }


                },
                e => {
                    this.exportFormGroup.enable();
                }
            );
    }

    isAllSelected(data: Cultivar[]) {
        return data.every((cultivar) => {
            return this.selection.isSelected(cultivar.key);
        });
    }

    masterToggle(data: Cultivar[]) {
        this.isAllSelected(data) ?
            data.forEach(row => this.selection.deselect(row.key)) :
            data.forEach(row => this.selection.select(row.key));
    }

    trackByKey(index, item) {
        return item.key;
    }

    setFormFile(file: File) {
        this.importFormGroup.get('file').setValue(file);
    }

    attemptImport() {
        if (!this.importFormGroup.valid || !this.importFormGroup.value.file) {
            this.handleErrorMessage('Invalid Input. Check your input and try again.');
            return;
        }

        let file: File = this.importFormGroup.value.file;
        this.import(file);
    }

    import(file: File) {
        this._store.dispatch(new ImportCultivarIndex(file));
    }

    downloadImportTemplate() {
        this._store.dispatch(new DownloadCultivarImportTemplate());
    }

    exportHistory(orgKey: string) {
        const data: ExportHistoryDialogData = {
            orgKey: orgKey,
            type: ExportType.CULTIVARS,
        }

        this._dialog.open(ExportHistoryDialog, { data })
    }

    private handleErrorMessage(message: string) {
        this._snackbar.error(message);
    }
}