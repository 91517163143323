import { Cultivar, FilterPage, FilterSort, Sample, SampleType, Site, Status } from '@core/data';
import { SampleLabelOptions } from '../sample-labels/sample-label-options';
import { Protocol } from '@library';

export interface SampleIndexStateModel {

    // org context
    orgKey: string;

    // sample type context
    sampleType: SampleType;

    // table data
    status: Status;
    data: Sample[];
    total: number;

    // filter options
    page: FilterPage;
    sort: FilterSort;
    query: SampleIndexQuery;

    // table options
    displayColumns: string[];
    sortOptions: { id: string, label: string }[];
    pageSizeOptions: number[];
    licenseeOptions: string[];

    // export result
    exports: {
        key: string;
        status: Status;
    }[];

    // export result
    exportStats: {
        key: string;
        status: Status;
    }[];

    // barcode/label printing configuration
    labelOptions: SampleLabelOptions;

    // selection
    selectedKeys: string[];
    selectedData: Sample[];

    protocolOptions: Protocol[];
}

export interface SampleIndexQuery {
    search: string;
    scionCultivars: Cultivar[];
    rootstockCultivars: Cultivar[];
    sites: Site[];
    scionCropId: string[];
    scionLicensee: string[];
    groupKey: string[];

    birthYear?: number[];
    birthWeek?: number[];

    startEvalPeriod?: string;
    endEvalPeriod?: string;

    hideDestroyed?: boolean;
    excludeYear?: boolean;

    rowIndex?: string;
    positionIndex?: string;
}

export const SAMPLE_SORT_OPTIONS: { id: string, label: string }[] = [
    { id: 'rowPosition', label: 'Row Position' },
    { id: 'siteName', label: 'Site' },
    { id: 'birthDate', label: 'Harvest/Plant Date' },
    { id: 'label', label: 'Sample Label' },
    { id: 'size', label: 'Sample Size' },
    { id: 'scionCultivarName', label: 'Scion Cultivar' },
    { id: 'rootstockCultivarName', label: 'Rootstock Cultivar' },
    { id: 'evalsCount', label: '# Evaluations' },
    { id: 'harvestSamplesCount', label: '# Harvests' },
    { id: 'updatedAt', label: 'Updated Date' },
    { id: 'createdAt', label: 'Created Date' },
];

export const HARVEST_SAMPLE_DISPLAY_COLUMNS = [
    'image',
    'code',
    'description',
    'actions',
    'birthDate',
    'cultivars',
    'plantSampleDate',
    'evaluation',
    'location',
    'size',
    'timestamps',
];

export const PLANT_SAMPLE_DISPLAY_COLUMNS = [
    'image',
    'code',
    'description',
    'actions',
    'birthDate',
    'cultivars',
    'evaluation',
    'location',
    'size',
    'timestamps',
];

export const HARVEST_LABEL_OPTIONS: SampleLabelOptions = {
    layout: 'land',
    size: 'md',
    includeFields: ['scionCultivar', 'rootstockCultivar', 'site'],
    margin: '1mm',
    whitespace: true,
    duplicateAmount: 0
};

export const PLANT_LABEL_OPTIONS: SampleLabelOptions = {
    layout: 'port',
    size: 'md',
    includeFields: ['site', 'rowPosition'],
    margin: '5mm',
    whitespace: false,
    duplicateAmount: 0
};

export const DEFAULTS: SampleIndexStateModel = {
    orgKey: null,
    sampleType: SampleType.PLANT,
    status: Status.UNINITIALIZED,
    data: [],
    total: 0,
    page: {
        index: 0,
        size: 20
    },
    sort: {
        column: 'updatedAt',
        order: 'desc'
    },
    displayColumns: PLANT_SAMPLE_DISPLAY_COLUMNS,
    sortOptions: SAMPLE_SORT_OPTIONS,
    pageSizeOptions: [20,50,100,500,1000],
    query: {
        search: null,
        rootstockCultivars: [],
        scionCultivars: [],
        sites: [],
        birthYear: [],
        birthWeek: [],
        scionCropId: [],
        scionLicensee: [],
        groupKey: [],
        rowIndex: null,
        positionIndex: null,
        hideDestroyed: true,
        excludeYear: false,
        startEvalPeriod: null,
        endEvalPeriod: null,
    },
    labelOptions: PLANT_LABEL_OPTIONS,
    exports: [],
    exportStats: [],
    licenseeOptions: [],
    selectedKeys: [],
    selectedData: [],
    protocolOptions: [],
};

