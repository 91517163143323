<pv-view *ngIf="state$ | async as state"
         toolbarTitle="Report Builder"
         toolbarColor="accent"
         (printModeChange)="onPrintModeChange($event)">

    <!-- TOOLS -->
    <div pv-view-tools class="report-actions">

        <!-- Save Report -->
        <button mat-icon-button
                matTooltip="Save Report"
                (click)="attempt()">
            <mat-icon>save</mat-icon>
        </button>

        <!-- Create Template -->
        <button mat-icon-button
                matTooltip="Create Template"
                (click)="createTemplate()">
            <mat-icon class="material-symbols-outlined">assignment_add</mat-icon>
        </button>

        <!-- Apply Template -->
        <button mat-icon-button
                matTooltip="Apply Template"
                (click)="applyTemplate()">
            <mat-icon class="material-symbols-outlined">assignment</mat-icon>
        </button>

        <!-- Undo Apply Template -->
        <button mat-icon-button
                matTooltip="Undo Template"
                (click)="revertTemplate()"
                [class.disabled-button]="undoTemplates.length === 0"
                [disabled]="undoTemplates.length === 0">
            <mat-icon class="material-symbols-outlined">assignment_return</mat-icon>
        </button>

        <!-- Undo Apply Template -->
        <button mat-icon-button
                matTooltip="Redo Template"
                (click)="revertTemplate(true)"
                [class.disabled-button]="redoTemplates.length === 0"
                [disabled]="redoTemplates.length === 0">
            <mat-icon class="material-symbols-outlined invert-y">assignment_return</mat-icon>
        </button>

        <!-- Return To Index -->
        <button mat-icon-button
                matTooltip="Return to Index"
                (click)="back()">
            <mat-icon>arrow_back</mat-icon>
        </button>

        <!-- Print Preview -->
        <button mat-icon-button
                matTooltip="Print Preview"
                (click)="printPreview()">
            <mat-icon>print</mat-icon>
        </button>

    </div>
    <!-- TOOLS END -->

    <!-- SIDEBAR -->
    <div pv-view-sidebar class="report-builder-sidebar mode-{{ viewMode }}">

        <mat-tab-group (selectedIndexChange)="tabIndexChanged($event)" animationDuration="0ms">
            <mat-tab>

                <ng-template mat-tab-label>
                    <mat-icon matTooltip="Report Options">assessment</mat-icon>
                </ng-template>

                <!-- REPORT FORM -->
                <form class="pv-form single-column p-12 report-form" [formGroup]="formGroup">

                    <div class="form-subsection">

                        <h3 class="form-subheader">Title &amp; Description</h3>

                        <!-- control formGroup.title -->
                        <mat-form-field appearance="outline">
                            <mat-label>Report Title</mat-label>
                            <input type="text" matInput
                                   formControlName="title">
                            <mat-error>Title is required</mat-error>
                        </mat-form-field>

                        <!-- control formGroup.description -->
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Description</mat-label>
                            <textarea matInput
                                formControlName="description"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10">
                            </textarea>
                        </mat-form-field>

                    </div>


                    <!-- OPTIONS FORM (control formGroup.options) -->
                    <ng-container formGroupName="options">

                        <div class="form-subsection">

                            <h3 class="form-subheader">
                                <span>Options</span>
                            </h3>

                            <!-- control formGroup.toggleDescription -->
                            <mat-checkbox formControlName="toggleDescription"
                                          labelPosition="before">
                                        Show Report Description
                            </mat-checkbox>

                            <!-- control formGroup.options.showEmptyFields -->
                            <mat-checkbox formControlName="showEmptyFields"
                                          labelPosition="before">
                                Show Empty Fields
                            </mat-checkbox>

                            <!-- control formGroup.options.themeId -->
                            <mat-form-field appearance="outline">
                                <mat-label>Theme</mat-label>
                                <mat-select formControlName="themeId">
                                    <mat-option *ngFor="let theme of themeOptions"
                                                [value]="theme.id">
                                        {{ theme.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Letterhead</mat-label>
                                <mat-select (selectionChange)="updateLetterhead($event.value)" formControlName="letterheadKey">
                                    <mat-select-trigger>
                                        <div class="letterhead-option" *ngIf="letterheadControl.value">
                                            <img class="letterhead-option-image" [src]="letterheadControl.value?.fileRef | srcFileRef:{height: 39}" alt="">
                                        </div>
                                    </mat-select-trigger>
                                    <mat-option [value]="null">None</mat-option>
                                    <mat-option *ngFor="let letterheadOption of state.letterheadOptions" [value]="letterheadOption.key" >
                                        <div class="letterhead-option">
                                            <img class="letterhead-option-image" [src]="letterheadOption.fileRef| srcFileRef:{height: 39}">
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!-- Add Letterhead -->
                            <div class="letterhead-section">
                                <span matLine>Add Letterhead</span>
                                <button class="letterhead-button" mat-icon-button aria-label="Add Letterhead" matTooltip="Add Letterhead" (click)="addLetterhead(state.key, state.orgKey)">
                                    <mat-icon>add_circle</mat-icon>
                                </button>
                            </div>

                            <!-- Timeline Sync -->
                            <div class="letterhead-section">
                                <span matLine>Timeline Sync</span>
                                <button (click)="syncTimeLines()" class="letterhead-button" mat-icon-button matTooltip="Sync Timeline" mat-icon-button>
                                    <mat-icon>history</mat-icon>
                                </button>
                            </div>
                        </div>

                    <!--REPORT SECTIONS START-->
                    <div class="form-subheader-icon">

                        <div class="section-heading-title">

                            <h3 class="form-subheader"><span>Report Sections</span></h3>
                        </div>

                        <div class="section-heading-actions">

                            <!-- Reset Sections -->
                            <button (click)="resetSectionsOrder()" matTooltip="Reset to Default Order" [disabled]="lockSections" mat-icon-button>
                                <mat-icon>restart_alt</mat-icon>
                            </button>

                            <!-- Lock Sections -->
                            <button (click)="toggleLock()" matTooltip="Lock/Unlock Section Reordering" mat-icon-button>
                                <mat-icon>{{lockSections ? 'lock' : 'lock_open'}}</mat-icon>
                            </button>
                        </div>

                    </div>

                        <div cdkDropList class="report-sections-droplist" (cdkDropListDropped)="drop($event)">

                            <div class="form-subsection-droplist" [style.cursor]="lockSections ? 'pointer' : 'move'" *ngFor="let section of reportSections" cdkDrag [cdkDragDisabled]="lockSections" cdkDragLockAxis="y">

                                <!--TITLE, ICON, CHECKBOX-->
                                <h3 class="form-subheader" [class.text-muted]="!section.enabled">
                                    <mat-icon class="mat-16">{{section.iconRef}}</mat-icon>
                                    <span>{{section.title}}</span>
                                    <mat-checkbox (click)="toggleSection(section.title)" [formControlName]="section.formControlName"></mat-checkbox>
                                </h3>

                                <!--SECTION OPTIONS-->
                                <ng-container *ngIf="section.formGroupName">
                                    <ng-container [formGroupName]="section.formGroupName">

                                        <div *ngFor="let subSection of section.options">
                                            <div [ngSwitch]="subSection.type">

                                                <!--Type: Checkboxes-->
                                                <div *ngSwitchCase="'check'">
                                                    <h5 class="form-subheader" *ngIf="section.enabled">
                                                        <span>{{subSection.title}}</span>
                                                        <mat-checkbox [formControlName]="subSection.formControlName"></mat-checkbox>
                                                    </h5>
                                                </div>

                                                <!--Type: Select Many-->
                                                <div *ngSwitchCase="'select-many'">

                                                    <div [ngSwitch]="subSection.formControlName">
                                                        <!--Chars-->

                                                        <div *ngSwitchCase="'includeChars'">

                                                           <!--Info Sheet-->
                                                           <div *ngIf="section.formGroupName=='cultivarInfoOptions' && section.enabled">
                                                            <div class="form-subsection">
                                                                <div class="letterhead-section">
                                                                    <span matLine>Set All Characteristics</span>
                                                                    <button class="letterhead-button" matTooltip="Set Characteristics" mat-icon-button (click)="setAllInfoCharacteristics()">
                                                                        <mat-icon class="material-symbols-outlined fill">add</mat-icon>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="form-subsection">
                                                                <div class="letterhead-section">
                                                                    <span matLine>Clear All Characteristics</span>
                                                                    <button class="letterhead-button" matTooltip="Clear Characteristics" mat-icon-button (click)="clearInfoCharacteristics()">
                                                                        <mat-icon class="material-symbols-outlined">delete_sweep</mat-icon>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                                <mat-form-field appearance="outline">
                                                                    <mat-label>Include Characteristics</mat-label>
                                                                    <mat-select formControlName="includeChars" multiple>
                                                                        <mat-select-trigger>
                                                                            <span *ngIf="cultivarInfoOptionsGroup.controls.includeChars.value">
                                                                                {{  includeInfoSheetCharsControl.value.length + ' Selected' }}
                                                                            </span>
                                                                        </mat-select-trigger>
                                                                        <mat-optgroup *ngFor="let group of characteristicGroups; trackBy: trackById"
                                                                                    [label]="group.category?.label || group.id">
                                                                            <mat-option *ngFor="let char of group.chars; trackBy: trackById"
                                                                                        [value]="char.id" [disabled]="disableInfoSheetChars">
                                                                                {{ char.label }}
                                                                            </mat-option>
                                                                        </mat-optgroup>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>

                                                            <!--Chars Summary-->
                                                            <div *ngIf="section.formGroupName=='charSummaryOptions' && section.enabled">
                                                                <div class="form-subsection">
                                                                    <div class="letterhead-section">
                                                                        <span matLine>Set All Characteristics</span>
                                                                        <button class="letterhead-button" matTooltip="Set Characteristics" mat-icon-button (click)="setAllCharacteristics()">
                                                                            <mat-icon class="material-symbols-outlined fill">add</mat-icon>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div class="form-subsection">
                                                                    <div class="letterhead-section">
                                                                        <span matLine>Clear All Characteristics</span>
                                                                        <button class="letterhead-button" matTooltip="Clear Characteristics" mat-icon-button (click)="clearCharacteristics()">
                                                                            <mat-icon class="material-symbols-outlined">delete_sweep</mat-icon>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <mat-form-field appearance="outline">
                                                                    <mat-label>Include Characteristics</mat-label>
                                                                    <mat-select formControlName="includeChars" multiple>
                                                                        <mat-select-trigger>
                                                                            <span *ngIf="includeCharsControl.value">
                                                                                {{ includeCharsControl.value.length + ' Selected' }}
                                                                            </span>
                                                                        </mat-select-trigger>
                                                                        <mat-optgroup *ngFor="let group of characteristicGroups; trackBy: trackById"
                                                                                        [label]="group.category?.label || group.id">

                                                                            <mat-option *ngFor="let char of group.chars; trackBy: trackById"
                                                                                        [value]="char.id" [disabled]="disableIndvChars">
                                                                                {{ char.label }}
                                                                            </mat-option>
                                                                        </mat-optgroup>
                                                                    </mat-select>
                                                                </mat-form-field>

                                                            </div>
                                                        </div>

                                                        <!--Measurements-->
                                                        <div *ngSwitchCase="'includeMeasures'">

                                                            <!--Info Sheet-->
                                                            <div *ngIf="section.formGroupName=='cultivarInfoOptions' && section.enabled">
                                                                <mat-form-field appearance="outline">
                                                                    <mat-label>Include Measurements</mat-label>
                                                                    <mat-select formControlName="includeMeasures" multiple>
                                                                        <mat-select-trigger>
                                                                                {{
                                                                                    includeInfoSheetMeasuresControl.value[0] === '*'
                                                                                    ? 'All Measurements'
                                                                                    : includeInfoSheetMeasuresControl.value.length + ' Selected'
                                                                                }}
                                                                        </mat-select-trigger>
                                                                        <mat-option value="*">* All Measurements</mat-option>
                                                                        <mat-optgroup *ngFor="let group of measurementGroups; trackBy: trackById"
                                                                                      [label]="group.subject?.label || group.id">

                                                                            <mat-option *ngFor="let measure of group.measures; trackBy: trackById"
                                                                                        [value]="measure.id" [disabled]="disableInfoSheetMeasures">
                                                                                {{ measure.label }} [{{ measure.unit }}]
                                                                            </mat-option>

                                                                        </mat-optgroup>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>

                                                            <!--Meas Summary-->
                                                            <div *ngIf="section.formGroupName=='measureSummaryOptions' && section.enabled">
                                                                <mat-form-field appearance="outline">
                                                                    <mat-label>Include Measurements</mat-label>
                                                                    <mat-select formControlName="includeMeasures" multiple>
                                                                        <mat-select-trigger>
                                                                            {{
                                                                                includeMeasuresControl.value[0] === '*'
                                                                                ? 'All Measurements'
                                                                                : includeMeasuresControl.value.length + ' Selected'
                                                                            }}
                                                                        </mat-select-trigger>
                                                                        <mat-option value="*">* All Measurements</mat-option>
                                                                        <mat-optgroup *ngFor="let group of measurementGroups; trackBy: trackById"
                                                                                      [label]="group.subject?.label || group.id">

                                                                            <mat-option *ngFor="let measure of group.measures; trackBy: trackById"
                                                                                        [value]="measure.id" [disabled]="disableIndvMeasures">
                                                                                {{ measure.label }} [{{ measure.unit }}]
                                                                            </mat-option>

                                                                        </mat-optgroup>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>

                                                        <!--Index-->
                                                        <div *ngSwitchCase="'includeIndexes'">

                                                            <!--Info Sheet-->
                                                            <div *ngIf="section.formGroupName=='cultivarInfoOptions' && section.enabled">
                                                                <mat-form-field appearance="outline">
                                                                    <mat-label>Include Index Charts</mat-label>
                                                                    <mat-select formControlName="includeIndexes" multiple>
                                                                        <mat-select-trigger>
                                                                            <span *ngIf="cultivarInfoOptionsGroup.controls.includeIndexes.value">
                                                                                {{ cultivarInfoOptionsGroup.controls.includeIndexes.value.length + ' Selected' }}
                                                                            </span>
                                                                        </mat-select-trigger>
                                                                        <mat-option *ngFor="let index of indexOptions; trackBy: trackById" [value]="index.id">
                                                                            {{ index.label }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>

                                                            <!--Index Options-->
                                                            <div *ngIf="section.formGroupName=='charIndexOptions' && section.enabled">
                                                                <mat-form-field appearance="outline">
                                                                    <mat-label>Include Indexes</mat-label>
                                                                    <mat-select formControlName="includeIndexes" multiple>
                                                                        <mat-select-trigger>
                                                                            {{ includeIndexesControl.value[0] === '*' ? 'All Indexes' : includeIndexesControl.value.length + ' Selected' }}
                                                                        </mat-select-trigger>
                                                                        <mat-option value="*">* All Indexes</mat-option>
                                                                        <mat-option *ngFor="let index of indexOptions; trackBy: trackById"
                                                                                    [value]="index.id" [disabled]="disableIndvIndexes">
                                                                            {{ index.label }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!--Type: Select One-->
                                                <div *ngSwitchCase="'select-one'">
                                                    <mat-form-field appearance="outline" *ngIf="section.enabled">
                                                        <mat-label>{{subSection.title}}</mat-label>
                                                        <mat-select [formControlName]="subSection.formControlName">
                                                            <mat-option value="s">Small</mat-option>
                                                            <mat-option value="m">Medium</mat-option>
                                                            <mat-option value="l">Large</mat-option>
                                                            <mat-option value="xl">X-Large</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>

                                            </div>
                                         </div>
                                    </ng-container>
                                </ng-container>


                            </div>

                        </div>
                    <!--SECTIONS END-->

                </ng-container>
                <!-- OPTIONS FORM END -->

                </form>
            </mat-tab>


            <!-- SAMPLE IMPORT TAB -->
            <mat-tab>

                <ng-template mat-tab-label>
                    <mat-icon matTooltip="Sample Data">science</mat-icon>
                </ng-template>

                <ng-template matTabContent>
                    <div class="builder-sample-import">
                        <div class="pv-form single-column sample-import-form">
                            <div class="form-subsection">
                                <h3 class="form-subheader">
                                    <span>Cultivar Selection</span>
                                    <mat-checkbox [formControl]="showCultivarSelectionControl"></mat-checkbox>
                                </h3>
                            </div>

                            <ng-container *ngIf="showCultivarSelectionControl.value">
                                <mat-form-field appearance="outline" floatLabel="always">
                                    <mat-label>Primary Cultivar</mat-label>
                                    <pv-cultivar-autocomplete [orgKey]="state.orgKey"
                                                              [formControl]="primaryCultivarControl"
                                                              placeholder="Enter eg. 'granny smith'">
                                    </pv-cultivar-autocomplete>
                                </mat-form-field>

                                <mat-form-field appearance="outline" floatLabel="always">
                                    <mat-label>Control Cultivar</mat-label>
                                    <pv-cultivar-autocomplete [orgKey]="state.orgKey"
                                                              [formControl]="controlCultivarControl"
                                                              placeholder="Enter eg. 'granny smith'">
                                    </pv-cultivar-autocomplete>
                                </mat-form-field>
                            </ng-container>

                            <form [formGroup]="queryFormGroup">
                                <div class="search-group">
                                    <mat-form-field appearance="outline" floatLabel="always"
                                                    [ngSwitch]="state.sampleImport.status">
                                        <mat-label>Search Samples</mat-label>
                                        <input matInput type="text"
                                            formControlName="search"
                                            placeholder="Enter eg. 'granny smith'">
                                        <ng-container *ngSwitchCase="'OK'"></ng-container>
                                        <mat-icon matSuffix *ngSwitchCase="'LOADING'">hourglass_empty</mat-icon>
                                        <mat-icon matSuffix *ngSwitchCase="'EMPTY'">help_outline</mat-icon>
                                        <mat-icon matSuffix *ngSwitchDefault color="warn"
                                                [matTooltip]="state.sampleImport.status | descStatusMessage">
                                            error
                                        </mat-icon>
                                    </mat-form-field>

                                    <button mat-icon-button
                                        type="button"
                                        color="warn"
                                        matTooltip="Clear Filters"
                                        (click)="clearFilters()">
                                            <mat-icon>filter_alt_off</mat-icon>
                                    </button>
                                </div>

                                <div [matBadge]="sampleFilters">
                                    <mat-expansion-panel (opened)="panelOpenState = true"
                                                        (closed)="panelOpenState = false">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                Sample Filter
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <mat-form-field appearance="outline">
                                            <mat-label>
                                                <mat-icon>place</mat-icon>
                                                <span>Filter Location(s)</span>
                                            </mat-label>
                                            <pv-site-autocomplete-chips formControlName="sites"
                                                                        [orgKey]="state.orgKey">
                                            </pv-site-autocomplete-chips>
                                        </mat-form-field>

                                        <section>
                                            <mat-label class="filter-label">Sample Types</mat-label>
                                            <mat-checkbox class="example-margin" formControlName="plantSample">
                                                <mat-icon mat-list-icon svgIcon="common:plant_sample"></mat-icon>
                                                Plant Samples
                                            </mat-checkbox>
                                            <mat-checkbox class="example-margin" formControlName="harvestSample">
                                                <mat-icon mat-list-icon svgIcon="common:harvest_sample"></mat-icon>
                                                Harvest Sample
                                            </mat-checkbox>
                                        </section>

                                        <mat-form-field appearance="outline">
                                            <mat-label>
                                                <mat-icon svgIcon="common:flat_scion"></mat-icon>
                                                <span>Filter Scion Cultivar(s)</span>
                                            </mat-label>
                                            <pv-cultivar-autocomplete-chips formControlName="scionCultivars"
                                                                            [orgKey]="state.orgKey">
                                            </pv-cultivar-autocomplete-chips>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline">
                                            <mat-label>
                                                <mat-icon svgIcon="common:flat_rootstock"></mat-icon>
                                                <span>Filter Rootstock Cultivar(s)</span>
                                            </mat-label>
                                            <pv-cultivar-autocomplete-chips formControlName="rootstockCultivars"
                                                                            [orgKey]="state.orgKey">
                                            </pv-cultivar-autocomplete-chips>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline">
                                            <mat-label>Filter Crop</mat-label>
                                            <mat-select formControlName="scionCropId" multiple>
                                                <mat-option *ngFor="let crop of cropOptions" [value]="crop.id">
                                                    <mat-icon [svgIcon]="'common:' + crop.id"></mat-icon>
                                                    <span>{{ crop.label }}</span>
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <div class="flex-row">
                                            <mat-form-field appearance="outline" class="col-50" floatLabel="always">
                                                <mat-label>Harvest/Plant Year</mat-label>
                                                <mat-select formControlName="birthYear" multiple>
                                                    <mat-option *ngFor="let year of yearOptions" [value]="year">
                                                        {{ year }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="col-50" floatLabel="always">
                                                <mat-label>Week</mat-label>
                                                <mat-select formControlName="birthWeek" multiple>
                                                    <mat-option *ngFor="let week of weekOptions" [value]="week">
                                                        {{ week }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="pb-sm">
                                            <mat-checkbox formControlName="excludeYear">Exclude Years</mat-checkbox>
                                            <span class="mat-checkbox-hint">Exclude samples with selected Harvest/Plant year</span>
                                        </div>

                                        <div class="flex-row">
                                            <mat-form-field appearance="outline" class="col-50" floatLabel="always">
                                                <mat-label>Row</mat-label>
                                                <span matPrefix>R</span>
                                                <input matInput type="text" formControlName="rowIndex">
                                                <mat-error>Invalid range/set</mat-error>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="col-50" floatLabel="always">
                                                <mat-label>Position</mat-label>
                                                <span matPrefix>.</span>
                                                <input matInput type="text" formControlName="positionIndex">
                                                <mat-error>Invalid range/set</mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="flex-row">
                                            <mat-form-field floatLabel="always" appearance="outline" class="col-100">
                                                <mat-label>Hide Destroyed Samples</mat-label>
                                                <mat-slide-toggle formControlName="hideDestroyed"></mat-slide-toggle>
                                                <textarea matInput hidden></textarea>
                                            </mat-form-field>
                                        </div>

                                        <div class="flex-row">
                                            <mat-form-field appearance="outline" class="col-100">
                                                <input formControlName="startEvalPeriod" matInput [matDatepicker]="startEvalPeriodPicker" placeholder="Choose a start date">
                                                <mat-label>Evaluation Period Start</mat-label>
                                                <mat-hint>Filter Samples with Evaluations from this date</mat-hint>
                                                <mat-datepicker-toggle matSuffix [for]="startEvalPeriodPicker"></mat-datepicker-toggle>
                                                <mat-datepicker #startEvalPeriodPicker></mat-datepicker>
                                            </mat-form-field>
                                        </div>

                                        <div class="flex-row">
                                            <mat-form-field appearance="outline" class="col-100">
                                                <input formControlName="endEvalPeriod" matInput [matDatepicker]="endEvalPeriodPicker" placeholder="Choose an end date">
                                                <mat-label>Evaluation Period End</mat-label>
                                                <mat-hint>Filter Samples with Evaluations before this date</mat-hint>
                                                <mat-datepicker-toggle matSuffix [for]="endEvalPeriodPicker"></mat-datepicker-toggle>
                                                <mat-datepicker #endEvalPeriodPicker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </mat-expansion-panel>
                                </div>
                            </form>
                        </div>

                        <ng-container *ngIf="state.sampleImport.samples">
                            <div class="add-all-samples">
                                <mat-label >Add All Samples</mat-label>
                                <button  matTooltip="Add All Samples" mat-icon-button (click)="state.sampleImport.samples ? addAllSamples(state.sampleImport.samples) : addAllSamples(null)">
                                    <mat-icon>new_label</mat-icon>
                                </button>
                            </div>
                        </ng-container>

                        <mat-list dense class="builder-sample-list">
                            <mat-list-item *ngFor="let sample of state.sampleImport.samples; trackBy: trackByKey"
                                           class="builder-sample-item">
                                <mat-icon matListIcon
                                          [svgIcon]="'common:' + sample.type + '_sample'">
                                </mat-icon>
                                <span matLine>{{ sample.code }}</span>
                                <span matLine *ngIf="sample.label">{{ sample.label }}</span>
                                <span matLine *ngIf="sample.birthDate">
                                    {{ sample.type === 'plant' ? 'Planted' : 'Harvested' }}
                                    {{ sample.birthDate | amDateFormat:'ll' }}
                                </span>
                                <span matLine *ngIf="sample | rowPosition as rp">{{ rp }}</span>
                                <div matLine *ngIf="sample.site" [pvSiteLine]="sample.site"></div>
                                <div matLine *ngIf="sample.scionCultivar"
                                     [pvCultivarLine]="sample.scionCultivar" graft="scion"></div>
                                <div matLine *ngIf="sample.rootstockCultivar"
                                     [pvCultivarLine]="sample.rootstockCultivar" graft="rootstock">
                                </div>
                                <button mat-icon-button (click)="addSample(sample)">
                                    <mat-icon>add_circle</mat-icon>
                                </button>
                            </mat-list-item>
                            <mat-list-item *ngIf="!state.sampleImport?.samples?.length">
                                <mat-icon>block</mat-icon>
                                <span matLine>No Matching Samples Found</span>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </ng-template>
            </mat-tab>
            <!-- SAMPLE IMPORT END -->


            <!-- WEATHER IMPORT -->
            <mat-tab>

                <ng-template mat-tab-label>
                    <mat-icon matTooltip="Weather Data">thermostat</mat-icon>
                </ng-template>

                <ng-template matTabContent>
                    <ng-container *ngIf="state.weatherImport as import" [ngSwitch]="import.status">

                        <pv-view-loader *ngSwitchCase="'LOADING'"></pv-view-loader>

                        <div *ngSwitchCase="'EMPTY'" class="p-12">
                            <p class="mat-caption">
                                No weather stations to import weather reports from.
                                Add weather stations in the 'Weather' section.
                            </p>
                            <button mat-button (click)="reloadWeatherImport()">
                                Reload Stations
                            </button>
                        </div>

                        <ng-container *ngSwitchCase="'OK'">

                            <div class="pv-form single-column p-12">
                                <mat-form-field appearance="outline" floatLabel="always">
                                    <mat-label>Weather Station</mat-label>
                                    <mat-select [formControl]="weatherStationControl">
                                        <mat-option *ngFor="let station of import.stations"
                                                    [value]="station">
                                            {{ station.name }}
                                        </mat-option>
                                    </mat-select>
                                    <button matSuffix mat-icon-button (click)="reloadWeatherImport()">
                                        <mat-icon>refresh</mat-icon>
                                    </button>
                                </mat-form-field>
                                <ng-container *ngIf="weatherStationControl.value as station else noStationSelected">

                                    <ng-container *ngIf="station.sensorSummaries.length else noSensors">

                                        <mat-list>
                                            <h3 matSubheader>Available Sensor Data</h3>
                                            <mat-divider></mat-divider>
                                            <mat-list-item *ngFor="let summ of station.sensorSummaries">
                                                <span matLine>{{ summ.sensorId | labelSensor }}</span>
                                                <span matLine>
                                                    {{ summ.firstRecordedAt | amDateFormat:'ll' }}
                                                    - {{ summ.lastRecordedAt | amDateFormat:'ll' }}
                                                </span>
                                                <button mat-icon-button
                                                        (click)="addWeatherReport(station, summ)">
                                                    <mat-icon>add_circle</mat-icon>
                                                </button>
                                            </mat-list-item>
                                            <mat-list-item>
                                                <span matLine>New Report</span>
                                                <button mat-icon-button
                                                        (click)="addWeatherReport(station)">
                                                    <mat-icon>add_circle</mat-icon>
                                                </button>
                                            </mat-list-item>
                                        </mat-list>

                                        <p class="mat-caption" >
                                            Select a sensor above to add a new weather report.
                                        </p>

                                    </ng-container>

                                    <ng-template #noSensors>
                                        <p class="mat-caption text-warn">
                                            No sensor data available for this weather station.
                                            Select a different weather station or add data to this
                                            station in the 'Weather' section.
                                        </p>
                                    </ng-template>



                                </ng-container>
                                <ng-template #noStationSelected>
                                    <p class="mat-caption">
                                        Select a weather station to view available weather sensor data.
                                    </p>
                                </ng-template>
                            </div>



                        </ng-container>

                        <div *ngSwitchDefault class="p-12">
                            <p class="mat-caption text-warn">{{ import.status | descStatusMessage }}</p>
                        </div>

                    </ng-container>
                </ng-template>
            </mat-tab>
            <!-- WEATHER IMPORT END -->

            <!-- TABLE IMPORT -->
            <mat-tab>

                <ng-template mat-tab-label>
                    <mat-icon matTooltip="Custom Tables">table_view</mat-icon>
                </ng-template>

                <ng-template matTabContent>
                    <div class="pv-form single-column p-12">
                        <mat-list>
                            <h2 matSubheader>Report Tables</h2>
                            <mat-divider></mat-divider>
                            <mat-list-item>
                                <span matLine>New Table</span>
                                <button mat-icon-button (click)="addTableReport(state.data.samples)">
                                    <mat-icon>add_circle</mat-icon>
                                </button>
                            </mat-list-item>
                            <h2 matSubheader>Table Templates</h2>
                            <mat-divider></mat-divider>
                            <mat-list-item>
                                <span matLine>Create Table Template</span>
                                <button mat-icon-button (click)="openTableTemplateDialog(state.orgKey)">
                                    <mat-icon>view_list</mat-icon>
                                </button>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </ng-template>
            </mat-tab>
            <!-- TABLE IMPORT END -->

        </mat-tab-group>


    </div>
    <!-- SIDEBAR END -->


    <!-- BODY START -->
    <div pv-view-body [ngSwitch]="viewMode" class="report-builder-body mode-{{ viewMode }}">


        <!-- REPORT PREVIEW -->
        <ng-container *ngSwitchDefault [ngSwitch]="state.status">

            <pv-view-loader *ngSwitchCase="'LOADING'" message="Loading Report..."></pv-view-loader>

            <ng-container *ngSwitchCase="'OK'"></ng-container>

            <pv-report *ngIf="state.status === 'OK' || state.status === 'LOADING'"
                       [print]="print"
                       [report]="state.data"
                       [editable]="editable"
                       [formControlPassthrough]="samplesControl"
                       [infoSheetImagesTemp]="infoSheetImagesTemp">
            </pv-report>

            <pv-view-message *ngSwitchDefault
                             [heading]="state.status | descStatusTitle"
                             [message]="state.status | descStatusMessage:'Report'"
                             icon="error">
                <button mat-button
                        color="primary"
                        pvMessageAction
                        (click)="reload(false)">
                    Reload
                </button>
            </pv-view-message>


        </ng-container>
        <!-- END REPORT PREVIEW -->


        <!-- REPORT LIVE DATA -->
        <ng-container *ngSwitchCase="'samples'" [ngSwitch]="state.liveData.status">

            <pv-view-loader *ngSwitchCase="'LOADING'"></pv-view-loader>

            <ng-container *ngSwitchCase="'OK'"></ng-container>

            <div class="p-12" *ngSwitchCase="'EMPTY'">
                <p class="mat-caption">
                    Add a harvest or plant sample to this report by
                    searching for the sample using the search box on the left.
                    Search for any attribute (code, label, cultivar, rootstock, location, etc.)
                    associated with the sample.
                    Then, select <strong>+</strong> to import a sample search result.
                </p>
            </div>

            <!-- REPORT DATA TREE -->
            <pv-sample-tree *ngIf="state.liveData.status === 'OK' || state.liveData.status === 'LOADING'"
                            [formControl]="samplesControl"
                            [data]="state.liveData.data"
                            [reportSamples]="state.data.samples">
            </pv-sample-tree>

            <pv-view-message *ngSwitchDefault
                             [heading]="state.liveData.status | descStatusTitle"
                             [message]="state.liveData.status | descStatusMessage:'Data'"
                             icon="error">
                <button mat-button color="primary" pvMessageAction
                        (click)="reloadLiveData()">
                    Reload Data
                </button>
            </pv-view-message>

        </ng-container>
        <!-- REPORT LIVE DATA END -->

        <!-- REPORT WEATHER -->
        <ng-container *ngSwitchCase="'weather'" [ngSwitch]="state.status">

            <pv-view-loader *ngSwitchCase="'LOADING'"></pv-view-loader>

            <ng-container *ngSwitchCase="'OK'">

                <div class="pv-report-weather-list" *ngIf="state.data?.weather as reports" >

                    <div class="weather-panel" *ngFor="let report of reports">

                        <h4 class="weather-panel-title mat-title">
                            <mat-icon>show_chart</mat-icon>
                            <span>{{ report.title }}</span>
                        </h4>

                        <div class="weather-panel-info mat-caption">
                            <span>Station: {{ report.station.name }}</span><br>
                            <span>Report: {{ report.reportId | labelWeatherReport }}</span><br>
                            <span>Period: {{ report.startDate | amDateFormat:'ll' }} - {{ report.endDate | amDateFormat:'ll' }}</span><br>
                            <span>Interval: {{ report.interval }}</span>
                        </div>

                        <pv-chart class="weather-panel-chart"
                            *ngIf="report | compileWeatherChart:true as chart"
                            [type]="chart.type"
                            [data]="chart.data"
                            [options]="chart.options"
                            [chartLabel]="report.title">
                        </pv-chart>

                        <div class="weather-panel-actions">
                            <button mat-icon-button type="button" (click)="editWeatherReport(report)">
                                <mat-icon>settings</mat-icon>
                            </button>
                            <button mat-icon-button type="button" (click)="deleteWeatherReport(report)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>

                    </div>

                </div>
            </ng-container>

            <pv-view-message *ngSwitchDefault
                            [heading]="state.status | descStatusTitle"
                            [message]="state.status | descStatusMessage:'Report'"
                            icon="error">
                <button mat-button
                        color="primary"
                        pvMessageAction
                        (click)="reload(false)">
                    Reload
                </button>
            </pv-view-message>

        </ng-container>
        <!-- REPORT WEATHER END -->

        <!-- REPORT TABLES -->
            <ng-container *ngSwitchCase="'tabular'" [ngSwitch]="state.status">
                <pv-view-loader *ngSwitchCase="'LOADING'"></pv-view-loader>
                <ng-container *ngSwitchCase="'OK'">
                    <div>
                        <div *ngIf="reportTables" cdkDropList (cdkDropListDropped)="dropCustomTables($event)" [cdkDropListData]="reportTables">
                            <div class="table-panel" *ngFor="let table of reportTables" cdkDrag>
                                <h4 class="table-panel-title mat-title">
                                    <mat-icon>table_view</mat-icon>
                                    <span>
                                        {{ table.title }}
                                        <small>&nbsp;{{ table.tableOptions?.isAggregate ? "(Aggregate)" :  table.tableOptions?.isSpread ? "(Spread)" : "" }}</small>
                                    </span><br>
                                    <span><small>{{ table.description }}</small></span>
                                </h4>

                                <div class="table-panel-info mat-caption">
                                    <span>Selected Columns:</span>
                                    <p class="form-caption">
                                        {{ table.tableOptions?.columns | descTableReport }}
                                    </p>
                                </div>

                                <div class="table-panel-info">
                                    <button mat-icon-button
                                        (click)="openEvaluationSelectDialog(table, state.data.samples)"
                                        matTooltip="Selected Evaluations">
                                        <mat-icon [matBadgeHidden]="table.tableOptions?.includedEvals?.length === 0"
                                            [matBadge]="table.tableOptions?.includedEvals?.length"
                                            [matBadgeColor]="primary">
                                            assignment
                                        </mat-icon>
                                    </button>
                                </div>

                                <div class="table-panel-actions">
                                    <button mat-icon-button type="button" (click)="editTableReport(table)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button mat-icon-button (click)="openTableTemplateDialog(state.orgKey, table)">
                                        <mat-icon>view_list</mat-icon>
                                    </button>
                                    <button mat-icon-button type="button" (click)="deleteTableReport(table)">
                                        <mat-icon>clear</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <!-- REPORT TABLES END -->

    <!-- BODY END -->

</pv-view>
