<pv-view #view
         *ngIf="state$ | async as state"
         toolbarTitle="{{ state.sampleType | titlecase }} Sample Index">

    <!-- TOOLBAR ACTIONS -->
    <div pv-view-tools *ngIf="selectedOrg$ | async as selectedOrg">

        <button mat-icon-button
                matTooltip="Sample Index Actions"
                [matMenuTriggerFor]="sampleIndexMenu">
            <mat-icon>more_horiz</mat-icon>
        </button>

        <mat-menu #sampleIndexMenu>
            <ng-container *ngIf="selectedOrg?.access.role != 'guest'">
                <button mat-menu-item (click)="add()">
                    <mat-icon>add</mat-icon>
                    <span>Add Sample</span>
                </button>
            </ng-container>
            <button mat-menu-item (click)="exportHistory(state.orgKey, state.sampleType)" type="button">
                <mat-icon>history</mat-icon>
                <span>Export History</span>
            </button>
            <button mat-menu-item (click)="reload()">
                <mat-icon>refresh</mat-icon>
                <span>Reload Sample Index</span>
            </button>
            <button mat-menu-item (click)="view.togglePrintMode()">
                <mat-icon>print</mat-icon>
                <span>Print Preview</span>
            </button>
        </mat-menu>

    </div>
    <!-- TOOLBAR ACTIONS END -->

    <!-- SELECTION TOOLBAR -->
    <mat-toolbar pv-view-header class="sample-selection-toolbar mat-elevation-z3" color="accent"
                 *ngIf="!selection.isEmpty() && !view.printMode">

        <button mat-icon-button (click)="selection.clear()" matTooltip="Clear Selection">
            <mat-icon>clear</mat-icon>
        </button>

        <span class="flex-spacer">
            {{ selection.selected.length }} Samples Selected
        </span>

        <button mat-flat-button (click)="createReport()">
            <mat-icon>insert_chart</mat-icon>
            <span>Create Report</span>
        </button>

    </mat-toolbar>
    <!-- SELECTION TOOLBAR END -->


    <!-- SIDEBAR -->
    <div pv-view-sidebar *ngIf="selectedOrg$ | async as selectedOrg">


        <mat-tab-group [(selectedIndex)]="selectedTabIndex">

            <!-- FILTER TAB -->
            <mat-tab label="Filter">

                <ng-template matTabContent>
                    <form class="pv-form single-column dense p-12 sample-index-form"
                          [formGroup]="queryFormGroup">

                        <div class="search-group pb-sm">
                            <mat-form-field appearance="outline">
                                <mat-label>
                                    <mat-icon>search</mat-icon> Search Samples
                                </mat-label>
                                <input type="text" matInput formControlName="search"
                                    placeholder="Enter sample code or label">
                            </mat-form-field>

                            <button mat-icon-button type="button" color="warn" matTooltip="Clear Filters"
                                    (click)="resetFilter()">
                                <mat-icon>filter_alt_off</mat-icon>
                            </button>
                        </div>

                        <mat-form-field appearance="fill">
                            <mat-label>
                                <mat-icon svgIcon="common:flat_scion"></mat-icon>
                                <span>Filter Scion Cultivar(s)</span>
                            </mat-label>
                            <pv-cultivar-autocomplete-chips formControlName="scionCultivars"
                                                            [orgKey]="state.orgKey">
                            </pv-cultivar-autocomplete-chips>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>
                                <mat-icon svgIcon="common:flat_rootstock"></mat-icon>
                                <span>Filter Rootstock Cultivar(s)</span>
                            </mat-label>
                            <pv-cultivar-autocomplete-chips formControlName="rootstockCultivars"
                                                            [orgKey]="state.orgKey">
                            </pv-cultivar-autocomplete-chips>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>
                                <mat-icon>place</mat-icon>
                                <span>Filter Location(s)</span>
                            </mat-label>
                            <pv-site-autocomplete-chips formControlName="sites"
                                                        [orgKey]="state.orgKey">
                            </pv-site-autocomplete-chips>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Filter Crop</mat-label>
                            <mat-select formControlName="scionCropId" multiple>
                                <mat-option *ngFor="let crop of cropOptions" [value]="crop.id">
                                    <mat-icon [svgIcon]="'common:' + crop.id"></mat-icon>
                                    <span>{{ crop.label }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill" *ngIf="groups$ | async as groups; else groupLoader">
                            <mat-label>Filter Group</mat-label>
                            <mat-select formControlName="groupKey" multiple>
                                <mat-option *ngFor="let group of groups" [value]="group.key">
                                    <mat-icon svgIcon="common:{{ group.type }}_sample"></mat-icon>&nbsp;{{ group.label }}
                                </mat-option>
                            </mat-select>
                            <mat-error>Select a group</mat-error>
                        </mat-form-field>
                        <ng-template #groupLoader>
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </ng-template>

                        <mat-form-field appearance="fill">
                            <mat-label>Filter Licensee</mat-label>
                            <mat-select formControlName="scionLicensee" multiple>
                                <mat-option *ngFor="let licensee of state.licenseeOptions"
                                            [value]="licensee">{{ licensee }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="flex-row">
                            <mat-form-field appearance="fill" class="col-50" floatLabel="always">
                                <mat-label>Harvest/Plant Year</mat-label>
                                <mat-select formControlName="birthYear" multiple>
                                    <mat-option *ngFor="let year of yearOptions" [value]="year">
                                        {{ year }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-50" floatLabel="always">
                                <mat-label>Week</mat-label>
                                <mat-select formControlName="birthWeek" multiple>
                                    <mat-option *ngFor="let week of weekOptions" [value]="week">
                                        {{ week }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="pb-sm">
                            <mat-checkbox formControlName="excludeYear">Exclude Years</mat-checkbox>
                            <span class="mat-checkbox-hint">Exclude samples with selected Harvest/Plant year</span>
                        </div>

                        <div class="flex-row">
                            <mat-form-field appearance="fill" class="col-50" floatLabel="always">
                                <mat-label>Row</mat-label>
                                <span matPrefix>R</span>
                                <input matInput type="text" formControlName="rowIndex">
                                <mat-error>Invalid range/set</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-50" floatLabel="always">
                                <mat-label>Position</mat-label>
                                <span matPrefix>.</span>
                                <input matInput type="text" formControlName="positionIndex">
                                <mat-error>Invalid range/set</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="flex-row">
                            <mat-form-field floatLabel="always" appearance="fill" class="col-100">
                                <mat-label>Hide Destroyed Samples</mat-label>
                                <mat-slide-toggle formControlName="hideDestroyed"></mat-slide-toggle>
                                <textarea matInput hidden></textarea>
                            </mat-form-field>
                        </div>

                        <div class="flex-row">
                            <mat-form-field appearance="fill" class="col-100">
                                <input formControlName="startEvalPeriod" matInput [matDatepicker]="startEvalPeriodPicker" placeholder="Choose a start date">
                                <mat-label>Evaluation Period Start</mat-label>
                                <mat-hint>Filter Samples with Evaluations from this date</mat-hint>
                                <mat-datepicker-toggle matSuffix [for]="startEvalPeriodPicker"></mat-datepicker-toggle>
                                <mat-datepicker #startEvalPeriodPicker></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="flex-row">
                            <mat-form-field appearance="fill" class="col-100">
                                <input formControlName="endEvalPeriod" matInput [matDatepicker]="endEvalPeriodPicker" placeholder="Choose an end date">
                                <mat-label>Evaluation Period End</mat-label>
                                <mat-hint>Filter Samples with Evaluations before this date</mat-hint>
                                <mat-datepicker-toggle matSuffix [for]="endEvalPeriodPicker"></mat-datepicker-toggle>
                                <mat-datepicker #endEvalPeriodPicker></mat-datepicker>
                            </mat-form-field>
                        </div>

                    </form>

                </ng-template>

            </mat-tab>

            <!-- IMPORT & EXPORT TAB -->
            <mat-tab label="Import/Export">

                <ng-template matTabContent>
                    <ng-container>
                        <form [formGroup]="exportFormGroup"
                            class="pv-form single-column p-12 sample-export-form"
                            (ngSubmit)="export()">

                            <ng-container *ngIf="filterSelectedLabels">
                                <div class="form-subheader text-primary">
                                    Samples Selected
                                </div>
                                <div class="form-caption">
                                    Only selected samples will be exported.
                                    Clear selection to export all samples in current filter.
                                </div>
                            </ng-container>

                            <mat-form-field appearance="fill">
                                <mat-label>File Type</mat-label>
                                <mat-select formControlName="type">
                                    <mat-option value="xlsx">Microsoft Excel (.xlsx)</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="form-actions">

                                <button mat-flat-button color="primary" type="submit"
                                        [disabled]="exportFormGroup.disabled">Export</button>
                            </div>
                        </form>

                        <mat-action-list dense>
                            <mat-divider></mat-divider>

                            <ng-container *ngFor="let export of state.exports; trackBy: trackByKey"
                                        [ngSwitch]="export.status">
                                <a mat-list-item [href]="export.url" target="_blank"
                                *ngSwitchCase="'COMPLETE'">
                                    <mat-icon matListIcon>email</mat-icon>
                                    <span matLine>Export Queued</span>
                                    <span matLine>Check your email inbox for result.</span>
                                </a>
                                <button type="button" mat-list-item *ngSwitchCase="'LOADING'">
                                    <mat-icon matListIcon>queue</mat-icon>
                                    <span matLine>Queueing Export...</span>
                                    <mat-spinner [diameter]="18"></mat-spinner>
                                </button>
                                <button type="button" mat-list-item *ngSwitchDefault>
                                    <mat-icon matListIcon color="warn">error</mat-icon>
                                    <span matLine>Export Failed</span>
                                    <span matLine>{{ export.status | descStatusMessage }}</span>
                                </button>
                            </ng-container>

                        </mat-action-list>
                    </ng-container>

                    <ng-container>
                        <form [formGroup]="importFormGroup" class="pv-form single-column p-12" (ngSubmit)="attemptImport()">
                            <h4 class="form-subheader">Import</h4>

                            <pv-import-upload (file)="setFormFile($event)"></pv-import-upload>

                            <div class="form-actions">
                                <button mat-flat-button color="accent"
                                    (click)="$event.preventDefault(); downloadImportTemplate()"
                                    [disabled]="importFormGroup.disabled">Download Template</button>
                                <button mat-flat-button color="primary" type="submit"
                                    [disabled]="importFormGroup.disabled">Import</button>
                            </div>
                        </form>
                    </ng-container>

                    <mat-divider></mat-divider>

                    <!-- EXPORT STATS -->
                    <ng-container>
                        <form [formGroup]="exportStatsFormGroup"
                            class="pv-form single-column p-12 sample-export-form"
                            (ngSubmit)="exportStats()">

                            <h4 class="form-subheader">Sample Stat Export</h4>

                            <ng-container *ngIf="filterSelectedLabels">
                                <div class="form-subheader text-primary">
                                    Samples Selected
                                </div>
                                <div class="form-caption">
                                    Stat summary will only be exported for selected samples.
                                    Clear filter to export summary for all samples within the current filter.
                                </div>
                            </ng-container>

                            <div class="export-section-padding">
                                <mat-form-field appearance="outline">
                                    <mat-label>Export Protocol</mat-label>
                                    <mat-select formControlName="protocol" required>
                                        <mat-option *ngFor="let proto of state.protocolOptions" [value]="proto.id">
                                            <div class="protocol-option">
                                                <div class="option-label">{{ proto.title }}</div>
                                                <div class="option-icon">
                                                    <mat-icon class="mat-24" style="margin: 0;" [svgIcon]="'common:' + proto.crop"></mat-icon>
                                                </div>
                                            </div>
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>Select an Export Protocol</mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>Measurement Limit</mat-label>
                                    <input matInput type="number" formControlName="measureLimit">
                                    <span matSuffix>#</span>
                                    <mat-hint>Number of measurements to include per sample export</mat-hint>
                                    <mat-error>Measurement limit should be a number between 1 - 100</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-actions">
                                <button
                                    mat-flat-button
                                    color="primary"
                                    type="submit"
                                    [disabled]="exportStatsFormGroup.disabled">
                                        Export
                                </button>
                            </div>
                        </form>

                        <mat-action-list dense>
                            <mat-divider></mat-divider>

                            <ng-container *ngFor="let export of state.exportStats; trackBy: trackByKey"
                                        [ngSwitch]="export.status">
                                <a mat-list-item [href]="export.url" target="_blank"
                                *ngSwitchCase="'COMPLETE'">
                                    <mat-icon matListIcon>email</mat-icon>
                                    <span matLine>Export Queued</span>
                                    <span matLine>Check your email inbox for result.</span>
                                </a>
                                <button type="button" mat-list-item *ngSwitchCase="'LOADING'">
                                    <mat-icon matListIcon>queue</mat-icon>
                                    <span matLine>Queueing Export...</span>
                                    <mat-spinner [diameter]="18"></mat-spinner>
                                </button>
                                <button type="button" mat-list-item *ngSwitchDefault>
                                    <mat-icon matListIcon color="warn">error</mat-icon>
                                    <span matLine>Export Failed</span>
                                    <span matLine>{{ export.status | descStatusMessage }}</span>
                                </button>
                            </ng-container>

                        </mat-action-list>
                    </ng-container>
                </ng-template>
            </mat-tab>

            <!-- LABELS TAB -->
            <mat-tab label="Barcodes">
                <form *matTabContent class="pv-form single-column p-12 sample-barcodes-form"
                      [formGroup]="labelOptionsFormGroup">

                    <ng-container *ngIf="filterSelectedLabels">
                        <div class="form-subheader text-primary">
                            Samples Selected
                        </div>
                        <p class="form-caption">
                            Only selected samples are showing.
                            Clear selection to show all samples in current filter.
                        </p>
                    </ng-container>


                    <mat-form-field appearance="fill">
                        <mat-label>Label Layout</mat-label>
                        <mat-select formControlName="layout">
                            <mat-option value="port">Portrait</mat-option>
                            <mat-option value="land">Landscape</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Label Size</mat-label>
                        <mat-select formControlName="size">
                            <mat-option value="sm">Small (28mm qr-code)</mat-option>
                            <mat-option value="md">Medium (42mm qr-code)</mat-option>
                            <mat-option value="lg">Large (68mm qr-code)</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Include Fields</mat-label>
                        <mat-select multiple formControlName="includeFields">
                            <mat-option value="scionCultivar">Scion Cultivar</mat-option>
                            <mat-option value="codes">Code</mat-option>
                            <mat-option value="description">Description</mat-option>
                            <mat-option value="site">Location</mat-option>
                            <mat-option value="rowPosition">Row Position (Plant Samples Only)</mat-option>
                            <mat-option value="harvestWeek">Harvest Week (Harvest Samples Only)</mat-option>
                            <mat-option value="label">Label</mat-option>
                            <mat-option value="rootstockCultivar">Rootstock Cultivar</mat-option>
                            <mat-option value="tradeNames">Trade/Registered Names</mat-option>
                            <mat-option value="harvestDate">Planted Date</mat-option>

                        </mat-select>
                        <mat-hint>Info to show on label</mat-hint>
                    </mat-form-field>

                    <mat-slider
                        max="5"
                        min="0"
                        step="0.1"
                        thumbLabel="value"
                        formControlName="margin">
                    </mat-slider>
                    <span class="mat-checkbox-hint mat-form-field">Space Between Labels</span>

                    <mat-checkbox formControlName="whitespace">Include Whitespace</mat-checkbox>
                    <span class="mat-checkbox-hint">Add some space for writing</span>
                    <br/>

                    <mat-form-field appearance="fill">
                        <mat-label>Duplicate Amount:</mat-label>
                        <input matInput
                            type="number"
                            [formControl]="duplicateAmountControl"
                            placeholder="Number of duplicates to add"
                            min="0"
                            max="50"
                        />
                        <mat-hint>Up to 50 duplicates can be made of a selection, with a maximum of 1000 QR codes to be displayed at a time.</mat-hint>
                    </mat-form-field>
                    <br/>
                    <br/>

                    <div class="form-actions">
                        <button type="button" mat-flat-button color="primary"
                                (click)="view.togglePrintMode(true)">Print</button>
                    </div>

                </form>
            </mat-tab>

            <!-- BULK ACTION TAB -->
            <mat-tab label="Bulk Action">
                <ng-container *matTabContent>
                    <form
                    class="pv-form single-column p-12 dense sample-bulk-action-form"
                    id="bulk_action_form"
                    #form="ngForm"
                    [formGroup]="bulkActionFormGroup"
                    (ngSubmit)="bulkActionSave(selection.selected)"
                    autocomplete="off">

                        <div style="margin:8px; margin-top: 0;">
                            <ng-container *ngIf="selectedOrg?.access.role != 'guest'">
                                <mat-form-field appearance="standard">
                                    <mat-label>Select Sample Field</mat-label>
                                    <mat-select formControlName="bulkModifyOption">
                                        <ng-container *ngFor="let field of bulkFields">
                                            <mat-option [value]="field" *ngIf="field.type === state.sampleType">{{ field.label }}</mat-option>
                                            <mat-option [value]="field" *ngIf="field.type === null">{{ field.label }}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                    <mat-hint>Sample Info to be modified</mat-hint>
                                </mat-form-field>

                                <ng-container *ngIf="bulkModifyOptionControl.value?.fieldType === 'date'">
                                    <mat-form-field appearance="standard">
                                        <mat-label>{{ bulkModifyOptionControl.value?.label }}</mat-label>
                                        <input matInput formControlName="bulkFieldUpdate" [matDatepicker]="datePicker">
                                        <mat-datepicker-toggle matSuffix [for]="datePicker">
                                        </mat-datepicker-toggle>
                                        <mat-hint>{{ bulkModifyOptionControl.value?.hint }}</mat-hint>
                                        <mat-error innerHTML="{{ bulkModifyOptionControl.value?.error }}"></mat-error>
                                        <mat-datepicker #datePicker startView="multi-year"></mat-datepicker>
                                    </mat-form-field>
                                </ng-container>

                                <ng-container *ngIf="bulkModifyOptionControl.value?.fieldType === 'text'">
                                    <mat-form-field appearance="standard">
                                        <mat-label>{{ bulkModifyOptionControl.value?.label }}</mat-label>
                                        <input matInput type="text" formControlName="bulkFieldUpdate" autocomplete="off">
                                        <small matSuffix *ngIf="bulkModifyOptionControl.value?.suffix">{{ bulkModifyOptionControl.value?.suffix }}</small>
                                        <mat-hint>{{ bulkModifyOptionControl.value?.hint }}</mat-hint>
                                        <mat-error innerHTML="{{ bulkModifyOptionControl.value?.error }}"></mat-error>
                                    </mat-form-field>
                                </ng-container>

                                <ng-container *ngIf="bulkModifyOptionControl.value?.fieldType === 'textArea'">
                                    <mat-form-field appearance="standard">
                                        <mat-label>{{ bulkModifyOptionControl.value?.label }}</mat-label>
                                        <textarea matInput rows="4" formControlName="bulkFieldUpdate"
                                                style="resize: none;"></textarea>
                                        <mat-hint>{{ bulkModifyOptionControl.value?.hint }}</mat-hint>
                                        <mat-error innerHTML="{{ bulkModifyOptionControl.value?.error }}"></mat-error>
                                    </mat-form-field>
                                </ng-container>

                                <div class="form-actions">
                                    <button mat-raised-button color="primary" class="form-action mat-raised-button mat-button-base mat-primary" type="submit" style="margin-bottom: 10px;">
                                        <span>Save</span>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </form>

                    <mat-action-list class="station-detail-actions" dense>
                        <h2 matSubheader>Evaluation Actions</h2>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="$event.preventDefault(); addEvaluation(selection.selected)">
                            <mat-icon matListIcon>assignment</mat-icon>
                            <span matLine>Create Evaluations</span>
                        </mat-list-item>
                        <mat-list-item
                            *ngIf="state.sampleType == 'harvest'"
                            (click)="$event.preventDefault(); scheduleEvaluations(selection.selected)"
                            matTooltip="Schedule evaluations for samples of the same crop type"
                        >
                            <mat-icon matListIcon>pending_actions</mat-icon>
                            <span matLine>Schedule Evaluations</span>
                        </mat-list-item>
                        <!-- TODO: Future feature - still to implement -->
                        <!--<mat-list-item (click)="$event.preventDefault(); bulkEvaluationEdit(selection.selected)">
                            <mat-icon matListIcon>edit</mat-icon>
                            <span matLine>Edit Evaluations of Samples</span>
                        </mat-list-item>-->
                        <mat-list-item (click)="$event.preventDefault(); setBulkEvaluationEnd(selection.selected)">
                            <mat-icon matListIcon>event_available</mat-icon>
                            <span matLine>Set Evaluation End Date</span>
                        </mat-list-item>

                        <h2 matSubheader>Group Actions</h2>
                        <mat-divider></mat-divider>
                        <ng-container *ngIf="selectedOrg?.access.role != 'guest'">
                            <mat-list-item (click)="$event.preventDefault(); createSampleGroup(selection.selected)">
                                <mat-icon matListIcon>groups</mat-icon>
                                <span matLine>Create Sample Group</span>
                            </mat-list-item>

                            <mat-list-item (click)="$event.preventDefault(); addToGroup(selection.selected)">
                                <mat-icon matListIcon svgIcon="common:add_sample"></mat-icon>
                                <span matLine>Add Samples to Group</span>
                            </mat-list-item>

                            <mat-list-item (click)="$event.preventDefault(); bulkRemoveFromGroup(selection.selected, state.query.groupKey)">
                                <mat-icon matListIcon svgIcon="common:remove_sample"></mat-icon>
                                <span matLine>Remove Samples from Group</span>
                            </mat-list-item>
                        </ng-container>

                        <ng-container *ngIf="state.sampleType == 'harvest' && hasTastingsModule">
                            <h2 matSubheader>Tastings Actions</h2>
                            <mat-divider></mat-divider>

                            <mat-list-item (click)="$event.preventDefault(); createTastingsSamples(state.selectedData)">
                                <mat-icon matListIcon svgIcon="common:tastings_sample"></mat-icon>
                                <span matLine>Create Tasting Samples</span>
                            </mat-list-item>
                        </ng-container>

                        <h2 matSubheader>Sample Actions</h2>
                        <mat-divider></mat-divider>
                        <ng-container *ngIf="selectedOrg?.access.role != 'guest'">
                            <mat-list-item  *ngIf="state.sampleType == 'plant'"
                                            (click)="$event.preventDefault()"
                                            (click)="extractHarvestSamples(selection.selected)">
                                <mat-icon matListIcon svgIcon="common:harvest_sample"></mat-icon>
                                <span matLine>Extract Harvest Samples</span>
                            </mat-list-item>
                            <mat-list-item (click)="$event.preventDefault()" (click)="deleteSelected(selection.selected)">
                                <mat-icon matListIcon>delete</mat-icon>
                                <span matLine>Delete Samples</span>
                            </mat-list-item>
                        </ng-container>
                    </mat-action-list>
                </ng-container>
            </mat-tab>
            <!-- BULK ACTION TAB END -->

        </mat-tab-group>

    </div>
    <!-- SIDEBAR END -->


    <!-- PAGINATION & SORT FOOTER -->
    <div pv-view-footer class="pv-table-footer">

        <label class="mat-caption mr-12" for="sample_index_sort">Sort</label>
        <mat-select [formControl]="columnControl"
                    id="sample_index_sort"
                    class="sample-index-sort-select">
            <mat-option *ngFor="let opt of state.sortOptions" [value]="opt.id">{{ opt.label }}
            </mat-option>
        </mat-select>
        <button type="button" class="ml-12" mat-icon-button (click)="toggleSortOrder($event)" matTooltip="Change Sort Direction">
            <mat-icon
                      [svgIcon]="orderControl.value === 'asc' ? 'fa:solid-sort-alpha-up' : 'fa:solid-sort-alpha-down'">
            </mat-icon>
        </button>

        <mat-paginator [length]="state.total"
                       [pageSize]="state.page.size"
                       [pageIndex]="state.page.index"
                       (page)="paginate($event)"
                       [pageSizeOptions]="state.pageSizeOptions">
        </mat-paginator>
    </div>
    <!-- PAGINATION & SORT FOOTER END-->


    <!-- FAB -->
    <ng-container pv-view-fab *ngIf="selectedOrg$ | async as selectedOrg">
        <ng-container pv-view-fab *ngIf="selectedOrg?.access.role != 'guest'">
            <button pv-view-fab *ngIf="selection.isEmpty()" mat-fab color="primary" (click)="add()" matTooltip="Add Sample">
                <mat-icon>add</mat-icon>
            </button>
        </ng-container>
    </ng-container>


    <!-- BODY STATUS: OK -->
    <div pv-view-body class="sample-index-body" [ngSwitch]="state.status">


        <!-- STATUS MESSAGE: LOADING -->
        <pv-view-loader *ngSwitchCase="'LOADING'" message="Loading Samples..."></pv-view-loader>


        <!-- BODY STATUS: EMPTY -->
        <pv-view-message *ngSwitchCase="'EMPTY'"
                         heading="No results"
                         message="The current filters does not match any Samples. Update the filters or add a Sample."
                         icon="error">
            <button mat-raised-button color="accent"
                    pvMessageAction
                    (click)="resetFilter()">
                Reset Filters
            </button>
            <button mat-raised-button
                    color="primary"
                    pvMessageAction
                    (click)="add()">
                Add
            </button>
        </pv-view-message>


        <!-- STATUS MESSAGE: INVALID -->
        <pv-view-message *ngSwitchCase="'INVALID'"
                         heading="Invalid Filter Parameters"
                         message="Unable to load data with given filter and sort parameters."
                         icon="error">
            <button mat-raised-button color="accent"
                    pvMessageAction (click)="resetFilter()">
                Reset Filters
            </button>
            <button mat-raised-button color="primary" pvMessageAction
                    (click)="reload()">
                Reload
            </button>
        </pv-view-message>


        <!-- STATUS MESSAGE: ERROR / DEFAULT -->
        <pv-view-message *ngSwitchDefault [heading]="state.status | descStatusTitle"
                         [message]="state.status | descStatusMessage:'Sample'" icon="error">
            <button mat-raised-button color="primary" pvMessageAction
                    (click)="reload()">Reload</button>
        </pv-view-message>


        <!-- STATUS MESSAGE: OK (ngSwitch placeholder) -->
        <ng-container *ngSwitchCase="'OK'"></ng-container>


        <!-- TABLE / LABELS -->
        <ng-container [ngSwitch]="selectedTabIndex"
                      *ngIf="state.status === 'OK' || state.status === 'LOADING'">

            <pv-sample-labels
                              *ngSwitchCase="2"
                              [samples]="filterSelectedLabels ? state.selectedData : state.data"
                              [options]="state.labelOptions">
            </pv-sample-labels>


            <!-- TABLE -->
            <div class="pv-responsive-table" *ngIf="selectedOrg$ | async as selectedOrg">

                <table cdk-table
                       *ngSwitchDefault
                       class="pv-sample-table"
                       [dataSource]="state.data"
                       [trackBy]="trackByKey"
                       matSort
                       [matSortActive]="state.sort.column"
                       [matSortDirection]="state.sort.order"
                       [matSortDisableClear]="true"
                       (matSortChange)="sort($event)">

                    <!-- Column: Image -->
                    <ng-container cdkColumnDef="image">
                        <th cdk-header-cell *cdkHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle(state.data) : null"
                                          [checked]="selection.hasValue() && isAllSelected(state.data)"
                                          [indeterminate]="selection.hasValue() && !isAllSelected(state.data)"
                                          class="no-print">
                            </mat-checkbox>
                        </th>
                        <td cdk-cell *matCellDef="let row">

                            <pv-image-ref *ngIf="row.primaryImage as image"
                                          [fileRef]="image.fileRef"
                                          transformId="thumb_72">
                            </pv-image-ref>

                            <div class="image-overlay no-print" (click)="selection.toggle(row.key)">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                              (change)="$event ? selection.toggle(row.key) : null"
                                              [checked]="selection.isSelected(row.key)">
                                </mat-checkbox>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Column: Sample Label -->
                    <ng-container cdkColumnDef="code">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="label">Label</th>
                        <td cdk-cell *matCellDef="let row">
                            <span class="body-line"><strong>{{ row.label }}</strong></span>
                            <span class="body-line lg" *ngIf="row.type == 'plant'">{{ row.birthDate | amDateFormat:'Y' }}</span>
                            <span class="body-line lg" *ngIf="row.type == 'harvest'">{{ row.birthDate | amDateFormat:'[Week] W'}}, {{ row.birthDate | amDateFormat:'Y' }}</span>
                            <span class="body-line lg">{{ row.scionCultivar.commonName }}</span>
                            <span class="body-line lg">{{ row.code }}</span>
                        </td>
                    </ng-container>

                    <!-- Column: Actions -->
                    <ng-container cdkColumnDef="actions">
                        <th cdk-header-cell *cdkHeaderCellDef class="no-print"></th>
                        <td cdk-cell *matCellDef="let row" class="no-print">

                            <a mat-icon-button
                               (click)="$event.stopPropagation()"
                               [routerLink]="[row.key]"
                               matTooltip="View Sample Detail"
                               [ngStyle]="{'margin-right': selectedOrg?.access.role == 'guest' ? '10px' : 'none'}">
                                <mat-icon [matBadge]="row.evalsCount"
                                          [matBadgeColor]="row.evalsCount ? 'primary' : 'accent'">
                                    assignment
                                </mat-icon>
                            </a>
                            <button mat-icon-button
                                    *ngIf="row.type === 'plant'"
                                    (click)="$event.stopPropagation()"
                                    [matMenuTriggerFor]="harvestsMenu"
                                    [matMenuTriggerData]="{sample: row}"
                                    matTooltip="Harvests"
                                    [ngStyle]="{'margin-right': selectedOrg?.access.role == 'guest' ? '10px' : 'none'}">
                                <mat-icon svgIcon="common:harvest_sample"
                                          [matBadge]="row.harvestSamplesCount"
                                          [matBadgeColor]="row.harvestSamplesCount ? 'primary' : 'accent'">
                                </mat-icon>
                            </button>
                            <ng-container *ngIf="selectedOrg?.access.role != 'guest'">
                                <button mat-icon-button
                                        (click)="$event.stopPropagation()"
                                        [matMenuTriggerFor]="sampleMenu"
                                        [matMenuTriggerData]="{sample: row}"
                                        matTooltip="More Sample Actions">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>
                            </ng-container>
                        </td>
                    </ng-container>

                    <!-- Column: Description -->
                    <ng-container cdkColumnDef="description">
                        <th cdk-header-cell *cdkHeaderCellDef >Description</th>
                        <td cdk-cell *matCellDef="let row">
                            <div class="body-line" *ngIf="row.description">{{ row.description }}</div>
                        </td>
                    </ng-container>


                    <!-- Column: Birth Date -->
                    <ng-container cdkColumnDef="birthDate" *ngIf="state.sampleType === 'harvest'">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="birthDate">
                            Harvest Date
                        </th>
                        <td cdk-cell *matCellDef="let row">
                            <time class="body-line">
                                {{ row.birthDate | amDateFormat:'ll' }}
                            </time>
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="birthDate" *ngIf="state.sampleType === 'plant'">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="birthDate">
                            Planted Date
                        </th>
                        <td cdk-cell *matCellDef="let row">
                            <time class="body-line">
                                {{ row.birthDate | amDateFormat:'ll' }}
                            </time>
                        </td>
                    </ng-container>

                    <!-- Column: Cultivars -->
                    <ng-container cdkColumnDef="cultivars">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="scionCultivarName">
                            Scion &amp; Rootstock
                        </th>
                        <td cdk-cell *matCellDef="let row">
                            <span [pvCultivarLine]="row.scionCultivar"
                                  graft="scion"
                                  (click)="$event.stopPropagation()"
                                  [matMenuTriggerFor]="scionMenu"
                                  [matMenuTriggerData]="{cultivar: row.scionCultivar}">
                            </span>
                            <span *ngIf="row.rootstockCultivar as rootstock"
                                  [pvCultivarLine]="rootstock"
                                  graft="rootstock"
                                  (click)="$event.stopPropagation()"
                                  [matMenuTriggerFor]="rootstockMenu"
                                  [matMenuTriggerData]="{cultivar: row.rootstockCultivar}">
                            </span>
                        </td>
                    </ng-container>

                    <!-- Column: Connected Plant Sample plated date -->
                    <ng-container cdkColumnDef="plantSampleDate" *ngIf="state.sampleType == 'harvest'">
                        <th cdk-header-cell *cdkHeaderCellDef>
                            Plant Sample Planted Date
                        </th>
                        <td cdk-cell *matCellDef="let row">
                            <div *ngIf="row.type === 'harvest' && row.plantSample as plantSample; else noSample">
                                <span [pvSampleLine]="plantSample"></span>
                            </div>
                            <ng-template #noSample>N/A</ng-template>
                        </td>
                    </ng-container>

                    <!-- Column: Evaluation -->
                    <ng-container cdkColumnDef="evaluation">
                        <th cdk-header-cell *cdkHeaderCellDef>
                            Latest Uncompleted Evaluation
                        </th>
                        <td cdk-cell *matCellDef="let row">
                            <div class="latest-evaluation-line-wrapper">
                                <mat-icon>assignment</mat-icon>&nbsp;
                                <div *ngIf="row.evals | descLatestEval as evaluation" class="latest-evaluation-wrapper">
                                    <span>{{evaluation.label}}</span>
                                    <span class="latest-evaluation-date">{{evaluation.evalStartDate | amDateFormat:'ll' }}</span>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Column: Location -->
                    <ng-container cdkColumnDef="location">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="siteName">
                            Site
                        </th>
                        <td cdk-cell *matCellDef="let row">
                            <span class="row-pos-line" *ngIf="row.type === 'plant'">
                                {{ row | rowPosition }}
                            </span>
                            <span *ngIf="row.site as site"
                                  [pvSiteLine]="site"
                                  (click)="$event.stopPropagation()"
                                  [matMenuTriggerFor]="siteMenu"
                                  [matMenuTriggerData]="{site: site}">
                            </span>
                            <span>{{ row | descSpacing }}</span>
                        </td>
                    </ng-container>

                    <!-- Column: Size -->
                    <ng-container cdkColumnDef="size">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="size">Size</th>
                        <td cdk-cell *matCellDef="let row">
                            <div class="body-line" *ngIf="row.size">{{ row.size }}</div>
                        </td>
                    </ng-container>

                    <!-- Column: Timestamps -->
                    <ng-container cdkColumnDef="timestamps">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="createdAt">Timestamps
                        </th>
                        <td cdk-cell *matCellDef="let row">
                            <span class="caption-line">Added
                                {{ row.createdAt | amDateFormat:'ll' }}</span>
                            <span class="caption-line">Updated
                                {{ row.updatedAt | amTimeAgo }}</span>
                        </td>
                    </ng-container>

                    <!-- Row Defs -->
                    <tr cdk-header-row
                        *cdkHeaderRowDef="state.displayColumns">
                    </tr>
                    <tr cdk-row
                        *cdkRowDef="let row; columns: state.displayColumns;"
                        [class.selected]="selection.isSelected(row.key)">
                    </tr>

                </table>
            </div>
            <!-- TABLE END -->


            <!-- Sample Menu -->
            <mat-menu #sampleMenu="matMenu">
                <ng-template matMenuContent let-sample="sample">
                    <button mat-menu-item [routerLink]="[sample.key]">
                        <mat-icon>pageview</mat-icon>
                        <span>View Sample Detail</span>
                    </button>
                    <button mat-menu-item (click)="edit(sample)">
                        <mat-icon>edit</mat-icon>
                        <span>Edit Sample</span>
                    </button>
                    <button mat-menu-item (click)="edit(sample, true)">
                        <mat-icon>content_copy</mat-icon>
                        <span>Clone Sample</span>
                    </button>
                    <button mat-menu-item (click)="setPrimaryImage(sample)">
                        <mat-icon>star</mat-icon>
                        <span>Set Primary Image</span>
                    </button>
                    <button mat-menu-item (click)="delete(sample)">
                        <mat-icon>delete</mat-icon>
                        <span>Delete Sample</span>
                    </button>
                </ng-template>
            </mat-menu>

            <!-- Harvests Menu -->
            <mat-menu #harvestsMenu="matMenu">
                <ng-template matMenuContent let-sample="sample">
                    <button mat-menu-item (click)="extract(sample)">
                        <mat-icon>add</mat-icon>
                        <span>Extract Harvest</span>
                    </button>

                    <a mat-menu-item
                        *ngFor="let harvest of sample.harvestSamples"
                        [routerLink]="[harvest.key]">
                        <span [pvSampleLine]="harvest"></span>
                    </a>

                </ng-template>
            </mat-menu>

            <!-- Scion Cultivar Menu -->
            <mat-menu #scionMenu="matMenu">
                <ng-template matMenuContent let-cultivar="cultivar">
                    <button mat-menu-item (click)="addModelQuery('scion', cultivar)">
                        <mat-icon>playlist_add</mat-icon>
                        <span>Filter Scion</span>
                    </button>
                    <button mat-menu-item (click)="editCultivar(cultivar)">
                        <mat-icon>edit</mat-icon>
                        <span>Edit Cultivar Details</span>
                    </button>
                </ng-template>
            </mat-menu>

            <!-- Rootstock Cultivar Menu -->
            <mat-menu #rootstockMenu="matMenu">
                <ng-template matMenuContent let-cultivar="cultivar">
                    <button mat-menu-item (click)="addModelQuery('rootstock', cultivar)">
                        <mat-icon>playlist_add</mat-icon>
                        <span>Filter Rootstock</span>
                    </button>
                    <button mat-menu-item (click)="editCultivar(cultivar)">
                        <mat-icon>edit</mat-icon>
                        <span>Edit Cultivar Details</span>
                    </button>
                </ng-template>
            </mat-menu>

            <!-- Site Menu -->
            <mat-menu #siteMenu="matMenu">
                <ng-template matMenuContent let-site="site">
                    <button mat-menu-item (click)="addModelQuery('site', site)">
                        <mat-icon>playlist_add</mat-icon>
                        <span>Filter Location</span>
                    </button>
                    <button mat-menu-item (click)="editSite(site)">
                        <mat-icon>edit</mat-icon>
                        <span>Edit Site Details</span>
                    </button>
                </ng-template>
            </mat-menu>


        </ng-container>


    </div>

</pv-view>
