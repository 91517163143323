import { Protocol } from './_types';

export const LIB_PROTOCOL_AVOCADO_TREE_SCREEN_EVAL: Protocol = {
    id: "avocado_tree_screen_eval",
    title: "Advanced Avocado Tree Evaluation",
    description: "New Avocado Protocol",
    sampleType: "plant",
    crop: "avocado",

    chars: [
        "tree_race_avo",

        "date_ispr_bud_swell_avo",
        "date_espr_bud_swell_avo",
        "date_ispr_bud_break_avo",
        "date_espr_bud_break_avo",
        "date_spr_leaves_full_avo",
        "date_spr_sylleptic_shoot_avo",
        "date_spr_90_shoot_avo",
        "date_irbs_avo",
        "date_erbs_avo",
        "date_rbb_avo",
        "date_10_flower_avo",
        "date_50_flower_avo",
        "date_90_flower_avo",
        "date_ifa_avo",
        "date_50_size_avo",
        "date_90_size_avo",
        "date_isum_bud_swell_avo",
        "date_esum_bud_swell_avo",
        "date_isum_bud_break_avo",
        "date_esum_bud_break_avo",
        "date_sum_leaves_full_avo",
        "date_isum_sylleptic_shoot_avo",
        "date_sum_sylleptic_shoots_avo",
        "date_esum_sylleptic_shoot_avo",

        "tree_branch_habit_avo",
        "tree_branch_pattern_avo",
        "tree_branch_dist_avo",
        "tree_branch_crotch_avo",
        "tree_grow_potent_avo",
        "tree_branch_dense_avo",
        "tree_yng_leaf_colour_avo",
        "tree_mat_leaf_colour_avo",

        "tree_flower_type_avo",
        "tree_flower_dense_avo",
        "tree_flower_distrib_avo",
        "tree_flower_pos_avo",
        "tree_flower_color_avo",
        "tree_flower_absciss_avo",
        "tree_flower_second_avo",

        "tree_bear_precocity_avo",
        "tree_bear_potent_avo",
        "tree_fruit_drop_avo",
        "tree_preharvest_drop_avo",
        "tree_frut_habit_avo",
        "tree_frut_shape_avo",
        "tree_pick_count_avo",
        "tree_bear_alternate_avo",
        "tree_storage_avo",

        "tree_pest_detect_avo",
        "tree_disease_detect_avo",
        "tree_overall_disease_avo",

        "tree_flower_span_avo",
        "tree_rain_suscept_avo",
        "tree_frost_suscept_avo",
        "tree_chll_sens_avo",
        "tree_sunburn_symptom_avo",
        "tree_overall_adapt_avo",

    ],

    measures: [
        "tree_trunk_circum",
        "tree_trunk_diameter",
        "tree_height",
        "tree_depth",
        "tree_width",
        "tree_shoot_length",
        "tree_fruit_count",
        "tree_total_yield",
        "prod_average",
    ],

    indexes: [],

    calculations: [
        "prod_average",
        "tree_yield_eff",
        "tons_per_ha_calc",
        "tree_circumference_calc",
    ]
}