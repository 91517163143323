import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { LIBRARY } from '@app/evaluation/library';
import { Dialog, Snackbar, ViewComponent } from "@core/material";
import { CharacteristicCategoryGroup, Crop, Index, Library, MeasurementSubjectGroup, Protocol, WeatherReport } from '@library';
import { Cultivar, Evaluation, ReportImageOption, Report, ReportLetterhead, ReportRemark, ReportTable, ReportWeather, Sample, WeatherReportInterval, WeatherSensorSummary, WeatherStation, ReportImage, ReportOptions, InfoSheetReportOptions, ReportSample, ReportTableTemplate, TableReportOptions, SampleType } from '@core/data';
import { Select, Store } from "@ngxs/store";
import { combineLatest, Observable, Subject, Subscription } from "rxjs";
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { REPORT_THEMES } from '../../themes.service';
import { ReportTemplatesDialog, ReportTemplatesDialogData } from '../report-templates/report-templates.dialog';
import { MeasureSummaryOptionsFormDialog, MeasureSummaryOptionsFormDialogData } from './measure-summary-options-form.dialog';
import { InitReportBuilder, ReloadReportBuilder, ReloadReportBuilderLiveData, ReloadReportBuilderWeatherImport, ReportAddCultivarSamples, ReportAddSample, ReportAddTable, ReportRemoveTable, ReportAddWeather, ReportRemoveWeather, ReportBuilderLiveDataStateModel, ReportBuilderSampleImportStateModel, ReportBuilderState, ReportBuilderStateModel, ReportBuilderWeatherImportStateModel, SetReportBuilderSampleSearch, SubmitReportBuilder, UpdateReportFormData, SetReportBuilderSiteFilter, QueryReportBuilderSamples, ReportAddManySamples, ReloadReportBuilderRemarks, ReloadReportBuilderLetterheadOptions, ReportUpdateTables, SetReportBuilderInfoSheetImages, SetNewReportBuilderImageOptions, ReportUpdateSamples, ReportBuilderTableTemplateImportStateModel, LoadReportTableTemplates, CreateReportTableTemplate, ReportBuilderSampleQuery, DEFAULTS, ResetReportSampleQuery, ReportUpdateRemarks } from './report-builder.state';
import { mergeDefaultReportOptions, REPORT_OPTIONS_DEFAULT, REPORT_SECTIONS_DEFAULT } from './report-defaults';
import { ReportRemarkFormDialog } from './report-remark-form.dialog';
import { ReportWeatherFormDialog, ReportWeatherFormDialogData } from './report-weather-form.dialog';
import { ReportTableFormDialog, ReportTableFormDialogData } from './report-table-form.dialog';
import { ReportSampleImageSelectDialogData, ReportSampleImageSelectDialog } from '../report-builder/report-sample-image-select.dialog';
import { ReportTableEvaluationFormDialog, ReportTableEvaluationFormDialogData } from "./report-table-evaluation-form.dialog";
import { FileAttachmentDialog, FileAttachmentDialogData } from "../file-attachment-form/file-attachment-form.dialog";
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { LetterheadUploadDialog, LetterheadUploadFormData } from "../letterhead-upload-form/letterhead-upload-form.dialog";
import { ImageAttachmentDialog, ImageAttachmentDialogData } from "../image-attachment-form/image-attachment-form.dialog";
import { coerseDateProperty, fileExtension, NumberValidators } from "@core/utils";
import { SharedService } from "@app/evaluation/timeline.service";
import { CompiledCharacteristicSummaryReport, CompiledInfoSheetReport } from "@app/evaluation/report-compiler.types";
import { ReportTableTemplatesDialgData, ReportTableTemplatesDialog } from "../report-table-templates/report-table-templates-form.dialog";
import { ReportSetupFormDialog, ReportSetupFormDialogData } from "./report-setup-form.dialog";

@Component({
    selector: 'pv-report-builder-view',
    templateUrl: 'report-builder.view.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [],
    host: {
        class: 'pv-report-builder-view'
    }
})


export class ReportBuilderView implements OnInit {

    @ViewChild(ViewComponent, {static: false})
    viewComponent: ViewComponent;

    @Select(ReportBuilderState)
    state$: Observable<ReportBuilderStateModel>;

    @Select(ReportBuilderState.data)
    data$: Observable<Report>;

    @Select(ReportBuilderState.liveData)
    liveDataState$: Observable<ReportBuilderLiveDataStateModel>;

    @Select(ReportBuilderState.sampleImport)
    sampleImportState$: Observable<ReportBuilderSampleImportStateModel>;

    @Select(ReportBuilderState.weatherImport)
    weatherImportState$: Observable<ReportBuilderWeatherImportStateModel>;

    @Select(ReportBuilderState.tableTemplates)
    tableTemplateState$: Observable<ReportBuilderTableTemplateImportStateModel>;

    @Select(ReportBuilderState.query)
    query$: Observable<ReportBuilderSampleQuery>;

    // chars
    disableIndvChars = true;
    includeCharsControl = new FormControl(['*']);
    charSummaryOptionsGroup = new FormGroup({
        includeChars: this.includeCharsControl,
        layoutDense: new FormControl(true),
    });

    // measures
    measureOverridesControl = new FormControl([]);
    disableIndvMeasures = true;
    includeMeasuresControl = new FormControl(['*']);
    measureSummaryOptionsGroup = new FormGroup({
        includeMeasures: this.includeMeasuresControl,
        showCharts: new FormControl(true),
        showData: new FormControl(true),
        showStats: new FormControl(true),
        showAverage: new FormControl(false),
        overrides: this.measureOverridesControl
    });

    // indexes
    disableIndvIndexes = true;
    includeIndexesControl = new FormControl(['*'])
    charIndexOptionsGroup = new FormGroup({
        includeIndexes: this.includeIndexesControl,
        showCharts: new FormControl(true),
        showData: new FormControl(true),
    });

    // notes
    noteOptionsGroup = new FormGroup({});

    // images
    imageOptionsGroup = new FormGroup({
        size: new FormControl('m'),
        showNotes: new FormControl(true),
        showDates: new FormControl(true),
        showSite: new FormControl(true),
        showPlantedYear: new FormControl(true),
        overlay: new FormControl(false),
    });

    // info sheet
    infoSheetOverridesControl = new FormControl([]);
    showCultivarSelectionControl = new FormControl(false);
    primaryCultivarControl = new FormControl(null);
    controlCultivarControl = new FormControl(null);
    includeInfoSheetMeasuresControl = new FormControl(['*']);
    includeInfoSheetCharsControl = new FormControl(['*']);
    disableInfoSheetMeasures = false;
    disableInfoSheetChars = false;
    cultivarInfoOptionsGroup = new FormGroup({
        showSampleOrigin: new FormControl(),
        showCultivarSelection: this.showCultivarSelectionControl,
        primaryCultivar: this.primaryCultivarControl,
        controlCultivar: this.controlCultivarControl,
        includeChars: this.includeInfoSheetCharsControl,
        includeMeasures: this.includeInfoSheetMeasuresControl,
        showCharts: new FormControl(true),
        showData: new FormControl(true),
        showStats: new FormControl(true),
        showAverage: new FormControl(false),
        overrides: this.infoSheetOverridesControl,
        includeIndexes: new FormControl([]),
        includeImages: new FormArray([new FormControl([]), new FormControl([])]),
        infoSheetImageOptions: new FormArray([new FormControl([]), new FormControl([])]),
        imageSize: new FormControl("m"),
    });

    //tables
    showTablesControl = new FormControl(true);

    // all options
    letterheadRefControl = new FormControl(null);
    letterheadControl = new FormControl(null);
    letterheadKeyControl = new FormControl(null);
    themeIdControl = new FormControl(null);
    showEmptyFieldsControl = new FormControl(false);
    toggleDescriptionControl = new FormControl(false);

    showLegendControl = new FormControl(true);
    showStorageRegimeControl = new FormControl(true);
    legendOptionsGroup = new FormGroup({
        showLegend: this.showLegendControl,
        showStorageRegime: this.showStorageRegimeControl,
    })

    showTimelineControl = new FormControl(true);
    showWeatherControl = new FormControl(true);

    showCultivarInfoControl = new FormControl(true);
    showCharSummaryControl = new FormControl(true);
    showMeasureSummaryControl = new FormControl(true);
    showCharIndexControl = new FormControl(true);
    showImagesControl = new FormControl(true);
    showNotesControl = new FormControl(true);

    letterheadOptionsGroup = new FormGroup({
        letterhead: this.letterheadControl,
        letterheadKey: this.letterheadKeyControl,
    });

    imageSectionOptionsGroup = new FormGroup({
        images: new FormControl([]),
    });

    sectionsOrderControl = new FormControl(null);

    optionsGroup = new FormGroup({
        letterheadRef: this.letterheadRefControl,
        letterhead: this.letterheadControl,
        letterheadKey: this.letterheadKeyControl,
        letterheadOptions: this.letterheadOptionsGroup,
        themeId: this.themeIdControl,
        showEmptyFields: this.showEmptyFieldsControl,
        toggleDescription: this.toggleDescriptionControl,
        showLegend: this.showLegendControl,
        showStorageRegime: this.showStorageRegimeControl,
        legendOptions: this.legendOptionsGroup,
        showTimeline: this.showTimelineControl,
        showWeather: this.showWeatherControl,
        showTables: this.showTablesControl,
        showCultivarInfo: this.showCultivarInfoControl,
        cultivarInfoOptions: this.cultivarInfoOptionsGroup,
        showCharSummary: this.showCharSummaryControl,
        charSummaryOptions: this.charSummaryOptionsGroup,
        showMeasureSummary: this.showMeasureSummaryControl,
        measureSummaryOptions: this.measureSummaryOptionsGroup,
        showCharIndex: this.showCharIndexControl,
        charIndexOptions: this.charIndexOptionsGroup,
        showImages: this.showImagesControl,
        imageOptions: this.imageOptionsGroup,
        showNotes: this.showNotesControl,
        noteOptions: this.noteOptionsGroup,
        imageSectionOptions: this.imageSectionOptionsGroup,
        sectionsOrder: this.sectionsOrderControl,
    });

    remarksControl = new FormControl();
    samplesControl = new FormControl([]);
    weatherControl = new FormControl([]);
    tableControl = new FormControl([]);

    formGroup = new FormGroup({
        title: new FormControl('', [Validators.required, Validators.maxLength(64)]),
        description: new FormControl(null, [Validators.maxLength(255)]),
        ownerOrgKey: new FormControl(null, [Validators.required]),
        options: this.optionsGroup,
        samples: this.samplesControl,
        remarks: this.remarksControl,
        weather: this.weatherControl,
        tables: this.tableControl,
    });

    //query options
    sampleSearchControl = new FormControl(null);
    sampleSiteControl = new FormControl([]);

    // query form group
    scionCultivarsControl = new FormControl([]);
    rootstockCultivarsControl = new FormControl([]);
    birthWeekControl = new FormControl();
    birthYearControl = new FormControl();
    scionCropIdControl = new FormControl();
    scionLicenseeControl = new FormControl();
    rowIndexControl = new FormControl(null, [NumberValidators.rangeOrSet()]);
    positionIndexControl = new FormControl(null, [NumberValidators.rangeOrSet()]);
    groupKeyControl = new FormControl([]);
    destroyedControl = new FormControl();
    excludeYearControl = new FormControl();
    startEvalPeriodControl = new FormControl(null);
    endEvalPeriodControl = new FormControl(null);

    queryFormGroup = new FormGroup({
        search: this.sampleSearchControl,
        scionCultivars: this.scionCultivarsControl,
        rootstockCultivars: this.rootstockCultivarsControl,
        sites: this.sampleSiteControl,
        birthYear: this.birthYearControl,
        birthWeek: this.birthWeekControl,
        scionCropId: this.scionCropIdControl,
        rowIndex: this.rowIndexControl,
        positionIndex: this.positionIndexControl,
        hideDestroyed: this.destroyedControl,
        excludeYear: this.excludeYearControl,
        startEvalPeriod: this.startEvalPeriodControl,
        endEvalPeriod: this.endEvalPeriodControl,
        plantSample: new FormControl(null),
        harvestSample: new FormControl(null),
    });

    sampleFilters: number = 0;

    yearOptions: number[] = [];
    weekOptions: number[] = [];
    cropOptions: Crop[];

    weatherStationControl = new FormControl(null);

    // selection options
    characteristicGroups: CharacteristicCategoryGroup[];
    measurementGroups: MeasurementSubjectGroup[];
    indexOptions: Index[];
    weatherReportOptions: WeatherReport[];
    themeOptions = REPORT_THEMES;

    // letterhead
    letterheadUploadControl = new FormControl();
    letterheadOptions: ReportLetterhead[] = [];

    // Re-orderable report sections (default)
    reportSections = REPORT_SECTIONS_DEFAULT;
    lockSections = false;

    reportTables: ReportTable[] = []
    reportTableTemplates: ReportTableTemplate[];

    infoSheetOptions: ReportImageOption[][];
    imageSectionOptions: ReportImageOption[][];
    infoSheetImagesTemp: ReportImageOption[][] = null;
    reportProtocol: Protocol;

    print = false;
    editable = true;
    viewMode: 'preview' | 'samples' | 'weather' | 'tabular' = 'preview';
    liveDataErrorDialogRef: any;

    undoTemplates: Partial<Report>[] = [];
    redoTemplates: Partial<Report>[] = [];

    reportKey: string;

    private _isResetting = false;
    private _lastEmit:any = null;
    private _destroy$ = new Subject();

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _store: Store,
        private _router: Router,
        private _snackbar: Snackbar,
        private _dialogs: Dialog,
        private _changeRef: ChangeDetectorRef,
        private router: Router,
        @Inject(LIBRARY) private _library: Library,
        private sharedService: SharedService,
    ) {}

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.reportSections, event.previousIndex, event.currentIndex);
        this.updateSectionsFromForm();
        this.updateIncludeIndv();
        this.sectionsOrderControl.patchValue(this.generateSectionOrderString());
        this.formGroup.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }

    dropCustomTables(event: CdkDragDrop<ReportTable[]>) {
        moveItemInArray(this.reportTables, event.previousIndex, event.currentIndex);
        this.updateTableIndex()
        this._store.dispatch(new ReportUpdateTables(this.reportTables));
    }

    ngOnInit() {
        this.characteristicGroups = this._library.characteristicsGroupedByCategory();
        this.measurementGroups = this._library.measurementsGroupedBySubject();
        this.indexOptions = this._library.indexes.all();
        this.weatherReportOptions = this._library.weatherReports.all();

        this.updateIncludeIndv();

        // initialize state from route paramater changes
        combineLatest(this._activatedRoute.paramMap, this._activatedRoute.queryParamMap)
            .pipe(takeUntil(this._destroy$))
            .subscribe(changes => {
                let [params, queryParams] = changes;
                this._store.dispatch(new InitReportBuilder(params.get('reportKey')));
                this.reportKey = params.get('reportKey');
            });

        // bind formGroup to state data
        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if(data && data !== this._lastEmit){
                    this.reset(data);
                }
            });

        this.liveDataState$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {
            });

        this.tableTemplateState$.pipe(takeUntil(this._destroy$))
            .subscribe(templates => {
                this.reportTableTemplates = [...templates.templates]
            });

        // apply state changes to query form
        this.query$.pipe(takeUntil(this._destroy$))
            .subscribe(query => this.queryFormGroup.patchValue(query, { emitEvent: false }));

        // rebuild report from form group
        this.formGroup.valueChanges
            .pipe(
                takeUntil(this._destroy$),
                tap(form => {this.updateIncludeIndv()
                            this.updateSectionsFromForm()
                            }),
                filter(data => !this._isResetting),
                debounceTime(100),
            )
            .subscribe(report => {
                this._lastEmit = report;

                this._store.dispatch(new UpdateReportFormData(report));
            });

        // handle query changes (with primary and control cultivars)
        this.queryFormGroup.valueChanges
            .pipe(debounceTime(300), takeUntil(this._destroy$))
            .subscribe(val => {
                this.queryReportSamples(val);
        });

        // handle primaryCultivar changes (with other query elements)
        this.primaryCultivarControl.valueChanges
            .pipe(
                takeUntil(this._destroy$),
                debounceTime(400)
            )
            .subscribe(cultivar => {
                this.queryReportSamples();
            });

        // handle controlCultivar changes (with other query elements)
        this.controlCultivarControl.valueChanges
            .pipe(
                takeUntil(this._destroy$),
                debounceTime(400)
            )
            .subscribe(cultivar => {
                this.queryReportSamples();
            });

        this.showCultivarSelectionControl.valueChanges
            .pipe(
                takeUntil(this._destroy$),
                debounceTime(400)
            )
            .subscribe(showSelection => {
                this.queryReportSamples();
            });

        this.state$
            .pipe(
                takeUntil(this._destroy$)
            )
            .subscribe(state => {
                this.letterheadOptions = state.letterheadOptions;
            });

        this.cropOptions = this._library.filterExcludedCrops()

        // make year options [current year - 20] to [current year + 1]
        let cYear = new Date().getFullYear();
        for (let i = (cYear - 20); i <= (cYear + 1); i++) {
            this.yearOptions.push(i);
        }
        this.yearOptions.reverse();

        // make week options 1-53
        for (let i = 1; i <= 53; i++) {
            this.weekOptions.push(i);
        }

        //Update state just after init
        this._store.dispatch(new UpdateReportFormData(this.formGroup.getRawValue()));
    }

    queryReportSamples(val?: ReportBuilderSampleQuery) {
        const ignoredKeys = ['search', 'primaryCultivar', 'controlCultivar', 'type', 'hideDestroyed', 'showCultivarSelection'];

        if (!val) val = {...this.queryFormGroup.value};

        val.primaryCultivar = this.primaryCultivarControl.value;
        val.controlCultivar = this.controlCultivarControl.value;

        if (!this.showCultivarSelectionControl.value) {
            val.primaryCultivar = null;
            val.controlCultivar = null;
        }

        val.startEvalPeriod = val.startEvalPeriod ? coerseDateProperty(val.startEvalPeriod) : null,
        val.endEvalPeriod = val.endEvalPeriod ? coerseDateProperty(val.endEvalPeriod) : null,

        val.type = [];

        if (val.plantSample) val.type.push(SampleType.PLANT);
        if (val.harvestSample) val.type.push(SampleType.HARVEST);

        this.sampleFilters = 0;

        let keys = Object.keys(val).filter(key => !ignoredKeys.includes(key));

        keys.forEach(key => {
            if (!val[key]) return;
            if (Array.isArray(val[key]) && val[key].length == 0) return;
            this.sampleFilters += 1;
        });

        this._store.dispatch(new QueryReportBuilderSamples(val));
    }

    setReportProtocol(protocol: Protocol) {
        this.reportProtocol = protocol;
    }

    setAllInfoCharacteristics() {
        if (!this.reportProtocol) return;
        this.includeInfoSheetCharsControl.patchValue([...this.reportProtocol.chars]);
    }

    setAllCharacteristics() {
        if (!this.reportProtocol) return;
        this.includeCharsControl.patchValue([...this.reportProtocol.chars]);
    }

    clearCharacteristics() {
        this.includeCharsControl.setValue([]);
    }

    clearInfoCharacteristics() {
        this.includeInfoSheetCharsControl.setValue([]);
    }

    clearFilters() {
        this.sampleFilters = 0;
        this._store.dispatch(new ResetReportSampleQuery(this.showCultivarSelectionControl.value));
    }

    syncTimeLines(){
        this.sharedService.triggerTimelineSyncEvent();
    }

    updateSectionsFromForm() {
        this.reportSections.forEach((section, index) => {
           section.enabled = this.formGroup.controls.options.get(section.formControlName).value;
           section.index = index;
        })
    }

    updateTableIndex() {
        let tables = JSON.parse(JSON.stringify(this.reportTables));

        tables.forEach((table, index) => {
            table.index = index;
        })

        this.reportTables = tables;
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    /**
     * Return to report index
     */
    back() {

        this._router.navigate([`/org/${this.formGroup.value.ownerOrgKey}/reports`]);
    }

    reload(confirm = true){

        if(confirm){
            this._dialogs.confirm(
                "Reload Report",
                `
                Are you sure you want to reload the current report
                and revert all changes since last save?
                `
            ).afterClosed().subscribe(c => {
                if(c) this._store.dispatch(new ReloadReportBuilder());
            });
        }else{
            this._store.dispatch(new ReloadReportBuilder());
        }
    }


    reloadLiveData(){
        this._store.dispatch(new ReloadReportBuilderLiveData());
    }


    reloadWeatherImport(){
        this._store.dispatch(new ReloadReportBuilderWeatherImport());
    }

    reloadTableTemplates() {
        this._store.dispatch(new LoadReportTableTemplates(this.formGroup.value.ownerOrgKey));
    }

    reset(data: Report){
        this._isResetting = true;

        let infoSheetOptions: ReportImageOption[][] = this.canCompileReportOptions(data) ? this.compileReportOptions(data) : [[], []];

        //? RESETS UNSAVED IMAGE ORDERS FROM MEMORY
        if (this.infoSheetImagesTemp && infoSheetOptions !== this.infoSheetImagesTemp) {
            infoSheetOptions = [...this.infoSheetImagesTemp];
            this._store.dispatch(new SetNewReportBuilderImageOptions(infoSheetOptions));
            this.infoSheetImagesTemp = null;
        }

        const options: ReportOptions = {
            ...data.options,
            cultivarInfoOptions: {
                ...data.options.cultivarInfoOptions,
                infoSheetImageOptions: infoSheetOptions,
            }
        }

        const form: Partial<Report> = {
            title: data.title || null,
            description: data.description || null,
            ownerOrgKey: data.ownerOrgKey,
            sections: REPORT_SECTIONS_DEFAULT,
            options: mergeDefaultReportOptions(options),
            samples: Array.isArray(data.samples) ? data.samples : [],
            remarks: Array.isArray(data.remarks) ? data.remarks : [],
            weather: Array.isArray(data.weather) ? data.weather : [],
            tables: Array.isArray(data.tables) ? data.tables : [],
        };

        this.formGroup.reset(form, {emitEvent: false});
        this.formGroup.updateValueAndValidity();

        this.reportSections = this.updateSectionsOrder(form.options.sectionsOrder);

        this.formGroup.get('options').get('legendOptions').setValue({
            showLegend: data.options.showLegend,
            showStorageRegime: data.options.showLegend,
        });

        this.reportTables = [...data.tables];
        this.updateReportTableOrder();

        this.formGroup.get('options').get('letterheadOptions').setValue({
            letterhead: data.options.letterhead,
            letterheadKey: data.options.letterheadKey,
        });

        this._isResetting = false;

        this.reloadTableTemplates();
    }

    attempt(auto: boolean = false, bypassSectionOrder: boolean = false){
        if (this.infoSheetOptions) this.setInfoSheetOptionOrder()
        if (this.imageSectionOptions) this.setImageSectionImageOrder()

        this.formGroup.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        let form = this.formGroup.getRawValue();
        //Ensure 'legendOptions' are removed from formGroup before posting
        delete form.options['legendOptions'];

        this.save(form, auto, bypassSectionOrder);
    }

    generateSectionOrderString(): string {
        let orderString = '';
        this.reportSections.forEach(section => {
            orderString += section.title + ';';
        });

        //Remove spaces, twice
        orderString = orderString.replace(' ', '');
        orderString = orderString.replace(' ', '');

        return orderString;
    }


    setReportSectionsOrder(report: Partial<Report>, bypassSectionOrder: boolean = false): Report {
        let combo = !bypassSectionOrder ? this.generateSectionOrderString() : report.options.sectionsOrder;

        let copyData = JSON.parse(JSON.stringify(report));
        copyData.sections = this.reportSections;
        copyData.options.sectionsOrder = combo;

        return copyData;
    }


    updateSectionsOrder(sections: string) {
        let currSect;
        let reportSections = [];

        if(sections) {
            sections.split(';').forEach((sect, index) => {
                if(sect == 'InfoSheet') sect = 'Info Sheet';

                if(sect != '') {
                    currSect = this.reportSections.find(section => section.title == sect);
                    currSect.index = index;

                    reportSections[index] = currSect;
                }
            });
        } else return this.reportSections;

        return reportSections;
    }

    updateReportTableOrder(){
        if (!this.reportTables.some(table => table.index === this.reportTables.length - 1)) this.updateTableIndex();

        let tables = JSON.parse(JSON.stringify(this.reportTables));

        tables.sort(function(a, b) { return a.index - b.index });

        this.reportTables = tables;
    }

    updateInfoSheetImageOptions(evaluations: Evaluation[], validImages: string[]): ReportImageOption[] {
        let imageOption: ReportImageOption[] = []
        let dataCount = 0;
        for (let evaluation of evaluations) {
            let images = evaluation.images.filter((image) => validImages.includes(image.key))

            if (!images.length) continue;

            for (let image of images) {
                let data: ReportImageOption = {
                    key: image.key,
                    fileRef: image.fileRef,
                    name: image.name,
                    extension: fileExtension(image.name),
                    position: dataCount,
                }

                imageOption.push(data);
                dataCount++;
            }
        }

        return imageOption;
    }

    checkCultivarOptions(data: Report): InfoSheetReportOptions {
        if (data.options.cultivarInfoOptions === null) return null;

        let infoSheetOptions: ReportImageOption[][] = this.compileReportOptions(data);

        const cultivarInfoOptions: InfoSheetReportOptions = {
            ...data.options.cultivarInfoOptions,
            infoSheetImageOptions: infoSheetOptions
        }

        return cultivarInfoOptions;
    }

    compileReportOptions(data: Report): ReportImageOption[][] {
        let infoSheetOptions: ReportImageOption[][] = [[], []]
        let isNewList = false;

        let evaluations: Evaluation[] = []
        data.samples.map((sample) => sample.data.evals).map((evaluation) => evaluation.forEach((val) => evaluations.push(val)));

        for (let i = 0; i < 2; i++) {
            let infoOption: ReportImageOption[] =
                data.options.cultivarInfoOptions.infoSheetImageOptions
                ? data.options.cultivarInfoOptions.infoSheetImageOptions[i]
                : []

            if (!data.options.cultivarInfoOptions.hasOwnProperty('infoSheetImageOptions')) {
                if ((data.options.cultivarInfoOptions.includeImages[i].length) && evaluations.length){
                    infoOption = this.updateInfoSheetImageOptions(evaluations, data.options.cultivarInfoOptions.includeImages[i]);
                }
                isNewList = true
            }

            infoSheetOptions[i] = infoOption;
        }

        if (isNewList) this._store.dispatch(new SetNewReportBuilderImageOptions(infoSheetOptions));

        return infoSheetOptions;
    }

    save(report: Partial<Report>, auto: boolean = false, bypassSectionOrder: boolean = false) {
        report = this.setReportSectionsOrder(report, bypassSectionOrder);

        this._store.dispatch(new SubmitReportBuilder(report))
            .pipe(takeUntil(this._destroy$))
            .subscribe(result => {
                if(!auto) this._snackbar.message('Report Saved');

                let state: ReportBuilderStateModel = this._store.selectSnapshot(ReportBuilderState);

                this._activatedRoute.params
                    .pipe(takeUntil(this._destroy$))
                    .subscribe(params => {
                        if(!params.reportKey) this._router.navigate(['..', state.key, 'edit'], { relativeTo: this._activatedRoute });
                    });
            });
    }

    createTemplate(){

        let form: Partial<Report> = this.formGroup.value;

        let reportOptionsWithoutCultivar: ReportOptions = JSON.parse(JSON.stringify(form.options));
        reportOptionsWithoutCultivar.cultivarInfoOptions.primaryCultivar = null;
        reportOptionsWithoutCultivar.cultivarInfoOptions.controlCultivar = null;
        reportOptionsWithoutCultivar.cultivarInfoOptions.infoSheetImageOptions = [[], []];
        reportOptionsWithoutCultivar.cultivarInfoOptions.includeImages = [[], []];
        reportOptionsWithoutCultivar.tables = [];
        reportOptionsWithoutCultivar.sectionsOrder = '';
        reportOptionsWithoutCultivar.imageSectionOptions = REPORT_OPTIONS_DEFAULT.imageSectionOptions;

        if(form.tables.length > 0) {

            form.tables.forEach((table, index) => {

                if (reportOptionsWithoutCultivar.tables[index] === undefined) {

                    reportOptionsWithoutCultivar.tables[index] = new FormGroup({
                        index: new FormControl(null),
                        id: new FormControl(null),
                        title: new FormControl(null),
                        description: new FormControl(null),
                        tableOptions: new FormGroup({
                            isAggregate: new FormControl(false),
                            isSpread: new FormControl(false),
                            showSummary: new FormControl(false),
                            cropId: new FormControl(null),
                            columns: new FormControl([])
                        })
                    }).value

                reportOptionsWithoutCultivar.tables[index].index = table.index;
                reportOptionsWithoutCultivar.tables[index].description = table.description;
                reportOptionsWithoutCultivar.tables[index].id = table.id;
                reportOptionsWithoutCultivar.tables[index].title = table.title;
                reportOptionsWithoutCultivar.tables[index].tableOptions.cropId = table.tableOptions.cropId;
                reportOptionsWithoutCultivar.tables[index].tableOptions.columns = table.tableOptions.columns;
                reportOptionsWithoutCultivar.tables[index].tableOptions.isAggregate = table.tableOptions.isAggregate;
                reportOptionsWithoutCultivar.tables[index].tableOptions.isSpread = table.tableOptions.isSpread;
                reportOptionsWithoutCultivar.tables[index].tableOptions.showSummary = table.tableOptions.showSummary;

                }
            })

        }

        form = this.setReportSectionsOrder(form);
        reportOptionsWithoutCultivar.sectionsOrder = form.options.sectionsOrder;
        delete reportOptionsWithoutCultivar['legendOptions'];

        const data: ReportTemplatesDialogData = {
            ownerOrgKey: form.ownerOrgKey,
            reportOptions: reportOptionsWithoutCultivar,
            reportRemarks: this.cleanRemarks(form.remarks),
        };

        this._dialogs.open(ReportTemplatesDialog, {data});
    }

    applyTemplate() {

        let report: ReportBuilderStateModel = this.getDataCopy(this._store.selectSnapshot(ReportBuilderState));

        const data: ReportSetupFormDialogData = {
            ownerOrgKey: this.formGroup.value.ownerOrgKey,
            reportKey: this.reportKey,
            report: {
                ...report.data
            },
        };

        this._dialogs.open(ReportSetupFormDialog, {data}).afterClosed()
            .subscribe((result: {report: Partial<Report>, replaceRemarks: boolean}) => {
                if (!result) return;

                let report = result.report;

                this.undoTemplates.push({
                    description: data.report.description,
                    options: data.report.options,
                    tables: data.report.tables,
                    remarks: data.report.remarks,
                });

                this.patchTemplateData(report, result.replaceRemarks);
            })
    }

    revertTemplate(redo: boolean = false) {
        let undoSize = this.undoTemplates.length;
        let redoSize = this.redoTemplates.length;
        let report: ReportBuilderStateModel = this.getDataCopy(this._store.selectSnapshot(ReportBuilderState));

        if (redo) {
            if (redoSize === 0) return;
            let redoTemplate = this.redoTemplates[redoSize - 1];

            this.patchTemplateData(redoTemplate);
            this.undoTemplates.push(report.data);
            this.redoTemplates.pop();
            return;
        }

        if (undoSize === 0) return;
        let undoTemplate = this.undoTemplates[undoSize - 1];

        this.patchTemplateData(undoTemplate);
        this.redoTemplates.push(report.data);
        this.undoTemplates.pop();
    }

    patchTemplateData(report: Partial<Report>, replaceRemarks: boolean = true) {
        const form = this.formGroup.value;

        this.formGroup.patchValue({
            ...form,
            title: report.title ? report.title : form.title,
            description: report.description,
            options: report.options,
            tables: report.tables,
            remarks: replaceRemarks ? report.remarks : form.remarks,
        })

        this.attempt(true, true)
    }

    updateReportTableRemark(report: Partial<Report>, template: Partial<Report>) {
        if (template.remarks && template.remarks.length > 0) {
            template.remarks.forEach(remark => {
                if (remark.sectionId.includes('tableReports.customTable.')) {
                    if (Array.isArray(template.tables)) {
                        let idParts = remark.sectionId.split('.');
                        let table = template.tables.find(table => table.id.toString() === idParts[idParts.length - 1]);
                        if (!table) return;
                        let reportTable = report.tables.find(reportTable => table.index === reportTable.index && table.title === reportTable.title);
                        if (!reportTable) return;
                        remark.sectionId = `tableReports.customTable.${reportTable.id}`
                    }
                }
            });
        }

        return template.remarks
    }

    toggleLock() {
       if(this.lockSections) {
            this.lockSections = false;
            return;
       } else if (!this.lockSections) {
            this.lockSections = true;
            return;
       }
    }

    resetSectionsOrder() {
        this.reportSections = REPORT_SECTIONS_DEFAULT;
        this.updateSectionsFromForm();
        this.updateIncludeIndv();
        this.formGroup.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }

    printPreview() {
        this.viewComponent.togglePrintMode(false);
    }


    onPrintModeChange(isPrintMode: boolean){
        this.editable = !isPrintMode;
        this.print = isPrintMode;
    }


    editMeasureSummary(measureId: string, section?: string, report?: CompiledInfoSheetReport) {
        const chartOptions = this._library.charts.all();
        const bucketOptions = this._library.compatibleBuckets(measureId);
        const measure = this._library.measures.get(measureId);
        let overrides = [];
        let formGroupName = '';

        if (section === 'infosheet' && report) {
            let infosheetMeasureSummary = report.measureSummary;
            overrides = infosheetMeasureSummary.options.overrides;
            formGroupName = 'cultivarInfoOptionsGroup';
        } else {
            overrides = this.measureOverridesControl.value;
            formGroupName = 'measureSummaryOptionsGroup';
        }

        overrides = Array.isArray(overrides) ? overrides : [];

        let override = overrides.find(ov => ov.measureId === measureId);
        let exists = true;

        if (!override) {
            override = {
                measureId: measureId,
                bucketId: measure.defaultBucket,
                chartId: measure.defaultChart,
                showChart: true,
                showData: false,
                showStats: true,
                showAverage: false,
            };
            exists = false;
        }

        const data: MeasureSummaryOptionsFormDialogData = {
            override,
            chartOptions,
            bucketOptions,
            formGroupName
        };

        this._dialogs
            .open(MeasureSummaryOptionsFormDialog, {data})
            .afterClosed()
            .subscribe(override => {

                if (!override) return;

                let newOverrides = [];

                if(exists){
                    newOverrides = overrides.map(existing => {
                        if(existing.measureId === override.measureId){
                            return override;
                        }
                        return existing;
                    });
                }else{
                    newOverrides = [...overrides, override];
                }

                if (data.formGroupName === 'cultivarInfoOptionsGroup') {
                    this.cultivarInfoOptionsGroup.get('overrides').setValue(newOverrides);
                    this.cultivarInfoOptionsGroup.markAsDirty();
                } else {
                    this.measureOverridesControl.setValue(newOverrides);
                    this.measureOverridesControl.markAsDirty();
                }

                this.attempt(true);
                this._changeRef.detectChanges();
            });
    }

    editInfoSheetImages(cultivarKey: string, index: number) {
        const data: ReportSampleImageSelectDialogData = { cultivarKey, index };
        this._dialogs.open(ReportSampleImageSelectDialog, { data })
            .afterClosed()
            .subscribe(result => {
                if (!result) return;

                this.attempt(true);
            })
    }

    editRemark(sectionId: string){

        let remark = this.getRemark(sectionId);

        this._dialogs
            .openFullscreen(ReportRemarkFormDialog, {data: remark})
            .afterClosed()
            .subscribe(newRemark => {
                if(newRemark){
                    this.setRemark(newRemark);
                }
            });
    }

    getRemark(sectionId: string){
        let remarks: ReportRemark[] = this.remarksControl.value;

        remarks = Array.isArray(remarks) ? remarks : [];

        let remark = remarks.find(r => r.sectionId === sectionId);

        if(!remark) remark = {sectionId: sectionId, text: null};

        return remark;
    }

    setRemark(remark: ReportRemark){
        let remarks: ReportRemark[] = this.remarksControl.value;
        let replaced = false;
        let newRemarks = remarks.map(existing => {
            if(existing.sectionId === remark.sectionId){
                replaced = true;
                return remark;
            }
            return existing;
        });

        if(!replaced){
            newRemarks.push(remark);
        }
        this.remarksControl.setValue(newRemarks);
        this.remarksControl.markAsDirty();
    }

    editRemarkDocuments(sectionId: string) {
        const state: ReportBuilderStateModel = this._store.selectSnapshot(ReportBuilderState);
        const data: FileAttachmentDialogData = {
            key: state.key,
            sectionId: sectionId
        }

        this.attempt(); // Save report to prevent data loss during document editing
        this._dialogs.openFullscreen(FileAttachmentDialog, {data}).afterClosed().subscribe((data: {refresh: boolean, reportKey: string}) => {
            if (data.refresh && data.reportKey) this._store.dispatch(new ReloadReportBuilderRemarks(data.reportKey));
        });
    }

    editRemarkImages(sectionId: string) {
        const state: ReportBuilderStateModel = this._store.selectSnapshot(ReportBuilderState);

        let remark = state.data.remarks.find(remark => remark.sectionId === sectionId);

        let options = remark ? remark.imageOptions : null;
        let text = remark ? remark.text : null;

        const data: ImageAttachmentDialogData = {
            key: state.key,
            sectionId: sectionId,
            options: options !== null ? remark.imageOptions : [],
        }

        this._dialogs.openFullscreen(ImageAttachmentDialog, {data})
            .afterClosed()
            .subscribe(result => {
                if (!result) return;

                this.setRemark({...result, text: text});
            });
    }

    editInfoSheetImageOrder(infoSheetOptions: ReportImageOption[][]) {
        const updatedImages = this.updateImageOptionPositions(infoSheetOptions);
        this.infoSheetOptions = updatedImages;
        this.infoSheetImagesTemp = updatedImages;
    }

    setInfoSheetOptionOrder() {
        let cultivarOptions = this.formGroup.get('options').get('cultivarInfoOptions')

        this.formGroup.get('options').get('cultivarInfoOptions').setValue({
            ...cultivarOptions.value,
            infoSheetImageOptions: this.infoSheetOptions
        })
    }

    editImageSectionImageOptions(imageSectionImages: ReportImageOption[][], saveOrder: boolean = false) {
        const  updatedImages = this.updateImageOptionPositions(imageSectionImages);
        this.imageSectionOptions = updatedImages;
        if (saveOrder) this.attempt(true);
    }

    setImageSectionImageOrder() {
        this.formGroup.get('options').get('imageSectionOptions').setValue({
            images: this.imageSectionOptions
        });
    }

    addSample(sample: Sample) {
        let state: ReportBuilderStateModel = this._store.selectSnapshot(ReportBuilderState);

        if (!state.data.samples.map(s => s.sampleKey).includes(sample.key)) {
            this._store.dispatch(new ReportAddSample(sample.key));
        } else {
            this._snackbar.message('Duplicate Sample', "error");
        }
    }

    addAllSamples(samples: Sample[]) {
        if(samples.length > 0) {
            this._store.dispatch(new ReportAddManySamples(samples.map(s => s.key)));
        } else {
            this._snackbar.message('No Samples to Add', "error");
        }
    }

    addCultivar(cultivar: Cultivar, type: boolean = true) {
        this._store.dispatch(new ReportAddCultivarSamples(cultivar.key, type));
    }

    toggleSection(title: string) {
      let section = this.reportSections.find(section => section.title == title);
      section.enabled ? section.enabled = false : section.enabled = true;
    }

    addWeatherReport(station: WeatherStation, summary: WeatherSensorSummary){

        const state: ReportBuilderWeatherImportStateModel
             = this._store.selectSnapshot(ReportBuilderState.weatherImport);

        let reportId: string, reportWeather: Partial<ReportWeather>;

        if(summary){

            switch(summary.sensorId){
                case 'tsoi':
                    reportId = 'soil_temp';
                    break;
                case 'hurh':
                    reportId = 'humidity_rh';
                    break;
                default:
                    reportId = 'air_temp';
                    break;
            }

            let report = this._library.weatherReports.get(reportId);

            reportWeather = {
                title: `${station.name} ${report.label}`,
                reportId,
                stationKey: station.key,
                startDate: summary.firstRecordedAt,
                endDate: summary.lastRecordedAt,
                interval: WeatherReportInterval.DAILY,
            };

        }else{
            reportWeather = {
                title: `${station.name}`,
                reportId: null,
                stationKey: station.key,
                startDate: null,
                endDate: null,
                interval: WeatherReportInterval.DAILY,
            };
        }

        const data: ReportWeatherFormDialogData = {
            stations: state.stations,
            data: reportWeather,
        };

        this._dialogs.open(ReportWeatherFormDialog, {data})
            .afterClosed()
            .subscribe(result => {
                if(!result) return;
                this._store.dispatch(new ReportAddWeather(result));
            });

    }

    editWeatherReport(report: ReportWeather){

        const state: ReportBuilderWeatherImportStateModel
             = this._store.selectSnapshot(ReportBuilderState.weatherImport);

        const data: ReportWeatherFormDialogData = {
                stations: state.stations,
                data: {...report},
        };

        this._dialogs.open(ReportWeatherFormDialog, {data})
            .afterClosed()
            .subscribe(result => {
                if(!result) return;
                this._store.dispatch(new ReportAddWeather(result));
            });
    }

    deleteWeatherReport(report: ReportWeather) {
        this._dialogs.confirm(
            'Remove Weather Report',
            'Are you sure you want to remove this temperature data?',
            'Remove',
            'Cancel'
        ).afterClosed().subscribe(res => {
            if (res) this._store.dispatch(new ReportRemoveWeather(report))
                .subscribe(state => {
                    this._snackbar.message('Temperature Data Removed');
                });
        });
    }

    addTableReport(samples: Sample[]) {
        const data: ReportTableFormDialogData = {
            orgKey: this.formGroup.value.ownerOrgKey,
            data: {
                title: "New Table",
                index: this.reportTables.length,
            },
            samples: samples,
            reportTables: this.reportTables,
            reportTableTemplates: this.reportTableTemplates,
        };

        this._dialogs.open(ReportTableFormDialog, {data})
            .afterClosed()
            .subscribe(result => {
                if (!result) return;
                this._store.dispatch(new ReportAddTable(result.data));
                this.updateTableIndex();
                this._store.dispatch(new ReportUpdateTables(this.reportTables));

                if (result.createTemplate) {
                    const template: Partial<ReportTableTemplate> = {
                        name: result.data.title,
                        ownerOrgKey: this.formGroup.value.ownerOrgKey,
                        tableOptions: result.data.tableOptions,
                    }

                    this._store.dispatch(new CreateReportTableTemplate(template));
                }
                this.reloadTableTemplates();
                this.attempt(true);
            });
    }

    editTableReport(report: ReportTable) {
        const data: ReportTableFormDialogData = {
            orgKey: this.formGroup.value.ownerOrgKey,
            data: {...report},
            reportTables: this.reportTables,
            reportTableTemplates: this.reportTableTemplates,
        };

        this._dialogs.open(ReportTableFormDialog, {data})
            .afterClosed()
            .subscribe(result => {
                if (!result) return;
                this._store.dispatch(new ReportAddTable(result.data));

                if (result.createTemplate) {
                    const template: Partial<ReportTableTemplate> = {
                        name: result.data.title,
                        ownerOrgKey: this.formGroup.value.ownerOrgKey,
                        tableOptions: result.data.tableOptions,
                    }

                    this._store.dispatch(new CreateReportTableTemplate(template));
                }

                this.reloadTableTemplates();
                this.attempt(true);
            });
    }

    deleteTableReport(report: ReportTable) {
        let remarks: ReportRemark[] = this.remarksControl.value;
        let tableIdStr = report.id.toString();
        this._dialogs.confirm(
            'Remove Table',
            'Are you sure you want to remove this table?',
            'Remove',
            'Cancel'
        ).afterClosed().subscribe(res => {
            if (res) this._store.dispatch(new ReportRemoveTable(report))
                .subscribe(() => {
                    // Remove remarks where sectionId contains the table's ID
                    let filteredRemarks = remarks.filter(remark =>
                        !remark.sectionId.includes(tableIdStr)
                    );
                    this._store.dispatch(new ReportUpdateRemarks(filteredRemarks));
                    this._snackbar.message('Table Removed');
                    this.updateTableIndex();
                    this._store.dispatch(new ReportUpdateTables(this.reportTables));
                    this.attempt(true);
                });
        });
    }

    openTableTemplateDialog(orgKey: string, table?: ReportTable) {
        let options = table ? table.tableOptions : null;

        const template: Partial<ReportTableTemplate> = {
            name: table ? table.title : null,
            ownerOrgKey: orgKey,
            tableOptions: {
                columns: options ? options.columns : [],
                cropId: options ? options.cropId : null,
                isAggregate: options ? options.isAggregate : null,
                isSpread: options ? options.isSpread : null,
                showSummary: options ? options.showSummary : null,
                includedEvals: [],
            }
        }

        const data: ReportTableTemplatesDialgData = {
            data: template,
            reportTables: this.formGroup.get('tables').value
        }

        this._dialogs.open(ReportTableTemplatesDialog, { data })
            .afterClosed()
            .subscribe(result => {
                if (!result) return;

                this.reloadTableTemplates();
            });
    }

    trackById(index, item){
        return item.id;
    }

    trackByKey(index, item){
        return item.key;
    }

    tabIndexChanged(index: number){
        if(index === 1) this.viewMode = 'samples';
        else if(index === 2) this.viewMode = 'weather';
        else if (index === 3) this.viewMode = 'tabular';
        else this.viewMode = 'preview';
    }

    addLetterhead(reportKey: string, orgKey: string) {

        const data: LetterheadUploadFormData = {
            reportKey: reportKey,
            ownerOrgKey: orgKey,
        }

        this._dialogs.openFullscreen(LetterheadUploadDialog, {data})
            .afterClosed()
            .subscribe(result => {

                if (!result) return;

                this._store.dispatch(new ReloadReportBuilderLetterheadOptions());

            });;
    }

    updateLetterhead(value: string){

        if (this.letterheadControl.value && this.letterheadControl.value.key == value) return;

        this.letterheadControl.setValue(this.letterheadOptions.find(x => x.key == this.letterheadKeyControl.value));
    }

    private updateIncludeIndv(){
        let includeChars = this.charSummaryOptionsGroup.value.includeChars;
        let includeMeasures = this.measureSummaryOptionsGroup.value.includeMeasures;
        let includeIndexes = this.charIndexOptionsGroup.value.includeIndexes;
        let includeInfoMeasurements = this.cultivarInfoOptionsGroup.value.includeMeasures
        let includeInfoChars = this.cultivarInfoOptionsGroup.value.includeChars

        if(Array.isArray(includeChars) && includeChars.length > 0 && includeChars[0] === '*'){
            this.disableIndvChars = true;
        }else{
            this.disableIndvChars = false;
        }

        if(Array.isArray(includeInfoMeasurements) && includeInfoMeasurements.length > 0 && includeInfoMeasurements[0] === '*'){
            this.disableInfoSheetMeasures = true;
        }else{
            this.disableInfoSheetMeasures = false;
        }

        if(Array.isArray(includeInfoChars) && includeInfoChars.length > 0 && includeInfoChars[0] === '*'){
            this.disableInfoSheetChars = true;
        }else{
            this.disableInfoSheetChars = false;
        }

        if(Array.isArray(includeMeasures) && includeMeasures.length > 0 && includeMeasures[0] === '*'){
            this.disableIndvMeasures = true;
        }else{
            this.disableIndvMeasures = false;
        }

        if(Array.isArray(includeIndexes) && includeIndexes.length > 0 && includeIndexes[0] === '*'){
            this.disableIndvIndexes = true;
        }else{
            this.disableIndvIndexes = false;
        }
    }

    openEvaluationSelectDialog(table: ReportTable, samples: ReportSample[]) {
        const data: ReportTableEvaluationFormDialogData = {
            data: {...table},
            samples: samples
        }

        this._dialogs.open(ReportTableEvaluationFormDialog, {data})
            .afterClosed()
            .subscribe(result => {
                if (!result) return;
                this._store.dispatch(new ReportAddTable(result));
            });;
    }

    private updateImageOptionPositions(options: ReportImageOption[][]): ReportImageOption[][] {
        const updatedImages = [];

        options.forEach(
            (option) => {
                updatedImages.push(
                    option.map((image, index) => ({
                        ...image,
                        position: index
                    }))
                )
            }
        );

        return updatedImages;
    }

    private canCompileReportOptions(data: Report): boolean {
        if (data.options.cultivarInfoOptions == null) return false;
        return true;
    }

    private cleanRemarks(remarks: ReportRemark[]): ReportRemark[] {
        let cleanedRemarks: ReportRemark[] = [];

        remarks.forEach((remark) => {
            let cRemark = {
                ...remark,
                documents: [],
                imageOptions: [],
                images: [],
            }

            cleanedRemarks.push(cRemark);
        });

        return cleanedRemarks;
    }

    private getDataCopy(data: any) {
        return JSON.parse(JSON.stringify(data));
    }
}


