import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, Output, Directive, Component } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Status } from '@core/data';
import { BehaviorSubject, combineLatest, of, Subject, Observable } from 'rxjs';
import { debounceTime, startWith, switchMap, takeUntil } from 'rxjs/operators';


@Directive()
export abstract class ModelAutocompleteComponent<T>
    implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<T>
{

    static nextId = 0;

    id = `pv-model-autocomplete-${ModelAutocompleteComponent.nextId++}`;

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @HostBinding('attr.aria-describedby')
    describedBy = '';

    @Input()
    get value(): T | null {
        return this._value;
    }
    set value(value: T | null) {
        if (this._value !== value) {
            this.writeValue(value);
            this.onChange(value);
        }
    }

    get empty() {
        return !this.value;
    }

    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean { return this._disabled; }
    set disabled(value: boolean) {
        this.setDisabledState(coerceBooleanProperty(value));
    }

    @Input()
    get orgKey(): string {
        return this.orgKey$.value;
    }
    set orgKey(value: string) {
        if(value && this.orgKey !== value){
            this.orgKey$.next(value);
        }
    }

    orgKey$ = new BehaviorSubject<string>(null);
    searchText$ = new BehaviorSubject<string>(null);
    @Input()
    control = new FormControl();
    onChange: any = () => { };
    onTouch: any = () => { };
    stateChanges = new Subject<void>();
    _placeholder: string = '';
    _required = false;
    _disabled = false;
    _value: T = null;
    focused = false;
    errorState = false;

    status: Status = Status.UNINITIALIZED;
    options: T[] = [];

    private _destroy$ = new Subject();

    constructor(
        protected fm: FocusMonitor,
        protected elRef: ElementRef<HTMLElement>,
        protected _changeRef: ChangeDetectorRef,
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() @Self() public ngControl: NgControl,
        @Optional() public _parentForm: NgForm,
        @Optional() public _parentFormGroup: FormGroupDirective,
        @Optional() public _parentFormField: MatFormField,
    ) {
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }

        fm.monitor(elRef.nativeElement, true)
        .subscribe(origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        });
    }

    ngOnInit() {

        combineLatest(this.orgKey$, this.searchText$)
        .pipe(
            takeUntil(this._destroy$),
            switchMap(changes => {

                let [orgKey, text] = changes;

                if(!orgKey || !text || text.length < 2){
                    return of({
                        status: Status.INVALID,
                        options: this.options
                    });
                } else {
                    return this.search(orgKey, text);
                }

            }),
            startWith({ status: Status.OK, options: [] })
        )
        .subscribe(state => {
            this.options = state.options;
            this.status = state.status;
            this._changeRef.markForCheck();
        });

        this.control.valueChanges
            .pipe(takeUntil(this._destroy$), debounceTime(300))
            .subscribe(value => {

                if(typeof value !== 'object'){
                    this.searchText$.next(value);
                }

            });

    }

    ngOnDestroy() {
        this.fm.stopMonitoring(this.elRef.nativeElement);
        this.stateChanges.complete();
        this._destroy$.next();
        this._destroy$.complete();
    }

    ngDoCheck(){
        if(this.ngControl) this.updateErrorState();
    }

    clear(){
        this.writeValue(null);
        this.control.setValue(null);
        this.onChange(null);
        this.onTouch();
    }

    writeValue(obj: any): void {
        this._value = obj;
        this.stateChanges.next();
        this._changeRef.markForCheck();
    }

    onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase() != 'input') {
            this.elRef.nativeElement.querySelector('input').focus();
        }
    }

    updateErrorState() {
        const oldState = this.errorState;
        const parent = this._parentFormGroup || this._parentForm;
        const matcher = /*this.errorStateMatcher || */this._defaultErrorStateMatcher;
        const control = this.ngControl ? this.ngControl.control as FormControl : null;
        const newState = matcher.isErrorState(control, parent);

        if (newState !== oldState) {
            this.errorState = newState;
            this.stateChanges.next();
        }
    }

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this._disabled = isDisabled;
        if (isDisabled) this.control.disable();
        else this.control.enable();
        this.stateChanges.next();
    }

    blur() {
        this.onTouch();
    }

    optionSelected(event: MatAutocompleteSelectedEvent){
        this.writeValue(event.option.value);
        this.onChange(this._value);
        this.focused = false;
    }

    abstract displayFn(model: T);
    abstract search(orgKey: string, text: string): Observable<{status: Status, options: T[]}>;

}