import { Injectable, Inject } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { API_BASE_URI } from '../http/api';
import { Filter, FilterBuilder } from '../http/filter';
import { SampleType, Sample } from '../types/sample';
import { Collection } from '../http/collection';
import { DetailRequest, DetailRequestBuilder } from '../http/detail';
import { Observable } from 'rxjs';
import { SearchRequest, SearchResponse } from '../http/search';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ExportSampleStats } from "@app/evaluation/components/sample-index/sample-index.state-actions";

export class SampleUpdated {
    static readonly type = "[SampleService] Sample Updated";
    constructor(public sample: Sample){}
}

export class SampleDeleted {
    static readonly type = "[SampleService] Sample Deleted";
    constructor(public sample: Sample){}
}

export class SampleCreated {
    static readonly type = "[SampleService] Sample Created";
    constructor(public sample: Sample){}
}

export class SampleIndexImported {
    static readonly type = "[SampleService] Sample Index Imported";
    constructor(public response: SampleImportResponse) {}
}

export interface SampleImportResponse {
    response: string;
}

@Injectable()
export class SampleService {

    constructor(
        private _http: HttpClient,
        private _store: Store,
        @Inject(API_BASE_URI) private _baseUri: string
    ) { }

    get(sampleKey: string, detail?: DetailRequest): Observable<Sample> {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<Sample>(`${this._baseUri}/samples/${sampleKey}`, { params });
    }

    create(model: Partial<Sample>) {
        return this._http.post<Sample>(`${this._baseUri}/samples`, model)
                .pipe(tap(sample => this._store.dispatch(new SampleCreated(sample))));
    }

    update(key: string, model: Partial<Sample>) {
        return this._http.put<Sample>(`${this._baseUri}/samples/${key}`, model)
                .pipe(tap(sample => this._store.dispatch(new SampleUpdated(sample))));
    }

    delete(key: string) {
        return this._http.delete<Sample>(`${this._baseUri}/samples/${key}`)
                .pipe(tap(sample => this._store.dispatch(new SampleDeleted(sample))));
    }

    query(filter: Filter, detail?: DetailRequest): Observable<Collection<Sample>> {
        const params = {
            ...FilterBuilder.queryParams(filter),
            ...DetailRequestBuilder.queryParams(detail),
        };

        return this._http.get<Collection<Sample>>(`${this._baseUri}/samples`, { params });
    }

    search(request: SampleSearchRequest) {
        return this._http.get<SearchResponse<Sample>>(`${this._baseUri}/samples/search`, {params: <any>request});
    }

    export(filter: Filter, ownerOrgKey: string, sampleType: string, detail?: DetailRequest) {

        const data = {
            ...FilterBuilder.queryParams(filter),
            ...DetailRequestBuilder.queryParams(detail),
        };

        return this._http.post<SampleExportResponse>(`${this._baseUri}/samples/export`, {...data, ownerOrgKey, sampleType});
    }

    import(orgKey: string, file: File) {
        let data = new FormData();
        data.set('file', file);
        data.set('ownerOrgKey', orgKey);

        return this._http.post<SampleImportResponse>(`${this._baseUri}/samples/import`, data)
            .pipe(tap(result => {
                this._store.dispatch(new SampleIndexImported(result));
            }));
    }

    downloadImportTemplate() {
        return this._http.get<Blob>(`${this._baseUri}/samples/import/template`, {
            responseType: 'blob' as 'json',
        });
    }

    exportStats(filter: Filter, ownerOrgKey: string, sampleType: string, data: ExportSampleStats) {
        const exportType = "stat-export";

        const params = {
            ...FilterBuilder.queryParams(filter),
            ...data,
        };

        return this._http.post<SampleExportResponse>(`${this._baseUri}/samples/export/stats`, {...params, ownerOrgKey, sampleType, exportType});
    }
}

export interface SampleSearchRequest extends SearchRequest {
    sampleType?: SampleType;
}

export interface SampleExportResponse {}