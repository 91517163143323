import { SampleType, Cultivar, Site } from '@core/data';
import { SampleIndexQuery } from './sample-index.state-model';
import { SampleLabelOptions } from '../sample-labels/sample-label-options';



export class InitSampleIndex {
    static readonly type = "[SampleIndex] Init";
    constructor(public orgKey: string, public sampleType: SampleType){}
}

export class LoadSampleIndex {
    static readonly type = "[SampleIndex] Load";
    constructor(){}
}

export class PageSampleIndex {
    static readonly type = "[SampleIndex] Page";
    constructor(public index: number, public size: number){}
}

export class SortSampleIndex {
    static readonly type = "[SampleIndex] Sort";
    constructor(public column: string, public order = 'asc'){}
}

export class QuerySampleIndex {
    static readonly type = "[SampleIndex] Query";
    constructor(public query: SampleIndexQuery){}
}

export class ClearSampleIndexFilter {
    static readonly type = "[SampleIndex] Clear Filter";
    constructor(){}
}

export class PushSampleIndexModelQuery {
    static readonly type = "[SampleIndex] Push Model Query";
    constructor(public modelType: string, public model: Cultivar | Site){}
}


export class SetSampleLabelOptions {
    static readonly type = "[SampleIndex] Set label options";
    constructor(public options: SampleLabelOptions){}
}

export class ExportSampleIndex {
    static readonly type = "[SampleIndex] Export";
    constructor(){}
}

export class ImportSampleIndex {
    static readonly type = "[SampleIndex] Import";
    constructor(public file: File){}
}

export class ExportSampleStats {
    static readonly type = "[SampleIndex] Stats Export";
    constructor(public protocolId: string, public measureLimit: 20){}
}

export class DownloadSampleImportTemplate {
    static readonly type = "[SampleIndex] Import Template";
    constructor(){}
}

export class SetSampleIndexSelected {
    static readonly type = "[SampleIndex] Set Selected";
    constructor(public keys: string[]){}
}

export class DeleteSample {
    static readonly type = "[SampleIndex] Delete Sample";
    constructor(public sampleKey: string){}
}