import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Filter, FilterBuilder, FilterQueryMode } from '../http/filter';
import { Collection } from '../http/collection';
import { Cultivar } from '../types/cultivar';
import { SearchRequest, SearchResponse } from '../http/search';
import { catchError, tap } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { Dot } from '@core/utils';
import { API_BASE_URI } from '../http/api';
import { Store } from '@ngxs/store';
import { DetailRequest, DetailRequestBuilder } from '../http/detail';


export class CultivarUpdated {
    static readonly type = "[CultivarService] Cultivar Updated";
    constructor(public cultivar: Cultivar){}
}

export class CultivarDeleted {
    static readonly type = "[CultivarService] Cultivar Deleted";
    constructor(public cultivar: Cultivar){}
}
export class CultivarCreated {
    static readonly type = "[CultivarService] Cultivar Created";
    constructor(public cultivar: Cultivar){}
}

export class CultivarIndexImported {
    static readonly type = "[CultivarService] Cultivar Index Imported";
    constructor(public response: CultivarImportResponse){}
}

export interface CultivarExportResponse {}
export interface CultivarImportResponse {
    response: string;
}

export class DuplicateCommonNameError extends HttpErrorResponse {}
export class CultivarConnectionsError extends HttpErrorResponse {}


@Injectable()
export class CultivarService {

    constructor(
        private _http: HttpClient,
        @Inject(API_BASE_URI) private _baseUri: string,
        private _store: Store
    ){}

    query(filter: Filter, detail?: DetailRequest){
        let params = {
            ...(new FilterBuilder(filter)).getQueryParams(),
            ...(detail ? (new DetailRequestBuilder(detail)).getQueryParams() : {})
        };
        return this._http.get<Collection<Cultivar>>(`${this._baseUri}/cultivars`, { params });
    }

    queryKeys(keys: string[]){

        const params =
            (new FilterBuilder())
            .setQuery('key', keys, FilterQueryMode.IN)
            .getQueryParams();

        return this._http.get<Collection<Cultivar>>(`${this._baseUri}/cultivars`, { params });
    }

    search(search: SearchRequest){
        return this._http.get<SearchResponse<Cultivar>>(`${this._baseUri}/cultivars/search`, {params: <any>search});
    }

    groupLicensees(filter: Filter){
        let params = (new FilterBuilder(filter)).getQueryParams();
        return this._http.get<{ group: string[] }>(`${this._baseUri}/cultivars/group/licensee`, { params });
    }

    export(filter: Filter, ownerOrgKey: string, type = 'xlsx'){
        let params = (new FilterBuilder(filter)).getQueryParams();
        return this._http.get<CultivarExportResponse>(`${this._baseUri}/cultivars/export`, { params: {...params, type, ownerOrgKey}});
    }

    import(orgKey: string, file: File) {
        let data = new FormData();
        data.set('file', file);
        data.set('ownerOrgKey', orgKey);

        return this._http.post<CultivarImportResponse>(`${this._baseUri}/cultivars/import`, data)
            .pipe(tap(result => {
                this._store.dispatch(new CultivarIndexImported(result));
            }));
    }

    downloadImportTemplate() {
        return this._http.get<Blob>(`${this._baseUri}/cultivars/import/template`, {
            responseType: 'blob' as 'json',
        });
    }

    get(key: string, detail?: DetailRequest) {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<Cultivar>(`${this._baseUri}/cultivars/${key}`, { params });
    }

    create(model: Partial<Cultivar>): Observable<Cultivar>{
        return this._http.post(`${this._baseUri}/cultivars`, model)
                    .pipe(
                        this.catchCommonNameError(),
                        tap(result => this._store.dispatch(new CultivarCreated(result)))
                    );
    }

    update(key: string, model: Partial<Cultivar>): Observable<Cultivar>{
        return this._http.put(`${this._baseUri}/cultivars/${key}`, model)
                    .pipe(
                        this.catchCommonNameError(),
                        tap(result => this._store.dispatch(new CultivarUpdated(result)))
                    );
    }

    switch(targetKey: string, model: Partial<Cultivar>, candidateKey: string): Observable<Cultivar>{
        return this._http.put(`${this._baseUri}/cultivars/${targetKey}/${candidateKey}`, model)
                    .pipe(
                        this.catchCommonNameError(),
                        tap(result => this._store.dispatch(new CultivarUpdated(result)))
                    );
    }

    delete(key: string): Observable<Cultivar> {
        return this._http.delete<Cultivar>(`${this._baseUri}/cultivars/${key}`)
                    .pipe(
                        catchError(e => {

                            if(e instanceof HttpErrorResponse && e.status === 400 && e.error.message === 'has_samples'){
                                return throwError(new CultivarConnectionsError(e));
                            }

                            return throwError(e);
                        }),
                        tap(result => this._store.dispatch(new CultivarDeleted(result)))
                    );
    }

    private catchCommonNameError(){
        return catchError<Cultivar, Observable<Cultivar>>(e => {

            if(e instanceof HttpErrorResponse && e.status === 422
                && String(Dot.get(e.error, 'errors.commonName.0', '')).includes('taken') ){
                return throwError(new DuplicateCommonNameError(e));
            }

            return throwError(e);
        });
    }


}
