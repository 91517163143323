<ng-container *ngIf="state$ | async as state">
    <div mat-dialog-title class="mat-dialog-header">
        <div>
            <h2 class="dialog-title">Transfer Cultivar Data</h2>
            <h3 class="dialog-subtitle">
                Organisation: <pv-org-control [formControl]="ownerOrgKeyControl"></pv-org-control>
            </h3>
            <p class="dialog-text">
                This process will transfer all related sample information, including Tasting, Plant and Harvest Samples.
            </p>
        </div>
    </div>

    <form
        id="cultivarForm"
        [formGroup]="formGroup"
        #cultivarForm="ngForm"
        class="pv-form form-wrapper"
        autocomplete="off"
        (ngSubmit)="attempt()">


        <div class="form-wrapper">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>
                    <mat-icon svgIcon="common:flat_scion"></mat-icon>
                    <span>&nbsp;Cultivar</span>
                </mat-label>
                <pv-cultivar-autocomplete [orgKey]="ownerOrgKeyControl.value"
                                        formControlName="cultivar"
                                        [excludedCultivar]="excludedCultivar"
                                        [crop]="selectedCrop"
                                        [rootstock]="rootstock">
                </pv-cultivar-autocomplete>
                <mat-error>Select a cultivar</mat-error>
            </mat-form-field>
        </div>

        <div mat-dialog-content class="content-wrapper">
            <!-- Basic Info Section -->
            <ng-container>
                <mat-card class="switch-mat-card form-column">
                    <mat-card-header>
                        <mat-card-title>Base cultivar</mat-card-title>
                        <mat-card-subtitle>Cultivar receiving data.</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content class="pv-cultivar-form-card">
                        <div>
                            <p>
                                <strong>Common Name</strong>
                            </p>
                            {{ commonNameControl.value }}
                        </div>
                        <div>
                            <p>
                                <strong>Harvest Window</strong>
                            </p>
                            {{ harvestWindowControl.value | extractHarvestWindow }}
                        </div>
                        <div>
                            <p>
                                <strong>Crop</strong>
                            </p>
                            <div><mat-icon matPrefix [svgIcon]="'common:' + cropIdControl.value" class="mat-18 mr-12"></mat-icon>
                                {{ cropIdControl.value | labelCrop }}
                            </div>
                        </div>
                        <p><strong>Samples</strong></p>
                        <div>
                            <!-- Scion Plant Samples -->
                            <div class="sample-count" *ngIf="target?.scionPlantSamplesCount">
                                <mat-icon svgIcon="common:plant_sample"></mat-icon>
                                <mat-icon svgIcon="common:flat_scion"></mat-icon>
                                <span>&nbsp;{{ target.scionPlantSamplesCount }}</span>
                            </div>

                            <!-- Rootstock Plant Samples -->
                            <div class="sample-count" *ngIf="target?.rootstockPlantSamplesCount">
                                <mat-icon svgIcon="common:plant_sample"></mat-icon>
                                <mat-icon svgIcon="common:flat_rootstock"></mat-icon>
                                <span>&nbsp;{{ target.rootstockPlantSamplesCount }}</span>
                            </div>

                            <!-- Scion Harvest Samples -->
                            <div class="sample-count" *ngIf="target?.scionHarvestSamplesCount">
                                <mat-icon svgIcon="common:harvest_sample"></mat-icon>
                                <mat-icon svgIcon="common:flat_scion"></mat-icon>
                                <span>&nbsp;{{ target.scionHarvestSamplesCount }}</span>
                            </div>

                            <!-- Rootstock Harvest Samples -->
                            <div class="sample-count" *ngIf="target?.rootstockHarvestSamplesCount">
                                <mat-icon svgIcon="common:harvest_sample"></mat-icon>
                                <mat-icon svgIcon="common:flat_rootstock"></mat-icon>
                                <span>&nbsp;{{ target.rootstockHarvestSamplesCount }}</span>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </ng-container>

            <ng-container>
                <mat-card class="switch-mat-card form-column">
                    <mat-card-header>
                    <mat-card-title>Cultivar Data to Transfer</mat-card-title>
                    <mat-card-subtitle>Cultivar data to be transfered.</mat-card-subtitle>
                    </mat-card-header>

                    <mat-card-content class="col-4 pv-cultivar-form-card">
                    <div>
                        <p><strong>Common Name</strong></p>
                        {{ this.candidate?.commonName }}
                    </div>
                    <div>
                        <p><strong>Harvest Window</strong></p>
                        {{ this.candidate?.harvestWindow | extractHarvestWindow }}
                    </div>
                    <div>
                        <p><strong>Crop</strong></p>
                        <div>
                        <mat-icon *ngIf="this.candidate?.cropId"
                                    matPrefix
                                    [svgIcon]="'common:' + this.candidate?.cropId"
                                    class="mat-18 mr-12">
                        </mat-icon>
                        {{ this.candidate?.cropId | labelCrop }}
                        </div>
                    </div>
                    <div>
                        <p><strong>Samples</strong></p>

                        <!-- Scion Plant Samples -->
                        <div class="sample-count" *ngIf="candidate?.scionPlantSamplesCount">
                            <mat-icon svgIcon="common:plant_sample"></mat-icon>
                            <mat-icon svgIcon="common:flat_scion"></mat-icon>
                            <span>&nbsp;{{ candidate.scionPlantSamplesCount }}</span>
                        </div>

                        <!-- Rootstock Plant Samples -->
                        <div class="sample-count" *ngIf="candidate?.rootstockPlantSamplesCount">
                            <mat-icon svgIcon="common:plant_sample"></mat-icon>
                            <mat-icon svgIcon="common:flat_rootstock"></mat-icon>
                            <span>&nbsp;{{ candidate.rootstockPlantSamplesCount }}</span>
                        </div>

                        <!-- Scion Harvest Samples -->
                        <div class="sample-count" *ngIf="candidate?.scionHarvestSamplesCount">
                            <mat-icon svgIcon="common:harvest_sample"></mat-icon>
                            <mat-icon svgIcon="common:flat_scion"></mat-icon>
                            <span>&nbsp;{{ candidate.scionHarvestSamplesCount }}</span>
                        </div>

                        <!-- Rootstock Harvest Samples -->
                        <div class="sample-count" *ngIf="candidate?.rootstockHarvestSamplesCount">
                            <mat-icon svgIcon="common:harvest_sample"></mat-icon>
                            <mat-icon svgIcon="common:flat_rootstock"></mat-icon>
                            <span>&nbsp;{{ candidate.rootstockHarvestSamplesCount }}</span>
                        </div>
                    </div>

                    </mat-card-content>
                </mat-card>
            </ng-container>
        </div>
    </form>
    <pv-form-actions
        mat-dialog-actions
        [form]="cultivarForm"
        (cancel)="cancel()"
        [status]="state.status">
    </pv-form-actions>
</ng-container>
