import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Inject } from "@angular/core";
import { Subject, Observable } from 'rxjs';
import { ReportTemplate, Status } from '@core/data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { ReportTemplatesState, ReportTemplatesStateModel, InitReportTemplates, SubmitReportTemplate, DeleteReportTemplate } from './report-templates.state';
import { takeUntil } from 'rxjs/operators';
import { Dialog, Snackbar } from '@core/material';


export interface ReportTemplatesDialogData extends Partial<ReportTemplate> {}

@Component({
    selector: 'pv-report-templates-dialog',
    templateUrl: 'report-templates.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false
})
export class ReportTemplatesDialog implements OnInit, OnDestroy {

    @Select(ReportTemplatesState)
    state$: Observable<ReportTemplatesStateModel>;

    private _destroy$ = new Subject();

    idControl = new FormControl(null);
    nameControl = new FormControl(null, [Validators.required]);

    formGroup = new FormGroup({
        id: this.idControl,
        name: this.nameControl,
        ownerOrgKey: new FormControl(null, [Validators.required]),
        reportOptions: new FormControl(null),
        reportRemarks: new FormControl(),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: ReportTemplatesDialogData,
        private _store: Store,
        private _snackbar: Snackbar,
        private _dialogRef: MatDialogRef<ReportTemplatesDialog>,
        private _dialog: Dialog,
    ){}


    ngOnInit(){

        this._store.dispatch(new InitReportTemplates(this._data));


        this.state$
            .pipe(takeUntil(this._destroy$))
            .subscribe(state => {

                if(state.status === Status.COMPLETE){
                    this._snackbar.info("Report template updated");
                    this._dialogRef.close(state.data);
                } else if(state.status === Status.DELETED) {
                    this._snackbar.info("Report template deleted");
                    this._dialogRef.close(state.data);
                }else if(state.status !== Status.LOADING && state.data){
                    this.formGroup.reset(state.data);
                    this.formGroup.enable();

                }else{
                    this.formGroup.disable();
                }

            });


    }

    ngOnDestroy(){

        this._destroy$.next();
        this._destroy$.complete();
    }

    existingSelectionChanged(event: MatRadioChange, template: ReportTemplate){

        if(event.value && this.nameControl.value !== template.name){
            this.nameControl.setValue(template.name);
        }

    }

    selectExisting(template: ReportTemplate){

    }

    delete(template: ReportTemplate){

        this._dialog.confirm(
            'Delete Template',
            'Are you sure you want to delete this template?',
            'Delete',
            'Cancel'
        ).afterClosed().subscribe(res => {
            if (res) {
                this._store.dispatch(new DeleteReportTemplate(template.id));
            }
        });
    }


    attempt(){

        this.formGroup.updateValueAndValidity();

        if(this.formGroup.valid){

            this._store.dispatch(new SubmitReportTemplate(this.formGroup.value));

        }

    }

    cancel(){
        this._dialogRef.close();
    }

}


