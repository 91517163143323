import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Form, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cultivar, Sample, Status } from '@core/data';
import { Dialog, Snackbar } from '@core/material';
import { Crop, Library } from '@library';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { CultivarFormState, CultivarFormStateModel, InitCultivarSwitchForm, SubmitCultivarSwitchForm } from './cultivar-form.state';
import { LIBRARY } from '../../library';
import { TastingSample } from '@core/data/types/tasting-sample';

@Component({
    selector: 'pv-cultivar-switch-form-dialog',
    templateUrl: './cultivar-switch-form.dialog.html',
    styleUrls: ['./cultivar-form.scss']
})
export class CultivarSwitchFormDialog implements OnInit, OnDestroy {
    scionSamplesControl: FormControl = new FormControl([]);
    tastingSamplesControl: FormControl = new FormControl([]);
    ownerOrgKeyControl: FormControl = new FormControl(null, [Validators.required]);
    commonNameControl: FormControl = new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(32)]);
    cultivarControl = new FormControl(null, [Validators.required]);
    cropIdControl: FormControl = new FormControl(null, [Validators.required]);
    isRootstockControl: FormControl = new FormControl(false);
    harvestWindowControl: FormControl = new FormControl(null);

    formGroup: FormGroup = new FormGroup({
        cultivar: this.cultivarControl,
        commonName: this.commonNameControl,
        cropId: this.cropIdControl,
        harvestWindow: this.harvestWindowControl,
        ownerOrgKey: this.ownerOrgKeyControl,
        scionSamples: this.scionSamplesControl,
        tastingSamples: this.tastingSamplesControl,
    });

    cropOptions: Crop[];

    title: string = '';
    candidate: Cultivar;
    target: Partial<Cultivar>;
    disabled: boolean = true;
    selectedCrop: string = '';
    rootstock: boolean = false;

    @Input()
    excludedCultivar: string = '';

    @Select(CultivarFormState)
    state$: Observable<CultivarFormStateModel>;

    @Select(CultivarFormState.data)
    data$: Observable<Partial<Cultivar>>;

    private _destroy$ = new Subject();

    constructor(
        protected _dialogRef: MatDialogRef<CultivarSwitchFormDialog>,
        private _dialogs: Dialog,
        @Inject(MAT_DIALOG_DATA) private _data: CultivarSwitchFormDialogData,
        private _store: Store,
        @Inject(LIBRARY) private _evalLib: Library,
        private _snackbar: Snackbar
    ) { }

    ngOnInit() {
        this._store.dispatch(new InitCultivarSwitchForm(this._data.key, this._data.defaults));

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                if (state.status === Status.COMPLETE) {
                    this._dialogRef.close(state.data);
                } else if (state.status === Status.OK || state.status === Status.INVALID) {
                    this.formGroup.enable();
                } else {
                    this.formGroup.disable();
                }
            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) {
                    this.reset(data);
                    this.excludedCultivar = this._data.key
                    this.selectedCrop = this.cropIdControl.value;
                    this.rootstock = this.isRootstockControl.value;
                    this.target = data
                }
            });

        this.cultivarControl.valueChanges
            .pipe(takeUntil(this._destroy$), debounceTime(300))
            .subscribe(value => {
                this.candidate = value;
                this.getCandidateInfo(value);
            });

        this.cropOptions = this._evalLib.filterExcludedCrops();
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    getCandidateCrop(id: string) {
        if (!id) return '';
        let crop = this._evalLib.getCropById(id) || {} as Crop;
        return crop;
    }

    getCandidateInfo(candidate: Cultivar) {
        if (!candidate) return;

        candidate.scionSamples = this.getScionSamples(candidate);

        candidate.tastingSamples = this.getCultivarTastings(candidate);

        candidate.rootStockSamples = this.getCultivarRootStockSamples(candidate);

        this.candidate = { ...candidate };
    }

    getScionSamples(candidate: Cultivar): Sample[] {
        return candidate.scionSamples || [];
    }

    getCultivarTastings(candidate: Cultivar): TastingSample[] {
        return candidate.tastingSamples || [];
    }

    getCultivarRootStockSamples(candidate: Cultivar): Sample[] {
        return candidate.rootStockSamples || [];
    }

    reset(data: Partial<Cultivar>) {
        const cropId = data.cropId || 'apple'

        const excludedCrop = this._evalLib.getExcludedCropList().find(crop => crop === cropId)

        if(excludedCrop){
           let crop = this._evalLib.crops.get(excludedCrop)
           this.cropOptions.push(crop)
        }

        this.formGroup.reset({
            commonName: data.commonName || '',
            cropId: excludedCrop ? excludedCrop : cropId,
            isRootstock: data.isRootstock || false,
            harvestWindow: data.harvestWindow || null,
            scionSamples: data.scionSamples || null,
            cultivarTastings: data.tastingSamples || null,
            rootStockSamples: data.rootStockSamples || null,
            ownerOrgKey: data.ownerOrgKey,
        });

    }

    attempt() {
        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsDirty();

        if (!this.formGroup.valid) return this._snackbar.error("Invalid input. Check your input and try again.");

        this._dialogs.confirmSave('Save Changes', 'Are you sure you want to swap your cultivar data?', 'Confirm')
            .afterClosed()
            .subscribe(result => {
                if (!result) return;

                this.save();
            });
    }

    save() {
        const target = { ...this.formGroup.value };
        const candidate = { ...this.candidate };

        this._store.dispatch(new SubmitCultivarSwitchForm(target, candidate.key));
    }

    cancel() {
        this._dialogRef.close();
    }
}

export interface CultivarSwitchFormDialogData {
    key?: string;
    defaults?: Partial<Cultivar>;
}