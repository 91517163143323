import { Component, ChangeDetectionStrategy, HostBinding, ElementRef, ChangeDetectorRef, Optional, Self, Input } from "@angular/core";
import { ModelAutocompleteComponent } from './model-autocomplete.component';
import { MatFormFieldControl, MatFormField } from '@angular/material/form-field';
import { Cultivar, CultivarService, DetailRequest, FilterBuilder, Status, translateCommonErrorStatus } from '@core/data';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgControl, NgForm, FormGroupDirective } from '@angular/forms';
import { map, catchError, startWith } from 'rxjs/operators';
import { of, Observable, throwError } from 'rxjs';





@Component({
    selector: 'pv-cultivar-autocomplete',
    templateUrl: 'cultivar-autocomplete.component.html',
    styleUrls: [ 'model-autocomplete.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    providers: [{
        provide: MatFormFieldControl,
        useExisting: CultivarAutocompleteComponent
    }]
})
export class CultivarAutocompleteComponent extends ModelAutocompleteComponent<Cultivar> {


    @HostBinding()
    id = `pv-cultivar-autocomplete-${CultivarAutocompleteComponent.nextId++}`;

    @Input()
    excludedCultivar?: string;

    @Input()
    crop?: string;

    @Input()
    rootstock?: boolean;

    constructor(
        private _service: CultivarService,
        protected fm: FocusMonitor,
        protected elRef: ElementRef<HTMLElement>,
        protected _changeRef: ChangeDetectorRef,
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() @Self() public ngControl: NgControl,
        @Optional() public _parentForm: NgForm,
        @Optional() public _parentFormGroup: FormGroupDirective,
        @Optional() public _parentFormField: MatFormField,
    ){
        super(fm, elRef, _changeRef, _defaultErrorStateMatcher, ngControl, _parentForm, _parentFormGroup, _parentFormField);
    }

    displayFn(model: Cultivar){
        if(model) return model.commonName;
        return '';
    }

    getMinHeight(option: Cultivar): string {
        const licenseePresent = option.licensee;
        const onlyLicenseePresent = this.isOnlyLicenseePresent(option);
        const allOtherPropertiesPresent = this.areAllOtherPropertiesPresent(option);
        const cultivarScionSamples = option.scionSamples;
        const hasSite = cultivarScionSamples.every(scionSample => scionSample.site);
        const hasHarvestWindow = option.harvestWindow;

        if (licenseePresent && allOtherPropertiesPresent) {
            return 'auto'; // Allow flexible height based on content
        } else if (licenseePresent && !hasHarvestWindow) {
            return '160px';
        } else if (onlyLicenseePresent) {
            return '90px'; // Set height if only licensee is present
        } else if (licenseePresent) {
            return '130px'; // Set height for licensee with missing properties
        } else if (hasSite && !licenseePresent) {
            return '100px';
        } else {
            return '50px'; // Default height when licensee is not present
        }
    }

    isOnlyLicenseePresent(option: Cultivar): boolean {
        return option.licensee && !option.rootstockHarvestSamplesCount && !option.rootstockPlantSamplesCount && !option.scionHarvestSamplesCount && !option.scionPlantSamplesCount;
    }

    areAllOtherPropertiesPresent(option: any): boolean {
        return option.rootstockHarvestSamplesCount && option.rootstockPlantSamplesCount && option.scionHarvestSamplesCount && option.scionPlantSamplesCount && option.harvestWindow;
    }

    search(orgKey: string, text: string): Observable<{ status: Status, options: Cultivar[] }> {

        let excludedCultivar = this.excludedCultivar;

        const detail: DetailRequest = {
            counts: [
                'scionPlantSamples',
                'scionHarvestSamples',
                'rootstockPlantSamples',
                'rootstockHarvestSamples',
            ],
            related: ['scionSamples', 'scionSamples.site', 'tastingSamples', 'rootstockSamples']
        };

        return this._service.query(this.getFilter(orgKey, text), detail)
        .pipe(
            map(result => {

                 // Return unfiltered list if any required property is undefined
                if (this.crop === undefined || this.rootstock === undefined || this.excludedCultivar === undefined) {
                    return {
                        status: result.total === 0 ? Status.EMPTY : Status.OK,
                        options: result.data
                    };
                }

                const cultivarExcludedList = this.getExcludedCultevaList();
                cultivarExcludedList.push(excludedCultivar);

                const validCropIDs = this.getValidCropIDs();
                validCropIDs.push(this.crop)

                const rootstocks = this.getRootstocks();
                rootstocks.push(this.rootstock);

                let filteredOptions = result.data.filter(cultivar =>
                    validCropIDs.includes(cultivar.cropId) &&
                    !(cultivarExcludedList.includes(cultivar.key)) &&
                    (rootstocks.includes(cultivar.isRootstock))
                );

                if (result.total === 0) {
                    return {
                        status: Status.EMPTY,
                        options: []
                    };
                } else {
                    return {
                        status: Status.OK,
                        options: filteredOptions
                    };
                }
            }),
            catchError(error => {
                return throwError({
                    status: translateCommonErrorStatus(error),
                    options: []
                });
            })
        );
    }

    private getFilter(orgKey: string, searchString: string){

        const fb = (new FilterBuilder());

        if(orgKey){
            fb.setQuery('ownerOrgKey', orgKey)
        }

        if(typeof searchString === 'string' && searchString.length > 0){
            if (searchString.trim()) fb.setQuery('$fuzzy', searchString);
        }

        return fb.get();

    }

    getExcludedCultevaList(): string[]{
        return [];
    }

    getValidCropIDs(): string[] {
        return [];
    }

    getRootstocks(): boolean[] {
        return [];
    }

}