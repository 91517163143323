<h2 mat-dialog-title>Edit Measure</h2>
<div mat-dialog-content>
    <form [formGroup]="formGroup" class="pv-form">

        <div class="form-column">

            <mat-checkbox formControlName="showChart">Show Chart</mat-checkbox>
            <mat-checkbox formControlName="showData">Show Data</mat-checkbox>
            <mat-checkbox formControlName="showStats">Show Stats</mat-checkbox>
            <mat-checkbox formControlName="showAverage">Show Overall Average</mat-checkbox>

            <mat-form-field appearance="standard">
                <mat-label>Bucket Options</mat-label>
                <mat-select formControlName="bucketId">
                    <mat-option *ngFor="let bucket of bucketOptions" [value]="bucket.id">{{ bucket.label }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>Chart Options</mat-label>
                <mat-select formControlName="chartId">
                    <mat-option *ngFor="let chart of chartOptions" [value]="chart.id">{{ chart.title }}</mat-option>
                </mat-select>
            </mat-form-field>

        </div>
    </form>
</div>
<mat-dialog-actions class="actions-right">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button (click)="submit()">Ok</button>
</mat-dialog-actions>