import { Component, ChangeDetectionStrategy, Input, HostBinding } from "@angular/core";
import { Cultivar } from '@core/data';
import { SampleFormatter } from '@app/evaluation/sample-formatter.service';


@Component({
    selector: '[pvCultivarInline]',
    template: `
        <div class="inline-infobox">
            <div *ngIf="_licensee; else noLicensee" style="margin-top: -14px; margin-left: 1px;">
                <small style="margin-top: -14px; margin-left: 1px;">{{ this._licensee }}</small>
            </div>
            <ng-template #noLicensee>
            </ng-template>
            <div>
                <div class="cultivar-sample-count" *ngIf="_plantSampleCount"
                    matTooltip="Associated Plant Sample Scion">
                    <mat-icon svgIcon="common:plant_sample"></mat-icon>
                    <mat-icon svgIcon="common:flat_scion"></mat-icon>
                    <mat-icon *ngIf="_graftIcon" class="graft-icon" [svgIcon]="_graftIcon"></mat-icon>
                    <span>&nbsp;{{ this._plantSampleCount }}</span>
                </div>

                <div class="cultivar-sample-count" *ngIf="_rootstockPlantSamplesCount"
                    matTooltip="Associated Plant Sample Rootstock">
                    <mat-icon svgIcon="common:plant_sample"></mat-icon>
                    <mat-icon svgIcon="common:flat_rootstock"></mat-icon>
                    <mat-icon *ngIf="_graftIcon" class="graft-icon" [svgIcon]="_graftIcon"></mat-icon>
                    <span>&nbsp;{{ this._rootstockPlantSamplesCount }}</span>
                </div>

                <div class="cultivar-sample-count" *ngIf="_harvestSampleCount"
                    matTooltip="Associated Harvest Sample Scion">
                    <mat-icon svgIcon="common:harvest_sample"></mat-icon>
                    <mat-icon svgIcon="common:flat_scion"></mat-icon>
                    <mat-icon *ngIf="_graftIcon" class="graft-icon" [svgIcon]="_graftIcon"></mat-icon>
                    <span>&nbsp;{{ this._harvestSampleCount }}</span>
                </div>

                <div class="cultivar-sample-count" *ngIf="_rootstockHarvestSampleCount"
                    matTooltip="Associated Harvest Sample Rootstock">
                    <mat-icon svgIcon="common:harvest_sample"></mat-icon>
                    <mat-icon svgIcon="common:flat_rootstock"></mat-icon>
                    <span>&nbsp;{{ this._rootstockHarvestSampleCount }}</span>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-cultivar-inline pv-model-inline'
    }
})
export class CultivarInlineComponent {

    @Input('pvCultivarInline')
    set cultivar(cultivar: Cultivar){
        if (cultivar) {
            this._plantSampleCount = this._formatter.describePlantSampleCount(cultivar);
            this._harvestSampleCount = this._formatter.describeHarvetSampleCount(cultivar);
            this._rootstockPlantSamplesCount = this._formatter.describeRootstockPlantSamplesCount(cultivar);
            this._rootstockHarvestSampleCount = this._formatter.describeRootstockHarvestSampleCount(cultivar);
            this._licensee = this._formatter.describeLicensee(cultivar);
            this._cropIcon = 'common:' + cultivar.cropId;
        } else {
            this._plantSampleCount = '';
            this._harvestSampleCount = '';
            this._rootstockPlantSamplesCount = '';
            this._rootstockHarvestSampleCount = '';
            this._licensee = '';
            this._cropIcon = null;
        }
    }

    @Input()
    set graft(graft: string) {
        switch(graft){
            case 'scion':
                this._graftIcon = 'common:flat_scion';
                break;
            case 'rootstock':
                this._graftIcon = 'common:flat_rootstock';
                break;
            default:
                this._graftIcon = null;
        }
    }

    @HostBinding('attr.title')
    _title: string;

    _graftIcon: string = null;
    _cropIcon: string = null;
    _licensee: string;
    _plantSampleCount: string;
    _harvestSampleCount: string;
    _rootstockPlantSamplesCount: string;
    _rootstockHarvestSampleCount: string;

    constructor(private _formatter: SampleFormatter){}


}