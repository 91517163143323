import { Calculation } from './_types';

export const LIB_CALCULATIONS_TREE_HARVEST: Calculation[] = [

        //Calculated values
    {
        id: "tree_yield_eff",
        unit: "kg/cm2",
        label: "Yield Efficiency",
        tag: "YEF",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_tree_yie_eff",
        format: "####kg/cm2",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "tree_calculated",
    },
    {
        id: "tons_per_ha_calc",
        unit: "tons/ha",
        label: "Tons Per Hectare",
        tag: "TPH",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_tree_yie_eff",
        format: "####tons/ha",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "tree_calculated",
    },
    {
        id: "prod_average",
        unit: "kg/tree",
        label: "Productivity Average",
        tag: "PAV",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_tree_prod_average",
        format: "####kg/tree",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "tree_calculated",
    },
    {
        id: "tree_circumference_calc",
        unit: "mm",
        label: "Tree Circumference",
        tag: "CIR",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_tree_cir_std",
        format: "####mm",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "tree_calculated",
    },
];