import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppAuthModule } from '@app/auth';
import { AppBrowserModule } from '@core/browser';
import { AppChartsModule } from '@core/charts';
import { CoreDataModule } from '@core/data';
import { MapModule } from '@core/maps';
import { AppMaterialModule } from '@core/material';
import { CoreUtilsModule } from '@core/utils';
import { Library } from '@library';
import { NgxsModule } from '@ngxs/store';
import { MeasurementChartCompilerService } from './measurement-chart-compiler.service';
import { BarcodeDialogComponent } from './components/barcode-image/barcode-dialog.component';
import { BarcodeImageComponent } from './components/barcode-image/barcode-image.component';
import { BarcodeSvgDirective } from './components/barcode-image/barcode-svg.directive';
import { CultivarFormDialog } from './components/cultivar-form/cultivar-form.dialog';
import { CultivarFormState } from './components/cultivar-form/cultivar-form.state';
import { CultivarIndexState } from './components/cultivar-index/cultivar-index.state';
import { CultivarIndexView } from './components/cultivar-index/cultivar-index.view';
import { GroupIndexView } from './components/group-index/group-index.view';
import { CharacteristicsListComponent } from './components/evaluation-detail/characteristics-list.component';
import { ImageGalleryComponent } from './components/evaluation-detail/image-gallery.component';
import { ImageGalleryDialog } from './components/evaluation-detail/image-gallery.dialog';
import { MeasurementsTableComponent } from './components/evaluation-detail/measurements-table.component';
import { NotesListComponent } from './components/evaluation-detail/notes-list.component';
import { PrintEvaluationFormDialog } from './components/evaluation-detail/print-evaluation-form/print-evaluation-form.dialog';
import { CharacteristicsFormDialog } from './components/evaluation-form/characteristcs-form/characteristics-form.dialog';
import { CharacteristicsFormState } from './components/evaluation-form/characteristcs-form/characteristics-form.state';
import { EvaluationFormDialog } from './components/evaluation-form/evaluation-form.dialog';
import { EvaluationFormState } from './components/evaluation-form/evaluation-form.state';
import { ImagesFormDialog } from './components/evaluation-form/images-form/images-form.dialog';
import { ImagesFormState } from './components/evaluation-form/images-form/images-form.state';
import { MeasurementsFormDialog } from './components/evaluation-form/measurements-form/measurements-form.dialog';
import { MeasurementsFormState } from './components/evaluation-form/measurements-form/measurements-form.state';
import { MeasurementsUploadDialog } from './components/evaluation-form/measurements-form/measurements-upload.dialog';
import { NotesFormDialog } from './components/evaluation-form/notes-form/notes-form.dialog';
import { NotesFormState } from './components/evaluation-form/notes-form/notes-form.state';
import { CultivarAutocompleteChipsComponent } from './components/model-autocomplete/cultivar-autocomplete-chips.component';
import { CultivarAutocompleteComponent } from './components/model-autocomplete/cultivar-autocomplete.component';
import { SiteAutocompleteChipsComponent } from './components/model-autocomplete/site-autocomplete-chips.component';
import { CultivarLineComponent } from './components/model-line/cultivar-line.component';
import { SampleLineComponent } from './components/model-line/sample-line.component';
import { SiteLineComponent } from './components/model-line/site-line.component';
import { ModelSelectDialog } from './components/model-select/model-select.dialog';
import { ModelSelectState } from './components/model-select/model-select.state';
import { MeasureSummaryOptionsFormDialog } from './components/report-builder/measure-summary-options-form.dialog';
import { ReportBuilderState } from './components/report-builder/report-builder.state';
import { ReportBuilderView } from './components/report-builder/report-builder.view';
import { ReportRemarkFormDialog } from './components/report-builder/report-remark-form.dialog';
import { ReportSetupFormDialog } from './components/report-builder/report-setup-form.dialog';
import { ReportRemarkComponent } from './components/report/report-remark.component';
import { ReportComponent } from './components/report/report.component';
import { ReportIndexState } from './components/report-index/report-index.state';
import { ReportIndexView } from './components/report-index/report-index.view';
import { ReportPreviewState } from './components/report-preview/report-preview.state';
import { ReportPreviewView } from './components/report-preview/report-preview.view';
import { SampleDetailState } from './components/sample-detail/sample-detail.state';
import { SampleDetailView } from './components/sample-detail/sample-detail.view';
import { SampleFormDialog } from './components/sample-form/sample-form.dialog';
import { SampleFormState } from './components/sample-form/sample-form.state';
import { SampleIndexState } from './components/sample-index/sample-index.state';
import { SampleIndexView } from './components/sample-index/sample-index.view';
import { SampleLabelsComponent } from './components/sample-labels/sample-labels.component';
import { SiteFormDialog } from './components/site-form/site-form.dialog';
import { SiteFormState } from './components/site-form/site-form.state';
import { SiteIndexState } from './components/site-index/site-index.state';
import { SiteIndexView } from './components/site-index/site-index.view';
import { WeatherReadingsUploadDialog } from './components/weather-readings-upload/weather-readings-upload.dialog';
import { WeatherReadingsUploadState } from './components/weather-readings-upload/weather-readings-upload.state';
import { WeatherStationFormDialog } from './components/weather-station-form/weather-station-form.dialog';
import { WeatherStationFormState } from './components/weather-station-form/weather-station-form.state';
import { WeatherChartCompilerService } from './weather-chart-compiler.service';
import { WeatherStationIndexState } from './components/weather-station-index/weather-station-index.state';
import { WeatherStationIndexView } from './components/weather-station-index/weather-station-index.view';
import { EvaluationFormatter } from './evaluation-formatter.service';
import { EvaluationRoutingModule } from './evaluation-routing.module';
import { AgePipe } from './pipes/age.pipe';
import { DescribeCultivarPipe } from './pipes/describe-cultivar.pipe';
import { DescribeSamplePipe } from './pipes/describe-sample.pipe';
import { DescribeEvaluationPipe } from './pipes/describe-evaluation.pipe';
import { DescribeSiteAddressPipe } from './pipes/describe-site-address';
import { DescribeSiteCoordsPipe } from './pipes/describe-site-coords';
import { DescribeSitePipe } from './pipes/describe-site.pipe';
import { DescribeSpacing } from './pipes/describe-spacing.pipe';
import { DescribeTableReportPipe } from './pipes/describe-table-report.pipe';
import { DescribeTableReportColumnPipe } from './pipes/describe-table-report-column.pipe';
import { ExtractMessagePipe } from './pipes/extract-array-message.pipe'
import { FetchProtocolPipe } from './pipes/fetch-protocol.pipe';
import { GetReportPrimaryImagesPipe } from './pipes/get-report-primary-images.pipe';
import { LabelCharValuePipe } from './pipes/label-char-value.pipe';
import { LabelCharPipe } from './pipes/label-char.pipe';
import { LabelCropPipe } from './pipes/label-crop.pipe';
import { LabelMeasurePipe } from './pipes/label-measure.pipe';
import { LabelProtocolPipe } from './pipes/label-protocol.pipe';
import { LabelSchedulePipe } from './pipes/label-schedule.pipe';
import { LabelWeatherReportPipe } from './pipes/label-weather-report.pipe';
import { LabelWeatherSensorPipe } from './pipes/label-weather-sensor.pipe';
import { RowPositionPipe } from './pipes/row-position.pipe';
import { ReportCompilerService } from './report-compiler.service';
import { SampleFormatter } from './sample-formatter.service';
import { SiteAutocompleteComponent } from './components/model-autocomplete/site-autocomplete.component';
import { SampleAutocompleteComponent } from './components/model-autocomplete/sample-autocomplete.component';
import { SampleIndexGroupFormDialog } from './components/sample-index/sample-index-group-form.dialog'
import { EvaluationCompleteFormDialog } from './components/evaluation-form/evaluation-complete-form.dialog';
import { GetEvaluationStatusPipe } from './pipes/get-evaluation-status.pipe';
import { ReportTemplatesDialog } from './components/report-templates/report-templates.dialog';
import { ReportTemplatesState } from './components/report-templates/report-templates.state';
import { EvaluationScheduleFormState } from './components/evaluation-form/evaluation-schedule-form.state';
import { EvaluationScheduleFormDialog } from './components/evaluation-form/evaluation-schedule-form.dialog';
import { LIBRARY } from './library';
import { ReportWeatherFormDialog } from './components/report-builder/report-weather-form.dialog';
import { ReportTableFormDialog } from './components/report-builder/report-table-form.dialog';
import { CompileWeatherChartPipe } from './pipes/compile-weather-chart.pipe';
import { ReportSampleTreeComponent } from './components/report-builder/report-sample-tree.component';
import { SamplePrimaryImageFormState } from './components/sample-form/sample-primary-image-form.state';
import { SamplePrimaryImageFormDialog } from './components/sample-form/sample-primary-image-form.dialog';
import { TimelineModule } from '@core/timeline';
import { ExtractTimelinePipe } from './pipes/extract-timeline.pipe';
import { ReportShareDialog } from './components/report-builder/report-share.dialog';
import { ReportSharedPreviewView } from './components/report-shared-preview/report-shared-preview.view';
import { ReportSharedPreviewState } from './components/report-shared-preview/report-shared-preview.state';
import { ReportSampleImageSelectDialog } from './components/report-builder/report-sample-image-select.dialog';
import { CustomProtocolState } from '@app/evaluation/components/custom-protocol-index/custom-protocol.state';
import { CustomProtocolIndexView } from '@app/evaluation/components/custom-protocol-index/custom-protocol-index.view';
import { CustomProtocolFormDialog } from '@app/evaluation/components/custom-protocol-index/custom-protocol-form.dialog';
import { ExtractAgreementStatus } from './pipes/extract-agreement-status.pipe';
import { ExtractHarvestWindow } from './pipes/extract-harvest-window.pipe';
import { ReportTableEvaluationFormDialog } from './components/report-builder/report-table-evaluation-form.dialog';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SampleIndexEvaluationBulkDialog } from './components/sample-index/sample-index-evaluation-bulk.dialog';
import { MENU } from '@app/evaluation/menu';
import { CultevaMenu } from '@core/menu';
import { CultevaModules } from '@core/modules';
import { MODULES } from './modules';
import { SharedModule } from '@app/shared.module';
import { BulkTasteSampleFormState } from './components/sample-form/bulk-taste-sample-form.state';
import { BulkTasteSampleFormDialog } from './components/sample-form/bulk-taste-sample-form.dialog';
import { FileAttachmentDialog } from './components/file-attachment-form/file-attachment-form.dialog';
import { FileAttachmentFormState } from './components/file-attachment-form/file-attachment-form.state';
import { FileTypePipe } from './pipes/file-type.pipe';
import { NgxDocViewerModule } from 'ngx-doc-viewer2/';
import { DescribeLatestEvaluationPipe } from './pipes/describe-latest-evaluation.pipe';
import { LetterheadUploadDialog } from './components/letterhead-upload-form/letterhead-upload-form.dialog';
import { LetterheadUploadFormState } from './components/letterhead-upload-form/letterhead-upload-form.state';
import { CultivarTastingsSampleFormState } from './components/cultivar-tastings-sample-form/cultivar-tastings-sample-form.state';
import { CultivarTastingsSampleFormDialog } from './components/cultivar-tastings-sample-form/cultivar-tastings-sample-form.dialog';
import { ImageAttachmentDialog } from './components/image-attachment-form/image-attachment-form.dialog';
import { ImageAttachmentFormState } from './components/image-attachment-form/image-attachment-form.state';
import { ProtocolManagerState } from './components/protocol-manager/protocol-manager.state';
import { ReportTableTemplatesState } from './components/report-table-templates/report-table-templates.state';
import { ReportTableTemplatesDialog } from './components/report-table-templates/report-table-templates-form.dialog';
import { CultivarSwitchFormDialog } from './components/cultivar-form/cultivar-switch-form.dialog';

const libraryInstance = new Library;
const menuInstance = new CultevaMenu;
const modulesInstance = new CultevaModules;

@NgModule({
    imports: [
        CommonModule,
        CoreDataModule,
        AppMaterialModule,
        AppChartsModule,
        AppAuthModule,
        CoreUtilsModule,
        MapModule,
        TimelineModule,
        AppBrowserModule,
        EvaluationRoutingModule,
        NgxMatSelectSearchModule,
        NgxDocViewerModule,
        SharedModule,
        NgxsModule.forFeature([
            CultivarIndexState,
            CultivarFormState,
            CustomProtocolState,
            SiteIndexState,
            ModelSelectState,
            SiteFormState,
            ReportIndexState,
            ReportPreviewState,
            ReportSharedPreviewState,
            ReportBuilderState,
            ReportTemplatesState,
            SampleIndexState,
            SampleFormState,
            SampleDetailState,
            WeatherStationIndexState,
            WeatherStationFormState,
            WeatherReadingsUploadState,
            EvaluationFormState,
            EvaluationScheduleFormState,
            CharacteristicsFormState,
            MeasurementsFormState,
            NotesFormState,
            ImagesFormState,
            FileAttachmentFormState,
            SamplePrimaryImageFormState,
            BulkTasteSampleFormState,
            LetterheadUploadFormState,
            CultivarTastingsSampleFormState,
            ImageAttachmentFormState,
            ProtocolManagerState,
            ReportTableTemplatesState,
        ])
    ],
    declarations: [
        LabelCharValuePipe,
        LabelCropPipe,
        LabelProtocolPipe,
        LabelSchedulePipe,
        LabelCharPipe,
        LabelMeasurePipe,
        LabelWeatherReportPipe,
        LabelWeatherSensorPipe,
        DescribeSamplePipe,
        DescribeEvaluationPipe,
        DescribeLatestEvaluationPipe,
        ExtractTimelinePipe,
        ExtractMessagePipe,
        ExtractAgreementStatus,
        ExtractHarvestWindow,
        AgePipe,
        FetchProtocolPipe,
        GetReportPrimaryImagesPipe,
        CompileWeatherChartPipe,
        FileTypePipe,

        CustomProtocolIndexView,
        CustomProtocolFormDialog,

        //CultivarLineComponent,
        //CultivarAutocompleteComponent,
        //SiteAutocompleteComponent,
        //SampleAutocompleteComponent,
        //SampleLineComponent,
        //SiteLineComponent,

        BarcodeImageComponent,
        BarcodeDialogComponent,
        BarcodeSvgDirective,

        CharacteristicsFormDialog,
        MeasurementsFormDialog,
        NotesFormDialog,

        ReportComponent,
        ReportRemarkComponent,
        ReportSetupFormDialog,
        PrintEvaluationFormDialog,
        ReportRemarkFormDialog,
        ReportTemplatesDialog,
        ReportTableTemplatesDialog,
        MeasureSummaryOptionsFormDialog,
        ReportWeatherFormDialog,
        ReportTableFormDialog,
        ReportTableEvaluationFormDialog,
        ReportShareDialog,
        ReportSharedPreviewView,
        DescribeTableReportPipe,
        DescribeTableReportColumnPipe,

        MeasurementsUploadDialog,

        ReportSampleTreeComponent,

        RowPositionPipe,
        DescribeCultivarPipe,
        DescribeSitePipe,
        DescribeSpacing,
        DescribeSiteCoordsPipe,
        DescribeSiteAddressPipe,
        GetEvaluationStatusPipe,

        SiteIndexView,
        SiteFormDialog,
        //SiteAutocompleteChipsComponent,

        ModelSelectDialog,

        WeatherStationIndexView,
        WeatherStationFormDialog,
        WeatherReadingsUploadDialog,

        CultivarFormDialog,
        CultivarIndexView,
        CultivarSwitchFormDialog,
        //CultivarAutocompleteChipsComponent,

        FileAttachmentDialog,

        GroupIndexView,

        EvaluationFormDialog,
        EvaluationCompleteFormDialog,
        EvaluationScheduleFormDialog,

        ReportBuilderView,
        ReportIndexView,
        ReportPreviewView,
        ReportSampleImageSelectDialog,

        SampleLabelsComponent,

        SampleIndexView,
        SampleFormDialog,
        SampleIndexGroupFormDialog,
        SampleIndexEvaluationBulkDialog,
        SampleDetailView,
        CharacteristicsListComponent,
        MeasurementsTableComponent,
        NotesListComponent,
        ImageGalleryComponent,
        ImageGalleryDialog,
        ImagesFormDialog,
        SamplePrimaryImageFormDialog,

        BulkTasteSampleFormDialog,

        LetterheadUploadDialog,
        CultivarTastingsSampleFormDialog,
        ImageAttachmentDialog,

       // NgxDocViewerComponent,
    ],
    exports: [
        CultivarLineComponent,
    ],
    providers: [
        EvaluationFormatter,
        SampleFormatter,
        ReportCompilerService,
        MeasurementChartCompilerService,
        WeatherChartCompilerService,
        {
            provide: LIBRARY,
            useValue: libraryInstance
        },
        {
            provide: MENU,
            useValue: menuInstance
        },
        {
            provide: MODULES,
            useValue: modulesInstance
        }
    ],
    entryComponents: [
        BarcodeDialogComponent,
        PrintEvaluationFormDialog,
        MeasurementsUploadDialog,
        CharacteristicsFormDialog,
        CustomProtocolFormDialog,
        MeasurementsFormDialog,
        NotesFormDialog,
        ReportRemarkFormDialog,
        ReportSetupFormDialog,
        ReportTemplatesDialog,
        ReportTableTemplatesDialog,
        MeasureSummaryOptionsFormDialog,
        ModelSelectDialog,
        SiteFormDialog,
        WeatherStationFormDialog,
        WeatherReadingsUploadDialog,
        CultivarFormDialog,
        CultivarSwitchFormDialog,
        EvaluationFormDialog,
        EvaluationCompleteFormDialog,
        EvaluationScheduleFormDialog,
        FileAttachmentDialog,
        SampleFormDialog,
        ImageGalleryDialog,
        ImagesFormDialog,
        ReportWeatherFormDialog,
        ReportTableFormDialog,
        ReportTableEvaluationFormDialog,
        ReportSampleImageSelectDialog,
        SamplePrimaryImageFormDialog,
        SampleIndexGroupFormDialog,
        SampleIndexEvaluationBulkDialog,
        ReportShareDialog,
        BulkTasteSampleFormDialog,
        LetterheadUploadDialog,
        CultivarTastingsSampleFormDialog,
        ImageAttachmentDialog,
    ]
})
export class EvaluationModule { }
